import { Injectable } from '@angular/core';
import { set, reduce } from 'lodash';

@Injectable()
export class ParamsService {
  public paramsObj: any = {};

  constructor() {}

  latestUrlParams(queryString = null) {
    if (!queryString) {
      queryString = location.search;
    }
    const paramsString = this.decodeUrl(queryString).split('?').pop().replace(/\/$/, '');
    this.paramsObj = reduce(paramsString.split('&'), (acc, item) => {
      const [key, val] = item.split('=');
      if (!!key) {
        return set(acc, key, val);
      }
    }, {});
    return this.paramsObj;
  }

  decodeUrl(url) {
    if (decodeURIComponent(url) === url) {
      return url;
    } else {
      return this.decodeUrl(decodeURIComponent(url));
    }
  }
}
