import { Component } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'contact-sent-modal',
  templateUrl: 'contact-sent.html',
  styleUrls: ['contact-sent.scss']
})
export class ContactSentModalComponent {
  ownerId;
  contactParams;
  headerTitle: string;

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
  ) {
    this.ownerId = this.navParams.get('ownerId');
    this.contactParams = this.navParams.get('contactParams');
    this.headerTitle = this.navParams.get('headerTitle');
  }

  goToChat() {
    this.modalCtrl.dismiss({ openChat: true, contactParams: this.contactParams });
  }

  close() {
    this.modalCtrl.dismiss({ openChat: false });
  }
}
