import { Directive, Input, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { GeneralPwaService } from '../../core/services/general-pwa.service';

// TODO Andrey needs this directive for test purpose

@Directive({
  selector: '[appFixFreeze]',
})
export class FixFreezeDirective implements OnInit {

  constructor(private el: ElementRef, private generalPwaService: GeneralPwaService) {}

  ngOnInit() {
    const scrollElem = this.el.nativeElement.lastChild;
    if (scrollElem.className.indexOf('scroll-content')) {
      this.generalPwaService.scrollFreezeFix(scrollElem);
    }
  }

}
