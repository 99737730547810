import {
  createFormGroupState, createFormStateReducerWithUpdate,
  updateGroup,
  validate
} from 'ngrx-forms';
import { Action } from '@ngrx/store';
import { email, equalTo, minLength, required } from 'ngrx-forms/validation';

export interface PasswordValue {
  firstPwd: string;
  confirmPassword: string;
}

export interface FormValue {
  name: string;
  givenEmail: string;
  password: PasswordValue;
}

export const REGISTER_FORM_ID = 'register-form';

export const REGISTER_FORM_INITIAL_STATE_VALUE = {
  name: '',
  givenEmail: '',
  password: {
    firstPwd: '',
    confirmPassword: ''
  }
};

export const INITIAL_STATE = createFormGroupState<FormValue>(REGISTER_FORM_ID,  REGISTER_FORM_INITIAL_STATE_VALUE );



const validateAndUpdateFormState = updateGroup<FormValue>(
    {
      name: validate(required),
      givenEmail: validate([required, email]),
      password: (state, parentState) => {
        return updateGroup<PasswordValue>({
          firstPwd: validate([required, minLength(8)]),
          confirmPassword: validate([equalTo(state.value.firstPwd)])
        })(state);
      }
    }
);

const validationFormGroupReducer = createFormStateReducerWithUpdate<FormValue>(validateAndUpdateFormState);

export function registerReducer(state = INITIAL_STATE, action: Action) {
  return validationFormGroupReducer(state, action);
}
