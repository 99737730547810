export const NO_CITY_ID = -1;

export const IS_ONLINE_IL = 27;
export const IS_ONLINE_UK = 28;

export interface City {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  image_url: string;
}
