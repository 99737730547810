import { Action } from '@ngrx/store';
import { AppleSignInResponse } from '@ionic-native/sign-in-with-apple/ngx';

export const TRY_SIGNUP = '[Auth] TRY_SIGNUP';
export const SIGNUP = '[Auth] SIGNUP';
export const TRY_SIGNIN = '[Auth] TRY_SIGNIN';
export const SIGNIN = '[Auth] SIGNIN';
export const LOGOUT = '[Auth] LOGOUT';
export const LOGOUT_SUCCESS = '[Auth] LOGOUT_SUCCESS';
export const SIGNIN_ERROR = '[Auth] SIGNIN_ERROR';
export const SIGNUP_ERROR = '[Auth] SIGNUP_ERROR';
export const TRY_FB_SIGNIN = '[Auth] TRY_FB_SIGNIN';
export const APPLE_SIGNIN = '[Auth] APPLE_SIGNIN';
export const GOOGLE_SIGNIN = '[Auth] GOOGLE_SIGNIN';
export const FB_SIGNIN = '[Auth] FB_SIGNIN';
export const APPLE_SIGNIN_ERROR = '[Auth] APPLE_SIGNIN_ERROR';
export const GOOGLE_SIGNIN_ERROR = '[Auth] GOOGLE_SIGNIN_ERROR';
export const FACEBOOK_ONBOARDING = '[Auth] FACEBOOK_ONBOARDING';
export const FB_SIGNIN_ERROR = '[Auth] FB_SIGNIN_ERROR';
export const OPEN_SIGNUP = '[Auth] OPEN_SIGNUP';
export const OPEN_SIGNUP_PAGE = '[Auth] OPEN_SIGNUP_PAGE';
export const OPEN_SIGNIN = '[Auth] OPEN_SIGNIN';
export const OPEN_PASSWORD_RESET = '[Auth] OPEN_PASSWORD_RESET';
export const BACK_TO_ROOT = '[Auth] BACK_TO_ROOT';
export const TRY_PASSWORD_RESET = '[Auth] TRY_PASSWORD_RESET';
export const PASSWORD_RESET_SUCCESS = '[Auth] PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = '[Auth] PASSWORD_RESET_ERROR';
export const PASSWORD_SET_SUCCESS = '[Auth] PASSWORD_SET_SUCCESS';
export const TOGGLE_MARKETING_OPT_IN = '[Auth] TOGGLE_MARKETING_OPT_IN';
export const SKIP_LOGIN = '[Auth] SKIP_LOGIN';
export const DISABLE_SUBMIT = '[Auth] DISABLE_SUBMIT';
export const ENABLE_SUBMIT = '[Auth] ENABLE_SUBMIT';
export const SHOW_LOADER = '[Auth] SHOW_LOADER';
export const SET_FROM_MENU = '[Auth] SET FROM MENU';
export const RESET_FB_ERROR = '[Auth] RESET FB ERROR';

export class Signup implements Action {
  readonly type = SIGNUP;
}

export class Signin implements Action {
  readonly type = SIGNIN;
  constructor(public payload: any) {}
}

export class EnableSubmit implements Action {
  readonly type = ENABLE_SUBMIT;
}

export class DisableSubmit implements Action {
  readonly type = DISABLE_SUBMIT;
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class LogoutSuccess implements Action {
  readonly type = LOGOUT_SUCCESS;
}

export class SignInError implements Action {
  readonly type = SIGNIN_ERROR;
  constructor(public payload: any) {}
}

export class SignUpError implements Action {
  readonly type = SIGNUP_ERROR;
  constructor(public payload: string) {}
}

export class GoogleSignIn implements Action {
  readonly type = GOOGLE_SIGNIN;
  constructor(public payload: any) {}
}

export class GoogleSignInError implements Action {
  readonly type = GOOGLE_SIGNIN_ERROR;
  constructor(public payload: any) {}
}

export class AppleSignIn implements Action {
  readonly type = APPLE_SIGNIN;
  constructor(public payload: AppleSignInResponse) {}
}

export class AppleSignInError implements Action {
  readonly type = APPLE_SIGNIN_ERROR;
  constructor(public payload: any) {}
}

export class TryFBSignIn implements Action {
  readonly type = TRY_FB_SIGNIN;
}

export class FBSignIn implements Action {
  readonly type = FB_SIGNIN;
  constructor(public payload: { accessToken: string; expiresIn: string }) {}
}

export class FBSignInError implements Action {
  readonly type = FB_SIGNIN_ERROR;
  constructor(public payload: any) {}
}

export class OpenSignIn implements Action {
  readonly type = OPEN_SIGNIN;
}


export class OpenSignUpPage implements Action {
  readonly type = OPEN_SIGNUP_PAGE;
}

export class OpenSignUp implements Action {
  readonly type = OPEN_SIGNUP;
}

export class OpenPasswordReset implements Action {
  readonly type = OPEN_PASSWORD_RESET;
}

export class BackToRoot implements Action {
  readonly type = BACK_TO_ROOT;
}

export class ToggleMarketingOptIn implements Action {
  readonly type = TOGGLE_MARKETING_OPT_IN;
}

export class SkipLogin implements Action {
  readonly type = SKIP_LOGIN;
  constructor() {}
}

export class PasswordResetError implements Action {
  readonly type = PASSWORD_RESET_ERROR;
  constructor(public payload: string) {}
}

export class PasswordSetSuccess implements Action {
  readonly type = PASSWORD_SET_SUCCESS;
  constructor(public payload: string) {}
}

export class PasswordResetSuccess implements Action {
  readonly type = PASSWORD_RESET_SUCCESS;
  constructor(public payload: string) {}
}

export class ShowLoader implements Action {
  readonly type = SHOW_LOADER;
  constructor() {}
}

export class SetFromMenu implements Action {
  readonly type = SET_FROM_MENU;
  constructor(public fromMenu: boolean) {}
}

export class ResetFbError implements Action {
  readonly type = RESET_FB_ERROR;
  constructor() {}
}

export type AuthActions =
  | Signup
  | Signin
  | Logout
  | SignInError
  | SignUpError
  | TryFBSignIn
  | FBSignIn
  | OpenSignIn
  | OpenSignUp
  | OpenPasswordReset
  | BackToRoot
  | ToggleMarketingOptIn
  | SkipLogin
  | PasswordResetError
  | PasswordSetSuccess
  | PasswordResetSuccess
  | LogoutSuccess
  | EnableSubmit
  | DisableSubmit
  | FBSignInError
  | ShowLoader
  | ResetFbError
  | SetFromMenu
  | OpenSignUpPage;
