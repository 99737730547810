
import { Injectable } from '@angular/core';
import { Platform, IonApp, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class AndroidBackBtnService {

  private routesDic = [
    '/search',
    '/bookmarks',
    '/orders',
    '/menu',
    '/notifications',
    '/conversations',
    '/profile',
    '/profile-credits',
    '/profile-edit',
    '/loyalty-balance',
    '/share',
    '/category',
    '/funz',
    '/invite',
  ];

  constructor(private platform: Platform, private location: Location, private modalCtrl: ModalController) {
  }

  init() {
    this.platform.backButton.subscribe(async () => {
      const isModalOpened = await this.modalCtrl.getTop();
      if (this.routesDic.some((route) => (location.pathname).includes(route)) && !isModalOpened) {
        this.location.back();
      }
      if (isModalOpened) {
        isModalOpened.dismiss();
      }
    });
  }
}
