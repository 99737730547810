import { Action } from '@ngrx/store';
import { AuthHeaders } from '../../models/authHeaders.model';
import { SiteHeaders } from '../../models/siteHeaders.model';
import { MarketingParams } from '../../models/marketingParams.model';

export const FETCH_HEADERS = '[Core] FETCH_HEADERS';
export const LOAD_HEADERS = '[Core] LOAD_HEADERS';
export const SET_HEADERS = '[Core] SET_HEADERS';
export const AUTH_INVALID = '[Core] AUTH_INVALID';
export const VALIDATE_AUTH = '[Core] VALIDATE_AUTH';
export const BOOTSTRAP_AUTH_VALID = '[Core] BOOTSTRAP_AUTH_VALID';
export const LOGIN_SKIPPED = '[Core] LOGIN_SKIPPED';
export const SET_ROOT_PAGE = '[Core] SET_ROOT_PAGE';
export const SIGN_IN_COMPLETED = '[Core] SIGN_IN_COMPLETED';
export const FB_SIGN_IN_SUCCESS = '[Core] FB_SIGN_IN_SUCCESS';
export const SIGN_OUT_COMPLETED = '[Core] SIGN_OUT_COMPLETED';
export const REQUEST_PAGE = '[Core] REQUEST_PAGE';
export const CLEAR_REQUESTED_PAGE_PARAMS = '[Core] CLEAR_REQUESTED_PAGE_PARAMS';
export const SET_REQUESTED_PAGE_PARAMS = '[Core] SET_REQUESTED_PAGE_PARAMS';
export const CLEAR_REQUESTED_PAGE = '[Core] CLEAR_REQUESTED_PAGE';
export const SET_CONNECTION_STATUS = '[Core] CHANGE_CONNECTION_STATUS';
export const SET_DEEPLINKED_PAGE = '[Core] SET_DEEPLINKED_PAGE';
export const SET_DEEPLINKED_PAGE_PARAMS = '[Core] SET_DEEPLINKED_PAGE_PARAMS';
export const CLEAR_DEEPLINKED_PAGE_PARAMS = '[Core] CLEAR_DEEPLINKED_PAGE_PARAMS';
export const RESET_DEEPLINKED_PAGE = '[Core] RESET_DEEPLINKED_PAGE';
export const SET_LOCATION_PERMISSION = '[Core] SET_LOCATION_PERMISSION';
export const REQUEST_LOCATION = '[Core] REQUEST_LOCATION';
export const REQUEST_LOCATION_PERMISSION = '[Core] REQUEST_LOCATION_PERMISSION';
export const REQUEST_LOCATION_PERMISSION_FAILED = '[Core] REQUEST_LOCATION_PERMISSION_FAILED';
export const HANDLE_ERROR = '[Core] HANDLE_ERROR';
export const TOGGLE_MARKETING_OPT_IN = '[Core] TOGGLE_MARKETING_OPT_IN';
export const SET_DEFAULT_MARKETING_OPT_IN = '[Core] SET_DEFAULT_MARKETING_OPT_IN';
export const SET_TRACKING = '[Core] SET_TRACKING';
export const SET_CATEGORY = '[Core] SET_CATEGORY';
export const CHECK_FIRST_TIME = '[Core] CHECK_FIRST_TIME';
export const SET_FIRST_TIME = '[Core] SET_FIRST_TIME';
export const CHECK_INSTALL_DEEPLINK = '[Core] CHECK_INSTALL_DEEPLINK';
export const CHECK_OPEN_DEEPLINK = '[Core] CHECK_OPEN_DEEPLINK';
export const RESET_FIRST_TIME = '[Core] RESET_FIRST_TIME';
export const HANDLE_DEEPLINK = '[Core] HANDLE_DEEPLINK';
export const SET_DEEPLINK_STRING = '[Core] SET_DEEPLINK_STRING';
export const SET_AUTH_GUARD_INITIATED_AUTHENTICATION = '[Core] SET_AUTH_GUARD_INITIATED_AUTHENTICATION';
export const RESET_AUTH_GUARD_INITIATED_AUTHENTICATION = '[Core] RESET_AUTH_GUARD_INITIATED_AUTHENTICATION';
export const INITIALIZE_PWA = '[Core] INITIALIZE_PWA';
export const RESET_PWA_DEEPLINK = '[Core] RESET_PWA_DEEPLINK';
export const SET_IS_GEOLOCATION_PROMPTED = '[Core] SET_IS_GEOLOCATION_PROMPTED';
export const SET_LAST_SELECTED_CITY_ID = '[Core] SET_LAST_SELECTED_CITY_ID';
export const RESET_PWA_DEEPLINKED_SLUG = '[Core] RESET_PWA_DEEPLINKED_SLUG';
export const SET_DEEPLINKED_PAGE_ONBOARDING = '[Core] SET_DEEPLINKED_PAGE_ONBOARDING';
export const REQUEST_PAGE_PWA = '[Core] REQUEST_PAGE_PWA';
export const SHOW_PWA_FOOTER = '[Core] SHOW_PWA_FOOTER';
export const HIDE_PWA_FOOTER = '[Core] HIDE_PWA_FOOTER';
export const AUTH_HEADERS_SAVED = '[Core] AUTH_HEADERS_SAVED';
export const SITE_HEADERS_SAVED = '[Core] SITE_HEADERS_SAVED';
export const SAVE_SITE_HEADERS = '[Core] SAVE_SITE_HEADERS';
export const SAVE_MARKETING_PARAMS = '[Core] SAVE_MARKETING_PARAMS';
export const RESTORE_MARKETING_PARAMS = '[Core] RESTORE_MARKETING_PARAMS';
export const LOAD_MARKETING_PARAMS = '[Core] LOAD_MARKETING_PARAMS';
export const ONBOARDING_COMPLETED = '[Core] ONBOARDING_COMPLETED';

export enum ActionTypes {
  FETCH_SITE_HEADERS = '[Core] FETCH_SITE_HEADERS',
  SET_SITE_HEADERS = '[Core] SET_SITE_HEADERS',
  LOAD_SITE_HEADERS = '[Core] LOAD_SITE_HEADERS',
  GET_LOCATION = '[Core] GET_LOCATION',
  OPEN_AUTH_CONFIRM_DIALOG = '[Core] OPEN_AUTH_CONFIRM_DIALOG',
  AUTH_COMPLETED = '[Core] AUTH_COMPLETED'
}

export class LoadSiteHeaders implements Action {
  readonly type = ActionTypes.LOAD_SITE_HEADERS;
}

export class FetchSiteHeaders implements Action {
  readonly type = ActionTypes.FETCH_SITE_HEADERS;
}

export class SetSiteHeaders implements Action {
  readonly type = ActionTypes.SET_SITE_HEADERS;

  constructor(public payload: SiteHeaders) {}
}

export class FetchHeaders implements Action {
  readonly type = FETCH_HEADERS;
}

export class LoadHeaders implements Action {
  readonly type = LOAD_HEADERS;

  constructor(public payload: AuthHeaders) {}
}

export class SetHeaders implements Action {
  readonly type = SET_HEADERS;
  constructor(public payload: AuthHeaders) {}
}

export class AuthInvalid implements Action {
  readonly type = AUTH_INVALID;
  constructor(public payload?: { userInitiated?: boolean }) {}
}

export class ValidateAuth implements Action {
  readonly type = VALIDATE_AUTH;
}

export class BootstrapAuthValid implements Action {
  readonly type = BOOTSTRAP_AUTH_VALID;
}

export class LoginSkipped implements Action {
  readonly type = LOGIN_SKIPPED;
}

export class SetRootPage implements Action {
  readonly type = SET_ROOT_PAGE;

  constructor(public payload: string) {}
}

export class SignInCompleted implements Action {
  readonly type = SIGN_IN_COMPLETED;
  constructor(public payload: any) {}
}

export class FbSignInSuccess implements Action {
  readonly type = FB_SIGN_IN_SUCCESS;
  constructor(public payload: any) {}
}

export class SignOutCompleted implements Action {
  readonly type = SIGN_OUT_COMPLETED;
  constructor() {}
}

export class RequestPage implements Action {
  readonly type = REQUEST_PAGE;
  constructor(public payload: { page: string; params?: any }) {}
}

export class ClearRequestedPageParams implements Action {
  readonly type = CLEAR_REQUESTED_PAGE_PARAMS;
  constructor() {}
}

export class ClearRequestedPage implements Action {
  readonly type = CLEAR_REQUESTED_PAGE;
  constructor() {}
}

export class SetRequestedPageParams implements Action {
  readonly type = SET_REQUESTED_PAGE_PARAMS;
  constructor(public payload: any) {}
}

export class SetDeepLinkedPage implements Action {
  readonly type = SET_DEEPLINKED_PAGE;
  constructor(public payload: any) {}
}

export class ClearDeepLinkedPageParams implements Action {
  readonly type = CLEAR_DEEPLINKED_PAGE_PARAMS;
  constructor() {}
}

export class ResetDeepLinkedPage implements Action {
  readonly type = RESET_DEEPLINKED_PAGE;
}

export class SetDeepLinkedPageParams implements Action {
  readonly type = SET_DEEPLINKED_PAGE_PARAMS;
  constructor(public payload: any) {}
}

export class SetConnectionStatus implements Action {
  readonly type = SET_CONNECTION_STATUS;
  constructor(public payload: any) {}
}

export class SetLocationPermission implements Action {
  readonly type = SET_LOCATION_PERMISSION;
  constructor(public payload: any) {}
}

export class RequestLocation implements Action {
  readonly type = REQUEST_LOCATION;
  constructor() {}
}

export class RequestLocationPermission implements Action {
  readonly type = REQUEST_LOCATION_PERMISSION;
  constructor() {}
}

export class RequestLocationPermissionFailed implements Action {
  readonly type = REQUEST_LOCATION_PERMISSION_FAILED;
  constructor() {}
}

export class GetLocation implements Action {
  readonly type = ActionTypes.GET_LOCATION;
  constructor() {}
}

export class OpenAuthConfirmDialog implements Action {
  readonly type = ActionTypes.OPEN_AUTH_CONFIRM_DIALOG;
  constructor() {}
}

export class HandleError implements Action {
  readonly type = HANDLE_ERROR;
  constructor(public payload: any) {}
}

export class AuthCompleted implements Action {
  readonly type = ActionTypes.AUTH_COMPLETED;
  constructor() {}
}

export class ToggleMarketingOptIn implements Action {
  readonly type = TOGGLE_MARKETING_OPT_IN;
}

export class SetTracking implements Action {
  readonly type = SET_TRACKING;
  constructor(public payload: any) {}
}

export class SetCategory implements Action {
  readonly type = SET_CATEGORY;
  constructor(public payload: any) {}
}

export class SetDefaultMarketingOptIn implements Action {
  readonly type = SET_DEFAULT_MARKETING_OPT_IN;
  constructor(public payload: boolean) {}
}

export class CheckFirstTime implements Action {
  readonly type = CHECK_FIRST_TIME;
  constructor() {}
}

export class SetFirstTime implements Action {
  readonly type = SET_FIRST_TIME;
  constructor() {}
}

export class CheckInstallDeeplink implements Action {
  readonly type = CHECK_INSTALL_DEEPLINK;
  constructor(public payload: any) {}
}

export class CheckOpenDeeplink implements Action {
  readonly type = CHECK_OPEN_DEEPLINK;
  constructor(public payload: any) {}
}

export class ResetFirstTime implements Action {
  readonly type = RESET_FIRST_TIME;
  constructor() {}
}

export class HandleDeeplink implements Action {
  readonly type = HANDLE_DEEPLINK;
  constructor(public payload: {actionType: string, params: any}) {}
}

export class SetDeeplinkString implements Action {
  readonly type = SET_DEEPLINK_STRING;
  constructor(public payload: string) {}
}

export class SetAuthGuardInitiatedAuthentication implements Action {
  readonly type = SET_AUTH_GUARD_INITIATED_AUTHENTICATION;
}

export class ResetAuthGuardInitiatedAuthentication implements Action {
  readonly type = RESET_AUTH_GUARD_INITIATED_AUTHENTICATION;
}

export class SetIsGeolocationPrompted implements Action {
  readonly type = SET_IS_GEOLOCATION_PROMPTED;
  constructor(public payload: boolean) {}
}

export class InitializePWA implements Action {
  readonly type = INITIALIZE_PWA;
}

export class ResetPWADeeplink implements Action {
  readonly type = RESET_PWA_DEEPLINK;
}

export class SetLastSelectedCityId implements Action {
  readonly type = SET_LAST_SELECTED_CITY_ID;
  constructor(public payload: any) {}
}

export class ResetPwaDeeplinkedSlug implements Action {
  readonly type = RESET_PWA_DEEPLINKED_SLUG;
  constructor() {}
}

export class SetDeeplinkedPageOnboarding implements Action {
  readonly type = SET_DEEPLINKED_PAGE_ONBOARDING;
  constructor(public payload: any) {}
}

export class RequestPagePwa implements Action {
  readonly type = REQUEST_PAGE_PWA;
  constructor(public payload: { page: string; params?: any }) {}
}

export class ShowPwaFooter implements Action {
  readonly type = SHOW_PWA_FOOTER;
  constructor() {}
}

export class HidePwaFooter implements Action {
  readonly type = HIDE_PWA_FOOTER;
  constructor() {}
}

export class AuthHeadersSaved implements Action {
  readonly type = AUTH_HEADERS_SAVED;
  constructor() {}
}

export class SiteHeadersSaved implements Action {
  readonly type = SITE_HEADERS_SAVED;
  constructor() {}
}

export class SaveSiteHeaders implements Action {
  readonly type = SAVE_SITE_HEADERS;
  constructor(public payload: SiteHeaders) {}
}

export class SaveMarketingParams implements Action {
  readonly type = SAVE_MARKETING_PARAMS;
  constructor(public payload: MarketingParams) {}
}

export class LoadMarketingParams implements Action {
  readonly type = LOAD_MARKETING_PARAMS;
}

export class RestoreMarketingParams implements Action {
  readonly type = RESTORE_MARKETING_PARAMS;
  constructor(public payload: MarketingParams) {}
}

export class OnboardingCompleted implements Action {
  readonly type = ONBOARDING_COMPLETED;
}

export type CoreActions =
  | FetchSiteHeaders
  | SetSiteHeaders
  | LoadSiteHeaders
  | FetchHeaders
  | LoadHeaders
  | SetHeaders
  | AuthInvalid
  | BootstrapAuthValid
  | ValidateAuth
  | LoginSkipped
  | SetRootPage
  | SignInCompleted
  | RequestPage
  | ClearRequestedPageParams
  | ClearRequestedPage
  | FbSignInSuccess
  | SignOutCompleted
  | SetRequestedPageParams
  | SetDeepLinkedPage
  | SetDeepLinkedPageParams
  | ClearDeepLinkedPageParams
  | SetConnectionStatus
  | SetLocationPermission
  | RequestLocationPermission
  | RequestLocationPermissionFailed
  | GetLocation
  | OpenAuthConfirmDialog
  | HandleError
  | AuthCompleted
  | ToggleMarketingOptIn
  | SetDefaultMarketingOptIn
  | SetTracking
  | SetCategory
  | CheckFirstTime
  | SetFirstTime
  | CheckInstallDeeplink
  | CheckOpenDeeplink
  | ResetFirstTime
  | HandleDeeplink
  | ResetDeepLinkedPage
  | SetDeeplinkString
  | SetAuthGuardInitiatedAuthentication
  | ResetAuthGuardInitiatedAuthentication
  | SetIsGeolocationPrompted
  | InitializePWA
  | ResetPWADeeplink
  | SetLastSelectedCityId
  | ResetPwaDeeplinkedSlug
  | SetDeeplinkedPageOnboarding
  | RequestPagePwa
  | ShowPwaFooter
  | HidePwaFooter
  | AuthHeadersSaved
  | SiteHeadersSaved
  | SaveSiteHeaders
  | SaveMarketingParams
  | RestoreMarketingParams
  | LoadMarketingParams
  | OnboardingCompleted;
