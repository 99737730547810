import { OrderComponent } from './order';
import { OrderCoverComponent } from './order-cover';
import { OrderDetailsComponent } from './order-details';
import { OrderFeedbackComponent } from './order-feedback';
import { OrderMetaComponent } from './order-meta';
import { OrderOwnerComponent } from './order-owner';
import { OrderSubscriptionInfoComponent } from './order-subscription-info';
import { OrderSubscriptionLinkComponent } from './order-subscription-link';

export const OrderComponents = [
  OrderComponent,
  OrderCoverComponent,
  OrderDetailsComponent,
  OrderFeedbackComponent,
  OrderMetaComponent,
  OrderOwnerComponent,
  OrderSubscriptionInfoComponent,
  OrderSubscriptionLinkComponent
];
