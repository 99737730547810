
import { Component, Input, ChangeDetectionStrategy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavController, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import get from 'lodash/get';
import { take } from 'rxjs/operators';
import { debounce } from 'lodash-decorators';
import { Funz } from '../../funz/funz.model';
import { ImageHandlingService } from '../../core/services/image-handling.service';
import { AppServices } from '../../core/api/app.services';
import { getWishlistIcon } from '../../shared-stores/funz/store/funz.utils';
import { AppApi } from '../../core/api/app.api';
import { IAuthenticatedEvent } from '../require-auth/require-auth.directive';
import * as fromApp from '../../core/store/app.reducers';
import * as fromFunz from '../../shared-stores/funz/store';
import { APP_PAGES } from '../../pages';
import { TrackingService } from '../../core/services/tracking.service';
import { Category } from '../../funz/category.model';
import { GeneralHelperService } from '../../core/services/general-helper.service';
import { PAGE_TO_URL_TRANSFORMER } from '../../app-routing.utils';
import { Router } from '@angular/router';

enum ConfirmTranslateKeys {
  Title = 'component.funz_box.bookmark_confirm.title',
  Message = 'component.funz_box.bookmark_confirm.message',
  ButtonNo = 'component.funz_box.bookmark_confirm.button_no',
  ButtonYes = 'component.funz_box.bookmark_confirm.button_yes'
}

@Component({
  selector: 'funz-box',
  templateUrl: 'funz-box.html',
  styleUrls: ['funz-box.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FunzBoxComponent implements OnInit {

  @Input() funz: Funz;
  @Input() category: Category;
  @Input() isWide = false;
  @Input() showHeart = true;
  @Input() recommended = false;
  @ViewChild('video', { static: false }) video: ElementRef;
  public isToday = false;
  public srcUrl = '';
  public redirectPage = APP_PAGES.DiscoverPage;
  public showTicketsLeft: boolean;
  public soldOut: boolean;
  public isVideo = false;
  public liveDays: string;
  public liveTime: string;
  public canShowPrice = true;

  constructor(
    private navCtrl: NavController,
    private imageHandling: ImageHandlingService,
    private appStore: Store<fromApp.IAppState>,
    private appServices: AppServices,
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private appApi: AppApi,
    private router: Router,
    private trackingService: TrackingService,
    public ghs: GeneralHelperService,
  ) {}

  ngOnInit() {
    if (!this.funz) { return; }
    this.checkPrice(this.funz);
    this.showTicketsLeft = this.funz.showTicketsLeft && this.funz.ticketsLeft && this.funz.ticketsLeft < 9;
    this.soldOut = this.funz.allEventsSoldOut && this.funz.numberOfUpcomingDates > 0 && !this.showTicketsLeft;
    this.isToday = this.appServices.funzIsToday(this.funz.nextEventWithTicketsLeft);
    this.srcUrl = this.getImg(this.funz);
    this.calcLiveDates();
  }

  playVideo(e) {
    // if (this.isVideo) {
    //   this.video.nativeElement.play();
    // }
  }

  calcLiveDates() {
    if (!this.funz.liveEventComingDays && this.funz.liveEventComingDays !== 0) { return; }
    if (this.funz.liveEventComingDays > -1) {
      // todo: translate
      const t = ['היום', 'מחר', 'בעוד יומיים', 'בעוד x ימים'];
      this.liveDays = t[Math.min(3, this.funz.liveEventComingDays)].replace('x', this.funz.liveEventComingDays.toString());
      this.liveTime = this.funz.liveEventTime;
    } else {
      this.liveDays = this.funz.liveEventComingDays.toString();
    }
   }

  getImg(funz) {
    const imgToProccess = funz.coverUrl ? funz.coverUrl : funz.pictureUid;
    const img = this.imageHandling.getProcessedImageURL(imgToProccess, {
      width: this.isWide ? window.innerWidth - 5 : 325,
      height: this.isWide ? 220 : 200,
      mp4: false
    }, false, funz.online).toLowerCase();
    if (img.includes('.jpg') || img.includes('.jpeg') || img.includes('.png')) {
      return img;
    } else if (img.includes('.gif')) {
      return img.replace('.gif', '.jpg');
    } else if (img.includes('.mp4')) {
      return img.replace('.mp4', '.jpg');
    } else {
      return img + '.jpg'
    }
    // if (funz.coverUrl.includes('.gif') || funz.coverUrl.includes('.mp4')) {
    //   this.isVideo = true;
    //   const img = funz.coverUrl.replace('.gif', '.jpg');
    //   return img.replace('f_mp4,', '');
    // } else {
    //   this.isVideo = false;
    //   return this.imageHandling.getProcessedImageURL(funz.pictureUid, {
    //     width: this.isWide ? window.innerWidth - 5 : 325,
    //     height: this.isWide ? 220 : 200
    //   }, false, funz.online);
    // }
  }

  @debounce(25)
  navigateToFunzPage() {
   // TODO FIX TRACKING
  this.trackingService.traceAction('funz_selection', {
      funz_id: get(this, 'funz.id'),
      category_id: get(this, 'category.id') || get(this, 'funz.categoryId'),
      category: get(this, 'category.mobileDisplayName'),
      managed_funz: get(this, 'funz.owner.funzing_host'),
      source: get(this.router, 'url')
    });
    if (this.funz.currentLiveEventId) {
      this.navCtrl.navigateForward(
        PAGE_TO_URL_TRANSFORMER.getUrlByPageName(APP_PAGES.LivePage, { id: this.funz.currentLiveEventId })
      );
    } else {
      this.navCtrl.navigateForward(`funz/${this.funz.slug}`);
    }
  }

  @debounce(25)
  handleAuthenticated(funz: Funz, authEvent: IAuthenticatedEvent) {
    const requiredAuth = authEvent.requiredAuth;
    const newFunz = {
      ...funz,
      bookmarked: requiredAuth ? true : !funz.bookmarked
    };
    if (!newFunz.bookmarked) {
      this.showConfirm(newFunz, requiredAuth);
    } else {
      this.bookmarkFunz(newFunz, requiredAuth);
    }
  }

  private showConfirm(newFunz: Funz, requiredAuth: boolean) {
    this.getConfirmTexts().subscribe(async result => {
      const confirm = await this.alertCtrl.create({
        header: result[ConfirmTranslateKeys.Title],
        message: result[ConfirmTranslateKeys.Message],
        buttons: [
          { text: result[ConfirmTranslateKeys.ButtonNo] },
          {
            text: result[ConfirmTranslateKeys.ButtonYes],
            handler: () => this.bookmarkFunz(newFunz, requiredAuth)
          }
        ]
      });
      confirm.present();
    });
  }

  getConfirmTexts() {
    return this.translateService
      .get([
        ConfirmTranslateKeys.Title,
        ConfirmTranslateKeys.Message,
        ConfirmTranslateKeys.ButtonNo,
        ConfirmTranslateKeys.ButtonYes
      ])
      .pipe(take(1));
  }

  heartClick(event) {
    this.trackingService.traceAction('like_click', { liked: (this.wishlistIcon || '').includes('wishlist-o') });
    event.stopPropagation();
  }

  bookmarkFunz(newFunz: Funz, requiredAuth: boolean) {
    this.appApi.bookmarkFunz(newFunz);
    if (requiredAuth) { this.navigateToFunzPage(); }
  }

  get redirectParams() {
    return new fromFunz.UpdateFunz({ ...this.funz, bookmarked: true });
  }

  get wishlistIcon() {
    return getWishlistIcon(this.funz);
  }

  maxPrice(funz) {
    const kidsPrice = parseFloat(funz.kidsPrice.substring(1));
    const adultPrice = parseFloat(funz.adultPrice.substring(1));

    return kidsPrice > adultPrice ? funz.kidsPrice : funz.adultPrice;
  }

  private checkPrice(funz) {
    const kidsPrice = parseFloat(funz.kidsPrice.substring(1));
    const adultPrice = parseFloat(funz.adultPrice.substring(1));
    this.canShowPrice = kidsPrice || adultPrice ? true : false;
  }
}
