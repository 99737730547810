import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NO_CITY_ID } from '../../funz/city.model';

@Pipe({
  name: 'cityArea',
  pure: false
})
export class CityAreaPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  transform(city: any, isOnline: any = null) {
    if (city) {
      if (city.id === NO_CITY_ID) {
        if (isOnline !== null) {
          if (isOnline === 0) {
            return this.translate.instant('component.location.offline');
          } else {
            return this.translate.instant('component.location.online');
          }
        } else {
          return this.translate.instant('component.location.anywhere');
        }
      } else {
        return document.dir === 'rtl' ?
          `${this.translate.instant('component.location.area')} ${city.name}` :
          `${city.name} ${this.translate.instant('component.location.area')}`;
      }
    } else {
      return '';
    }
  }
}
