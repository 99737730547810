import { Action } from '@ngrx/store';
import { ISubscription } from '../activities';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 */
export enum ProfileActionTypes {
  UPDATE = '[Profile] UPDATE',
  UPDATE_DEVICE = '[Profile] UPDATE_DEVICE',
  UPLOAD = '[Profile] UPLOAD',
  REQUEST_UPLOAD_PICTURE = '[Profile] REQUEST_UPLOAD_PICTURE',

  CLEAR_EDIT_FORM = '[Pfofile] clear edit profile form',

  RESET_PROFILE = '[Profile] RESET_PROFILE',

  UPDATE_PROFILE = '[Profile] UPDATE profile',
  UPDATE_PROFILE_FORM = '[Profile] UPDATE profile form',
  UPDATE_PROFILE_SUCCESS = '[Pfofile] successfully updated profile',
  UPDATE_PROFILE_FAILURE = '[Pfofile] failed to update profile',

  GET_PROFILE_CREDITS = '[Profile] GET profile credits',
  GET_PROFILE_CREDITS_SUCCESS = '[Pfofile] successfully got profile credits',
  GET_PROFILE_CREDITS_FAILED = '[Profile] failed to get profile credits',

  GET_PROFILE_LOYALTY_POINTS = '[Profile] GET profile loyalty points',
  GET_PROFILE_LOYALTY_POINTS_SUCCESS = '[Profile] successfully got profile loyalty points',
  GET_PROFILE_LOYALTY_POINTS_FAILED = '[Profile] failed to get profile loyalty points',

  GET_PROFILE_INVITES = '[Profile] GET profile invites',
  GET_PROFILE_INVITES_SUCCESS = '[Profile] successfully got profile invites',
  GET_PROFILE_INVITES_FAILED = '[Profile] failed to get profile invites',

  UPDATE_PHONE = '[Profile] UPDATE_PHONE',
  UPDATE_PHONE_SUCCESS = '[Profile] Update phone successfully',
  UPDATE_PHONE_FAILED = '[Profile] Update phone failed',
  UPDATE_CITY = '[Profile] UPDATE_CITY',
  TOGGLE_MARKETING_OPT_IN = '[Profile] TOGGLE_MARKETING_OPT_IN',

  GET_PROFILE_SUBSCRIPTION = '[Profile] Get subscription',
  GET_PROFILE_SUBSCRIPTION_SUCCESS = '[Profile] Get subscription success',
  GET_PROFILE_SUBSCRIPTION_ERROR = '[Profile] Get subscription error'
}

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 */
export class UpdateProfile implements Action {
  readonly type = ProfileActionTypes.UPDATE;

  constructor(public payload: any) {}
}

export class UpdateDevice implements Action {
  readonly type = ProfileActionTypes.UPDATE_DEVICE;

  constructor(public payload: any) {}
}

export class UploadPicture implements Action {
  readonly type = ProfileActionTypes.UPLOAD;

  constructor(public payload: any) {}
}

export class ResetProfile implements Action {
  readonly type = ProfileActionTypes.RESET_PROFILE;
}

export class RequestUploadPicture implements Action {
  readonly type = ProfileActionTypes.REQUEST_UPLOAD_PICTURE;

  constructor() {}
}

/**
 * UPDATE_PROFILE
 */
export class ClearEditForm implements Action {
  readonly type = ProfileActionTypes.CLEAR_EDIT_FORM;

  constructor() {}
}

/**
 * UPDATE_PROFILE
 */
export class UpdateProfileAction implements Action {
  readonly type = ProfileActionTypes.UPDATE_PROFILE;
  constructor(public payload: any) {}
}
export class UpdateProfileFormAction implements Action {
  readonly type = ProfileActionTypes.UPDATE_PROFILE_FORM;
  constructor(public payload: any) {}
}
export class UpdateProfileSuccess implements Action {
  readonly type = ProfileActionTypes.UPDATE_PROFILE_SUCCESS;
  constructor(public payload: any) {}
}
export class UpdateProfileFailure implements Action {
  readonly type = ProfileActionTypes.UPDATE_PROFILE_FAILURE;
  constructor(public payload: any) {}
}

/**
 * GET_PROFILE_LOYALTY_POINTS
 */

export class GetProfileLoyaltyPoints implements Action {
  readonly type = ProfileActionTypes.GET_PROFILE_LOYALTY_POINTS;
  constructor() {}
}
export class GetProfileLoyaltyPointsSuccess implements Action {
  readonly type = ProfileActionTypes.GET_PROFILE_LOYALTY_POINTS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetProfileLoyaltyPointsFailure implements Action {
  readonly type = ProfileActionTypes.GET_PROFILE_LOYALTY_POINTS_FAILED;
  constructor(public payload: any) {}
}

/**
 * GET_INVITES_URL
 */

export class GetProfileInvites implements Action {
  readonly type = ProfileActionTypes.GET_PROFILE_INVITES;
  constructor(public payload: any) {}
}
export class GetProfileInvitesSuccess implements Action {
  readonly type = ProfileActionTypes.GET_PROFILE_INVITES_SUCCESS;
  constructor(public payload: any) {}
}
export class GetProfileInvitesFailure implements Action {
  readonly type = ProfileActionTypes.GET_PROFILE_INVITES_FAILED;
  constructor(public payload: any) {}
}

/**
 * GET_PROFILE_CREDITS
 */
export class GetProfileCredits implements Action {
  readonly type = ProfileActionTypes.GET_PROFILE_CREDITS;
  constructor(public payload: any) {}
}
export class GetProfileCreditsSuccess implements Action {
  readonly type = ProfileActionTypes.GET_PROFILE_CREDITS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetProfileCreditsFailure implements Action {
  readonly type = ProfileActionTypes.GET_PROFILE_CREDITS_FAILED;
  constructor(public payload: any) {}
}

export class UpdatePhone implements Action {
  readonly type = ProfileActionTypes.UPDATE_PHONE;
  constructor(public payload: string) {}
}

export class UpdatePhoneSuccess implements Action {
  readonly type = ProfileActionTypes.UPDATE_PHONE_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdatePhoneFailed implements Action {
  readonly type = ProfileActionTypes.UPDATE_PHONE_FAILED;
}

export class UpdateCity implements Action {
  readonly type = ProfileActionTypes.UPDATE_CITY;
  constructor(public payload: string) {}
}

export class ToggleMarketingOptIn implements Action {
  readonly type = ProfileActionTypes.TOGGLE_MARKETING_OPT_IN;
}

export class GetProfileSubscription implements Action {
  readonly type = ProfileActionTypes.GET_PROFILE_SUBSCRIPTION;
}

export class GetProfileSubscriptionSuccess implements Action {
  readonly type = ProfileActionTypes.GET_PROFILE_SUBSCRIPTION_SUCCESS;

  constructor(public subscription: ISubscription) {}
}

export class GetProfileSubscriptionError implements Action {
  readonly type = ProfileActionTypes.GET_PROFILE_SUBSCRIPTION_ERROR;
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type ProfileActions =
  | ProfileActionTypes.UPDATE
  | UploadPicture
  | ClearEditForm
  | ResetProfile
  | UpdateDevice
  | UpdateProfileAction
  | UpdateProfileFormAction
  | UpdateProfileSuccess
  | UpdateProfileFailure
  | GetProfileCredits
  | GetProfileCreditsSuccess
  | GetProfileCreditsFailure
  | GetProfileLoyaltyPoints
  | GetProfileLoyaltyPointsSuccess
  | GetProfileLoyaltyPointsFailure
  | UpdatePhone
  | UpdatePhoneSuccess
  | UpdatePhoneFailed
  | UpdateCity
  | GetProfileSubscription
  | GetProfileSubscriptionSuccess
  | GetProfileSubscriptionError
  | any;
