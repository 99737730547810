import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addComma'
})
export class AddCommaPipe implements PipeTransform {
  transform(value: string, ...args) {
    return value ? value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '';
  }
}
