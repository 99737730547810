import { Component, Renderer2, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'location-selector',
  templateUrl: 'location-selector.html',
  styleUrls: ['location-selector.scss'],
})
export class LocationSelectorComponent implements OnInit {

  constructor(public modalCtrl: ModalController, private renderer: Renderer2) {
  }

  ngOnInit() {
    const ionApp = document.getElementsByTagName('ion-app')[0];
    this.renderer.removeClass(ionApp, 'disable-scroll');
  }

  findLocation() {
    this.modalCtrl.dismiss(true);
  }

  closeModal() {
    this.modalCtrl.dismiss(false);
  }

}
