import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'amCalendar'
})
export class AmCalendarPipe extends DatePipe implements PipeTransform {
  transform(value: any, ...args: string[]): any {
    let date = super.transform(value, ...args);
    date = date.indexOf('@') >= 0 ? date.replace('@', 'at') : date;
    return date;
  }
}
