import { Injectable } from '@angular/core';
import { ENV } from '../../../environments/environment';
import { Platform } from '@ionic/angular';

interface IImageOptions {
  height?: number;
  width?: number;
  mp4?: boolean
}

@Injectable()
export class ImageHandlingService {
  constructor(private platform: Platform) {}

  getProcessedImageURL(
    imageUid: string,
    opts: IImageOptions = {},
    assets = false,
    online = false
  ) {
    if (!imageUid) {
      return null;
    }
    imageUid = imageUid.replace(/.*(stage|prod)\//gi, '');
    const imageBaseUrl = `${ENV.cloudinary_base_url}`;
    let imageUrl = '';
    const imageOpts = [];
    imageOpts.push(`h_${Math.round(opts.height)}`);
    imageOpts.push(`w_${Math.round(opts.width)}`);
    if (opts.mp4) {
      imageOpts.push('f_mp4');
      imageOpts.push('f_auto');
    }
    imageOpts.push('c_fill');
    imageOpts.push('q_auto');
    imageOpts.push('fl_lossy');
    imageUrl += imageOpts.join(',');
    if (online) {
      imageUrl += `/l_online_funz_overlay,g_north,y_10,w_0.3,c_fit,fl_relative`;
    }
    imageUrl += assets
      ? `/prod-site-assets/${imageUid}`
      : `/${ENV.cloudinary_image_set}/${imageUid}`;
    return imageBaseUrl + imageUrl;
  }

  getProccesedVideoURL(imageUid: string, opts: IImageOptions = {}) {
    let imageUrl = `${ENV.cloudinary_base_url}`;
    const imageOpts = [];
    if (opts.height) {
      imageOpts.push(`h_${Math.round(opts.height)}`);
    }
    if (opts.width) {
      imageOpts.push(`w_${Math.round(opts.width)}`);
    }
    if (opts.width && opts.height) {
      imageOpts.push('c_fill');
    }
    imageOpts.push('q_auto');
    imageOpts.push('f_mp4');
    imageOpts.push('fl_lossy');
    imageUrl += imageOpts.join(',');
    imageUrl += `/${ENV.cloudinary_image_set}/${imageUid}`;
    return imageUrl;
  }

  getSrcSetURLs(imageUid: string, height: number) {
    const srcSets = [];
    const widths = this.getWidths();
    widths.forEach(width =>
      srcSets.push(
        `${this.getProcessedImageURL(imageUid, { width, height })} ${width}w`
      )
    );
    return {
      src: this.getProcessedImageURL(imageUid, { width: widths[0], height }),
      sets: srcSets.join(', ')
    };
  }

  getFullWidthImage(imageUid: string, height: number) {
    return this.getProcessedImageURL(imageUid, { height, width: 420 });
  }

  getFullWidthVideo(imageUid: string, height: number) {
    return this.getProccesedVideoURL(imageUid, { height, width: 420 });
  }

  getVideoSrcURLs(imageUid: string, height: number) {
    const videoSources = [];
    this.getWidths().forEach(width =>
      videoSources.push({
        media: `(max-width: ${width}px)`,
        srcset: this.getProccesedVideoURL(imageUid, { width, height })
      })
    );
    return { videoSources };
  }

  getVideoUrl(imageUid: string, opts: IImageOptions = {}) {
    return this.getProccesedVideoURL(imageUid, opts);
  }

  getWidths() {
    return [349, 187];
  }

  getFullSizeVideo(imageUid: string) {
    return this.getProccesedVideoURL(imageUid);
  }

  getProcessedImageURLofUrl(imageUrl: string, opts: IImageOptions = {}) {
    if (!imageUrl || !imageUrl.includes('cloudfront.net/')) { return imageUrl; }
    const imageUid = imageUrl.split('cloudfront.net/')[1];
    return this.getProcessedImageURL(imageUid, opts);
  }

  getProcessedAssestsUrl(imageUrl: string, opts: IImageOptions = {}) {
    if (!imageUrl || !imageUrl.includes('cloudfront.net/')) { return imageUrl; }
    const imageUid = imageUrl.split('site-assets/v1/')[1];
    return this.getProcessedImageURL(imageUid, opts, true);
  }
}
