import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable()
export class CanActivateAppGuard implements CanActivate {

  private cordova = !!(window as any).cordova;

  constructor(private storage: Storage, private router: Router) {
  }

  canActivate(): Promise<boolean> {
    return this.storage.get('lastSelectedCity').then((getLastSelectedCityId) => {
      if (!getLastSelectedCityId && this.cordova) {
        return this.router.navigate(['onboarding']).then(() => false);
      } else {
        return true;
      }
    });
  }
}
