import { Component, Input } from '@angular/core';
import { IOrderDetails } from '../../core/store/activities/activities.models';

@Component({
  selector: 'tickets-barcode',
  templateUrl: 'tickets-barcode.html',
  styleUrls: ['tickets-barcode.scss']
})
export class TicketsBarcodeComponent {

  @Input() order: IOrderDetails;

  constructor() {
  }

}
