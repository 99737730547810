import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class WindowResizeService {

  resizeInProgress = new BehaviorSubject(false);
  resizeMinimized = new BehaviorSubject(false);
  private prevHeight: number = window.innerHeight;

  constructor() {}

  init() {
    let resizeTimer;
    window.onresize = (e) => {
      this.resizeInProgress.next(true);
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        const currHeight = (e.target as Window).innerHeight;
        this.resizeMinimized.next(this.prevHeight > currHeight);
        this.prevHeight = currHeight;
        this.resizeInProgress.next(false);
      }, 200);
    };
  }

  destroy() {
    this.resizeInProgress.next(false);
    this.resizeMinimized.next(false);
    window.onresize = null;
  }

}
