import { createSelector, createFeatureSelector } from '@ngrx/store';
import { State, storeKey } from './search.reducer';
import * as _ from 'lodash';
import { SearchFilterFieldType } from './search.filter.model';

export const selectSearchResults = createFeatureSelector<State>(storeKey);

export const getSearchCurrentPage = createSelector(
  selectSearchResults,
  (state: State) => state.current_page
);
export const getTotalPages = createSelector(
  selectSearchResults,
  (state: State) => state.total_pages
);
export const getIsSearching = createSelector(
  selectSearchResults,
  (state: State) => {
    return state.isSearching;
  }
);
export const getIsSearchingNext = createSelector(
  selectSearchResults,
  (state: State) => {
    return state.isSearchingNext;
  }
);
export const getIsSearchingError = createSelector(
  selectSearchResults,
  (state: State) => {
    return state.isSearchingError;
  }
);
export const getIsValidToSearch = createSelector(
  selectSearchResults,
  getSearchCurrentPage,
  getTotalPages,
  getIsSearching,
  (
    state: State,
    currentPage: number,
    totalPages: number,
    isSearching: boolean
  ) => {
    return currentPage < totalPages;
  }
);

export const getCanonicalUrl = createSelector(
  selectSearchResults,
  (state: State) => _.get(state, 'canonical_url')
);

export const getSearchSelectedCategories = createSelector(
  selectSearchResults,
  (state: State) => state.filter[SearchFilterFieldType.CATEGORIES] as any[]
);

export const getSearchFilter = createSelector(
  selectSearchResults,
  (state) => state.filter
);

export const getSearchFilterDate = createSelector(
  getSearchFilter,
  (filter) => filter[SearchFilterFieldType.DATE]
);

export const getLandingPageSlug = createSelector(
  selectSearchResults,
  (state: State) => state.slug
);

export const getCurrentLandingPageData = createSelector(
  selectSearchResults,
  (state: State) => {
    return {
      mobile_display_name: state.mobile_display_name,
      image_url: state.image_url,
      description: state.description
    };
  }
);

export const getIsLandingPage = createSelector(
  selectSearchResults,
  (state: State) => state.landing_page
);

