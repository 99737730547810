import { Store } from '@ngrx/store';
import { IAppState } from './../store/app.reducers';
import * as fromCore from './../store/core';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ENV } from 'src/environments/environment';
import VersionJson from 'src/assets/api/version.json';


export interface BannerResponse {
  canShowBanner: boolean;
  explanation?: string;
}

@Injectable()
export class PwaApiService {

  baseDomain: string;

  constructor(
    private httpClient: HttpClient,
    private store: Store<IAppState>,
  ) {
    this.store.select(fromCore.getBaseDomain).subscribe(baseDomain => {
      this.baseDomain = baseDomain;
    });
  }

  requestSiteHeaders(subdomain) {
    return this.httpClient.get<any>(
      `${ENV.cached_json_external_url}/site-${subdomain}.${ENV.mode}.json?v=${VersionJson.version}`,
      {
        observe: 'body',
        responseType: 'json'
      }
    )
  }

  getVersionNum() {
    return this.httpClient.get<any>(
      `${ENV.cached_json_external_url}/version.json?v=${VersionJson.version}`,
      {
        observe: 'body',
        responseType: 'json'
      }
    ).toPromise()
  }

  getShowBanner(): Observable<BannerResponse> {
    return this.httpClient.get<BannerResponse>(
      `${this.baseDomain}/sites/banner`,
      {
        observe: 'body',
        responseType: 'json'
      }
    );
  }
}
