import cloneDeep from 'lodash/cloneDeep';
import { keys } from 'lodash';

export const deepCopyObject = (object) => cloneDeep(object);

export const browserCheck = () => {
  const n = navigator.appName, ua = navigator.userAgent
  let tem, m = ua.match(/(opera|chrome|safari|firefox|msie|trident)\/?\s*(\.?\d+(\.\d+)*)/i);
  if (m && (tem = ua.match(/version\/([\.\d]+)/i)) !== null) { m[2] = tem[1] };
  m = m ? [m[1], m[2]] : [n, navigator.appVersion, '-?'];
  return m;
}

export const cleanObject = (obj) => {
  const objCleaned = {};
  keys(obj).forEach((item) => {
    const currentValue = obj[item]
    if (currentValue !== '' && currentValue !== null) {
      objCleaned[item] = currentValue;
    }
  });
  return objCleaned;
}
