import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appGetDir]'
})
export class GetDirectionDirective {
  constructor(el: ElementRef) {
    if (document.dir === 'rtl') {
      el.nativeElement.dir = document.dir;
    }
  }
}
