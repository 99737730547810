import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromApp from '../../../../core/store/app.reducers';
import * as fromCore from './../../../../core/store/core';
import { LoadingController } from '@ionic/angular';
import { ENV } from '../../../../../environments/environment';
import { from } from 'rxjs';
import { SetUserDefinedPropertyAction } from 'ngrx-forms';
import * as fromPassword from './password.actions';
import * as fromAuth from '../auth.actions';
import { withLatestFrom, switchMap, map, catchError, delayWhen } from 'rxjs/operators';

@Injectable()
export class PasswordEffects {
  loader: any;

  @Effect()
  submitPasswordForm = this.actions$.pipe(
    ofType(fromPassword.SUBMIT_PASSWORD_FORM),
    withLatestFrom(
      this.appStore$.select(state => state.auth.passwordForm.value),
      this.appStore$.select(fromCore.getBaseDomain)
    ),
    delayWhen(() => from(this.loadingCtrl.create().then((loader) => this.loader = loader))),
    switchMap(([action, state, baseDomain]) => {
      this.loader.present();
      const payloadParams = { ...state };
      return this.httpClient
        .post(`${baseDomain}/auth/password`, payloadParams, { observe: 'body', responseType: 'json' })
        .pipe(
          map(() => {
            this.loader.dismiss();
            return {
              type: fromAuth.PASSWORD_RESET_SUCCESS
            };
          }),
          catchError(error => {
            this.loader.dismiss();
            if (error.error.errors) {
              this.appStore$.dispatch(
                new SetUserDefinedPropertyAction(
                  'password-form',
                  'serverError',
                  error.error.errors[0]
                )
              );
            } else {
              this.appStore$.dispatch(
                new SetUserDefinedPropertyAction(
                  'password-form',
                  'serverError',
                  error.message
                )
              );
             // should throw error! Need to deal with error handling
            }
            return [];
          })
        );
    })
  );

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private appStore$: Store<fromApp.IAppState>,
    private loadingCtrl: LoadingController
  ) {
    this.loader = null;
  }
}
