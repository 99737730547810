import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AuthState } from './auth.reducers';
import { IAppState } from '../../../core/store/app.reducers';

export const selectAuthState = createFeatureSelector<IAppState>('auth');
export const getAuth = createSelector(
  selectAuthState,
  (state: IAppState) => state.auth
);
export const getAuthDisplayedComponent = createSelector(
  getAuth,
  (auth: AuthState) => auth.displayedComponent
);
export const getAuthSignedIn = createSelector(
  getAuth,
  (auth: AuthState) => auth.signedIn
);

export const getIsAuthenticating = createSelector(
  getAuth,
  (auth: AuthState) => auth.authenticating
);

export const getSubmitState = createSelector(
  getAuth,
  (auth: AuthState) => auth.disableSubmit
);

export const getAuthFromMenu = createSelector(
  getAuth,
  (auth: AuthState) => auth.fromMenu
);

export const getAppleSignError = createSelector(
  getAuth,
  (auth: AuthState) => auth.appleSignError
);

export const getFacebookSignError = createSelector(
  getAuth,
  (auth: AuthState) => auth.facebookSignError
);
