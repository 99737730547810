import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromActions from './search.actions';
import { catchError, map, switchMap, tap, withLatestFrom, filter } from 'rxjs/operators';
import { FunzApiService } from '../../core/services/funz-api.service';
import { IAppState } from '../../core/store/app.reducers';
import * as fromSearchSelectors from './search.selectors';
import * as fromFunz from '../funz/store';
import * as fromCitiesSelectors from '../../core/store/cities/cities.selectors';
import * as fromCategories from '../../core/store/categories';
import { SearchFilterBuilder } from './search.filter.model';
import * as fromSearch from './index';
import { InitFilter, InitFilterSuccess, Search, UpdateFilter } from './search.actions';
import { SetSelectCity } from '../../core/store/cities';
import { AppApi } from '../../core/api/app.api';
import { Storage } from '@ionic/storage';
import { TrackingService } from '../../core/services/tracking.service';
import { NO_CITY_ID } from '../../funz/city.model';
import { APP_PAGES } from '../../pages';
import { toCamelCase, toLandingPageJson } from '../funz/store/funz.utils';
import { NavController } from '@ionic/angular';
import { PAGE_TO_URL_TRANSFORMER } from '../../app-routing.utils';
import { ResetSearchResults } from '../funz/store';
import { GeneralHelperService } from '../../core/services/general-helper.service';

@Injectable()
export class SearchEffects {

  @Effect({ dispatch: false })
  clearSearchResult$ = this.actions$.pipe(
    ofType<Search>(fromActions.ActionTypes.SEARCH),
    tap((action) => {
      if (!action.refresh) {
        this.store.dispatch(new ResetSearchResults());
      }
    })
  );

  @Effect()
  fetchNext$ = this.actions$
    .pipe(
      ofType(fromActions.ActionTypes.SEARCH_NEXT_PAGE),
      withLatestFrom(
        this.store.select(fromSearchSelectors.getSearchCurrentPage),
        this.store.select(fromSearchSelectors.getSearchFilter),
        this.store.select(fromCitiesSelectors.getSelectedCityId),
        this.store.select(fromSearchSelectors.getIsLandingPage),
        this.store.select(fromSearchSelectors.getLandingPageSlug),
      ),
      switchMap(([action, page, theFilter, cityId, landingPage, slug]) => {
        const params = new SearchFilterBuilder(theFilter).build().toHttpParams();
        const nextPageNumber = page + 1;
        if (landingPage) {
          return this.funzApi.search({ slug }, nextPageNumber, NO_CITY_ID).pipe(
            map((response) => new fromActions.SearchNextPageSuccess(response)),
            catchError((err) => of(new fromActions.SearchError(err)))
          );
        } else {
          return this.funzApi.searchWithParams(params, cityId, nextPageNumber).pipe(
            map((response) => new fromActions.SearchNextPageSuccess(response)),
            catchError((err) => of(new fromActions.SearchError(err)))
          );
        }
      })
    );

  @Effect()
  updateFunzes$: Observable<Action> = this.actions$
    .pipe(
      ofType(fromActions.ActionTypes.SEARCH_SUCCESS),
      map((action: fromActions.SearchSuccess) => action.payload),
      map((payload: any) => payload.funzes),
      map((funzes: any) => new fromFunz.UpdateFilterResults(funzes))
    );

  @Effect()
  updateFunzesPaginations$: Observable<Action> = this.actions$
    .pipe(
      ofType(fromActions.ActionTypes.SEARCH_NEXT_PAGE_SUCCESS),
      map((action: fromActions.SearchNextPageSuccess) => action.payload),
      map((payload: any) => new fromFunz.UpdateSearchResults(payload))
    );

  @Effect()
  search$ = this.actions$
    .pipe(
      ofType<Search>(fromActions.ActionTypes.SEARCH),
      withLatestFrom(
        this.store.select(fromSearchSelectors.getSearchFilter),
        this.store.select(fromCitiesSelectors.getSelectedCity),
      ),
      switchMap(([action, theFilter, city]) => {
        const params = new SearchFilterBuilder(theFilter).build();
        return this.funzApi.searchWithParams(params.toHttpParams(), city.id, 1, action.refresh).pipe(
          map((response) => {
            if (response.status && Number(response.status) !== 200) {
              return new fromSearch.SearchError(response.error);
            } else {
              return new fromActions.SearchSuccess(response);
            }
          }),
          catchError((err) => of(new fromActions.SearchError(err)))
        );
      })
    );

  @Effect({ dispatch: false })
  updateFilter$: Observable<any> = this.actions$
    .pipe(
      ofType<UpdateFilter>(fromActions.ActionTypes.UPDATE_FILTER),
      withLatestFrom(this.store.select(fromSearch.getSearchFilter)),
      tap(([action]) => {
        if (action.city) { this.store.dispatch(new SetSelectCity(action.city)); }
      })
    );

  @Effect({ dispatch: false })
  saveFilterInLocalStorage$: Observable<any> = this.actions$
    .pipe(
      ofType<UpdateFilter>(
        fromActions.ActionTypes.UPDATE_FILTER,
        fromActions.ActionTypes.RESET_DATE,
        fromActions.ActionTypes.RESET_FILTER
      ),
      withLatestFrom(this.store.select(fromSearch.getSearchFilter)),
      tap(([action, theFilter]) => {
        this.storage.set('funz_filter', JSON.stringify(theFilter))
        .catch((error: Error) => this.ghs.logStorageError(error));
        this.appApi.setLocalStorageExpiration('funz_filter', 1000 * 60 * 30);
      })
    );

  @Effect()
  initFilter$: Observable<any> = this.actions$
    .pipe(
      ofType<InitFilter>(fromActions.ActionTypes.INIT_FILTER),
      switchMap(() =>
        this.appApi.isLocalStorageExpired('funz_filter')
        .then((expired) => expired ? null : this.storage.get('funz_filter'))
      ),
      map((data) => new InitFilterSuccess(JSON.parse(data)))
    );

  @Effect()
  slugFetchDeepLink$ = this.actions$
    .pipe(
      ofType(fromActions.ActionTypes.FETCH_SLUG_DEEPLINK),
      map((action: any) => action.payload),
      switchMap((slug) => {
        const currentPage = 1;
        return this.funzApi.search({ slug }, currentPage, NO_CITY_ID);
      }),
      map((response) => new fromActions.LoadSlugDeeplink(response))
    );

  @Effect()
  slugLoadDeeplink$ = this.actions$
    .pipe(
      ofType(fromActions.ActionTypes.LOAD_SLUG_DEEPLINK),
      map((action: any) => action.payload),
      withLatestFrom(
        this.store.select(fromSearchSelectors.getLandingPageSlug),
      ),
      switchMap(([response, slug]) => {
        let returnedActions = [];
        const { funzes } = response;
        if (response.error) {
          this.navCtrl.navigateRoot(PAGE_TO_URL_TRANSFORMER.getUrlByPageName(APP_PAGES.DiscoverPage));
        } else {
          const loadFunzes = funzes.reduce(toCamelCase, {});
          const pageName = 'landing_pages#index';
          this.trackingService.pushToDataLayer({ current_page: pageName });
          returnedActions = [
            new fromFunz.LoadFunzes(loadFunzes),
            new fromFunz.UpdateFilterResults(funzes),
            new fromActions.LoadLandingPage(toLandingPageJson(slug, response)),
          ];
        }
        return returnedActions;
      })
    );

  constructor(
    private actions$: Actions,
    private funzApi: FunzApiService,
    private store: Store<IAppState>,
    private storage: Storage,
    private appApi: AppApi,
    private trackingService: TrackingService,
    private navCtrl: NavController,
    private ghs: GeneralHelperService,
  ) {}
}
