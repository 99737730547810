import { Injectable } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import * as fromCore from '../store/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../store/app.reducers';

@Injectable()
export class OpenUrlService {
  public paramsObj: any = {};
  rootDomain: string;

  constructor(private inAppBrowser: InAppBrowser, private store: Store<fromApp.IAppState>) {
    this.store.select(fromCore.getSiteHeaders)
      .subscribe(site => {
        this.rootDomain = site.root_domain;
      });
  }

  open(url = null) {
    const link = `${this.rootDomain}/${url}?only_body=true`;
    this.inAppBrowser.create(link, '_blank', 'location=no,hideurlbar=yes,zoom=no');
  }
}
