import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { get, has, isEmpty } from 'lodash';
// import * as Sentry from 'sentry-cordova';
import * as LogRocket from 'logrocket';

@Injectable()
export class ErrorTrackerService {
  trackError(error) {
    LogRocket.log('Received error, trying to log');
    if (!error || isEmpty(error)) {
      error = new Error('Passed blank object to ErrorTrackingService');
    }
    const originalError = get(error, 'originalError', error);
    try {
      const sanitizedError = has(originalError, 'stack') ? originalError : new Error(get(originalError, 'message', originalError));
      LogRocket.captureException(sanitizedError);
      // Sentry.captureException(sanitizedError);
      LogRocket.log('Log error success');
    } catch (e) {
      this.log('Exception on Logging Error', e, error);
    }
  }

  log(...args) {
    if ((window as any).fdebug) {
      console.log.apply(console, args);
    }
  }
}
