import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { zipObject } from 'lodash';
import * as LogRocket from 'logrocket';
import { ErrorTrackerService } from './error-tracker.service';

@Injectable()
export class GeneralHelperService {
  constructor(
    private storage: Storage,
    private errorTracker: ErrorTrackerService,
  ) {}

  canShowStars(starsObject) {
    return starsObject.empty_stars !== 0 || starsObject.full_stars !== 0;
  }

  logStorageError(error: Error) {
    this.errorTracker.trackError(error);

    // now try to log contents of storage
    this.storage.keys().then(keys => {
      const promises: Array<Promise<any>> = [];
      keys.map(key => promises.push(this.storage.get(key)));
      Promise.all(promises).then(data => {
        const storageContents = JSON.stringify(zipObject(keys, data), null, 2);
        LogRocket.log(`Storage contents: ${storageContents}`);
      });
    });
  }
}
