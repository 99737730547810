import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'daysHours',
  pure: false
})
export class DaysHoursPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  transform(value) {
    if (value) {
      if (value > 72) {
        return (value / 24).toFixed() + ' ' + this.translate.instant('pipes.day-hours.days')
      } else {
        return value + ' ' + this.translate.instant('pipes.day-hours.hours')
      }
    } else {
      return '';
    }
  }
}
