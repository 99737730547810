import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TrackingService } from '../../../core/services/tracking.service';
import * as fromAuthActions from './auth.actions';
import { HttpClient } from '@angular/common/http';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../core/store/app.reducers';
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
import { map, catchError, withLatestFrom, switchMap, concatMap, tap, mergeMap } from 'rxjs/operators';
import { of,  from as fromPromise } from 'rxjs';
import { get, forEach } from 'lodash';
import * as fromAuth from './';
import * as fromCore from '../../../core/store/core';
import * as fromProfile from '../../../core/store/profile';
import { TranslateService } from '@ngx-translate/core';
import { IUserData, OneSignalTagsSentKey } from '../../../core/services/response-handler.service';
import { FacebookErrorComponent } from '../../../auth-components/facebook-error/facebook-error';
import { ErrorTrackerService } from '../../../core/services/error-tracker.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthEffects {
  loader: any;
  cordova = !!(window as any).cordova;

  @Effect()
  authSignout = this.actions$.pipe(
    ofType(fromAuthActions.LOGOUT),
    withLatestFrom(this.appStore$.select(fromCore.getBaseDomain)),
    switchMap(([action, baseDomain]) => {
      return this.httpClient.delete(`${baseDomain}/auth/sign_out`);
    }),
    map((response) => (response as any).success),
    catchError((err: any) => of(new fromCore.AuthInvalid())),
    concatMap(() => [
      new fromProfile.ResetProfile(),
      new fromCore.AuthInvalid({ userInitiated: true }),
    ]),
    tap(() => {
      (window as any).localStorage.removeItem(OneSignalTagsSentKey);
      setTimeout(() => {
        this.router.navigateByUrl('/')
      });
    })
  );

  @Effect()
  authSignin = this.actions$.pipe(
    ofType(fromAuthActions.SIGNIN),
    map((action: fromAuthActions.Signin) => action.payload),
    mergeMap((profile: IUserData) => [new fromCore.SignInCompleted(profile)])
  );

  @Effect({ dispatch: false })
  authSigninError = this.actions$.pipe(
    ofType(fromAuthActions.SIGNIN_ERROR),
    map((action: fromAuthActions.SignInError) => {
      return action.payload;
    }),
    tap(async error => {
      this.loader.dismiss();
     // todo: translation server side
      const alert = await this.alertCtrl.create({
        header: error.statusText,
        subHeader: error.error.errors[0],
        buttons: ['Ok']
      });
      alert.present();
    })
  );

  @Effect({ dispatch: false })
  authSignupError = this.actions$.pipe(
    ofType(fromAuthActions.SIGNUP_ERROR),
    map((action: fromAuthActions.SignUpError) => {
      return action.payload;
    }),
    tap(async error => {
      this.loader.dismiss();
      const alert = await this.alertCtrl.create({
        header: 'Error',
        subHeader: error,
        buttons: ['Ok']
      });
      await alert.present();
    })
  );

  @Effect()
  authGoogleSignIn = this.actions$.pipe(
    ofType(fromAuthActions.GOOGLE_SIGNIN),
    map((action: fromAuthActions.GoogleSignIn) => action.payload),
    withLatestFrom(
      this.appStore$.select(fromCore.getMarketingOptIn),
      this.appStore$.select(fromCore.getBaseDomain),
      this.appStore$.select(fromCore.getLastSelectedCityId),
      this.appStore$.select(fromCore.getMarketingParams),
    ),
    switchMap(([data, marketingOptInState, baseDomain, cityId, marketingParams]) => {
      // console.log('DATA', data);
      const urlOptions = {
        googleResponse: btoa(JSON.stringify(data)),
        subscribed_to_marketing_emails: marketingOptInState,
        registration_source: this.cordova ? 'api' : 'web-mobile',
        city_id: cityId,
        referer: get(marketingParams, 'referer')
      };
      const params = new URLSearchParams();
      forEach(urlOptions, (val, key) => params.set(key, val));

      return this.httpClient.get(`${baseDomain}/auth/google/callback.json?${params.toString()}`, { observe: 'body', responseType: 'json' })
        .pipe(
          map((response: any) => {
            this.loader.dismiss();
            const newUser = get(response, 'user_data.new_user');
            this.trackingService.sendUtms(!newUser).then(() => {
              setTimeout(() => {
                this.trackingService.traceAction('google_login_success', {
                  type: newUser ? 'Registration' : 'Login'
                });
                if (this.cordova && newUser) {
                  this.trackingService.trackAppsFlyer('af_complete_registration', {
                    af_customer_user_id: get(response, 'user_data.user_id'),
                    af_registration_method: 'google'
                  });
                }
                if (this.cordova && !newUser) {
                  this.trackingService.trackAppsFlyer('af_login', {
                    af_customer_user_id: get(response, 'user_data.user_id'),
                    af_registration_method: 'google'
                  });
                }
              }, 1000);
            })
            return new fromAuth.Signin(response);
          }),
          catchError(error => {
            this.loader.dismiss();
            return of({
              type: fromCore.HANDLE_ERROR,
              payload: error
            });
          })
        );
    })
  );

  @Effect({ dispatch: false })
  authGoogleSignInError = this.actions$.pipe(
    ofType(fromAuthActions.GOOGLE_SIGNIN_ERROR),
    map((action: fromAuthActions.GoogleSignInError) => action.payload),
    tap(error => {
      if (this.loader && typeof this.loader.dismiss === 'function') {
        this.loader.dismiss();
      }
      this.errorTracker.trackError(error);
    })
  );

  @Effect()
  authAppleSignIn = this.actions$.pipe(
    ofType(fromAuthActions.APPLE_SIGNIN),
    map((action: fromAuthActions.AppleSignIn) => action.payload),
    withLatestFrom(
      this.appStore$.select(fromCore.getMarketingOptIn),
      this.appStore$.select(fromCore.getBaseDomain),
      this.appStore$.select(fromCore.getLastSelectedCityId),
      this.appStore$.select(fromCore.getMarketingParams),
    ),
    switchMap(([data, marketingOptInState, baseDomain, cityId, marketingParams]) => {
      const urlOptions = {
        appleResponse: btoa(JSON.stringify(data)),
        subscribed_to_marketing_emails: marketingOptInState,
        registration_source: this.cordova ? 'api' : 'web-mobile',
        city_id: cityId,
        referer: get(marketingParams, 'referer')
      };
      const params = new URLSearchParams();
      forEach(urlOptions, (val, key) => params.set(key, val));

      return this.httpClient.get(`${baseDomain}/auth/apple/callback.json?${params.toString()}`, { observe: 'body', responseType: 'json' })
        .pipe(
          map((response: any) => {
            this.loader.dismiss();
            const newUser = get(response, 'user_data.new_user');
            this.trackingService.sendUtms(!newUser).then(() => {
              setTimeout(() => {
                this.trackingService.traceAction('apple_login_success', {
                  type: newUser ? 'Registration' : 'Login'
                });
                if (this.cordova && newUser) {
                  this.trackingService.trackAppsFlyer('af_complete_registration', {
                    af_customer_user_id: get(response, 'user_data.user_id'),
                    af_registration_method: 'apple'
                  });
                }
                if (this.cordova && !newUser) {
                  this.trackingService.trackAppsFlyer('af_login', {
                    af_customer_user_id: get(response, 'user_data.user_id'),
                    af_registration_method: 'apple'
                  });
                }
              }, 1000);
            })
            return new fromAuth.Signin(response);
          }),
          catchError(error => {
            this.loader.dismiss();
            return of({
              type: fromCore.HANDLE_ERROR,
              payload: error
            });
          })
        );
    })
  );

  @Effect({ dispatch: false })
  authAppleSignInError = this.actions$.pipe(
    ofType(fromAuthActions.APPLE_SIGNIN_ERROR),
    map((action: fromAuthActions.AppleSignInError) => action.payload),
    tap(error => {
      if (this.loader && typeof this.loader.dismiss === 'function') {
        this.loader.dismiss();
      }
      this.errorTracker.trackError(error);
    })
  );

  @Effect()
  authTryFBSignIn = this.actions$.pipe(
    ofType(fromAuthActions.TRY_FB_SIGNIN),
    switchMap((action: fromAuthActions.TryFBSignIn) => {
      return fromPromise(this.fb.login(['email'])).pipe(
        map((res: any) => {
          return {
            type: fromAuthActions.FB_SIGNIN,
            payload: {
              accessToken: res.authResponse.accessToken,
              expiresIn: res.authResponse.expiresIn
            }
          };
        }),
        catchError(err => of(new fromAuthActions.FBSignInError(err)))
      );
    })
  );

  @Effect()
  authFBSignIn = this.actions$.pipe(
    ofType(fromAuthActions.FB_SIGNIN),
    map((action: fromAuthActions.FBSignIn) => action.payload),
    withLatestFrom(
      this.appStore$.select(fromCore.getMarketingOptIn),
      this.appStore$.select(fromCore.getBaseDomain),
      this.appStore$.select(fromCore.getLastSelectedCityId),
      this.appStore$.select(fromCore.getMarketingParams),
    ),
    switchMap(([data, marketingOptInState, baseDomain, cityId, marketingParams]) => {
      const urlOptions = {
        accessToken: data.accessToken,
        expiresIn: data.expiresIn,
        subscribed_to_marketing_emails: marketingOptInState,
        registration_source: this.cordova ? 'api' : 'web-mobile',
        city_id: cityId,
        referer: get(marketingParams, 'referer')
      };
      const params = new URLSearchParams();
      forEach(urlOptions, (val, key) => params.set(key, val));
      return this.httpClient.get(
        `${baseDomain}/auth/facebook/callback.json?${params.toString()}`, { observe: 'body', responseType: 'json' }
      ).pipe(
        map((response: any) => {
          this.loader.dismiss();
          const newUser = get(response, 'user_data.new_user');
          this.trackingService.sendUtms(!newUser).then(() => {
            setTimeout(() => {
              this.trackingService.traceAction('facebook_login_success', {
                fb_id: get(response, 'user_data.fb_id'),
                type: newUser ? 'Registration' : 'Login'
              });
              if (this.cordova && newUser) {
                this.trackingService.trackAppsFlyer('af_complete_registration', {
                  af_customer_user_id: get(response, 'user_data.user_id'),
                  af_registration_method: 'facebook'
                });
              }
              if (this.cordova && !newUser) {
                this.trackingService.trackAppsFlyer('af_login', {
                  af_customer_user_id: get(response, 'user_data.user_id'),
                  af_registration_method: 'facebook'
                });
              }
            }, 1000)
          });
          return new fromAuth.Signin(response);
        }),
        catchError(error => {
          this.loader.dismiss();
          this.trackingService.traceAction('facebook_login_failure');
          return of({
            type: fromCore.HANDLE_ERROR,
            payload: error
          });
        }));
    })
  );

  @Effect({ dispatch: false })
  authFBSignInError = this.actions$.pipe(
    ofType(fromAuthActions.FB_SIGNIN_ERROR),
    map((action: fromAuthActions.FBSignInError) => action.payload),
    tap(error => {
      this.loader.dismiss();
      const e = new Error(error.errorMessage);
      this.errorTracker.trackError(error);
      this.showFBError();
    })
  );

  @Effect({ dispatch: false })
  openSignup = this.actions$.pipe(
    ofType(fromAuthActions.OPEN_SIGNUP),
    tap(() => {
      this.trackingService.traceAction('login_signup_click', { type: 'Registration' });
    })
  );

  @Effect({ dispatch: false })
  openSignupPage = this.actions$.pipe(
    ofType(fromAuthActions.OPEN_SIGNUP_PAGE),
    tap(() => {
      this.trackingService.traceAction('login_signup_click', { type: 'signup' });
    })
  );

  @Effect({ dispatch: false })
  openSignin = this.actions$.pipe(
    ofType(fromAuthActions.OPEN_SIGNIN),
    tap(() => {
      this.trackingService.traceAction('login_signup_click', { type: 'Login' });
    })
  );

  @Effect({ dispatch: false })
  authPasswordResetError = this.actions$.pipe(
    ofType(fromAuthActions.PASSWORD_RESET_ERROR),
    map((action: fromAuthActions.PasswordResetError) => {
      return action.payload;
    }),
    tap(async error => {
      this.loader.dismiss();
      const alert = await this.alertCtrl.create({
          header: 'Error',
          subHeader: error,
          buttons: ['Ok']
        });
      await alert.present();
    })
  );

  @Effect({ dispatch: false })
  showLoader$ = this.actions$.pipe(
    ofType(fromAuthActions.SHOW_LOADER),
    tap(async () => {
      this.loader = await this.loadingCtrl.create();
      this.loader.present();
    })
  );


  async showFBError() {
    const modal = await this.modalCtrl.create({
      component: FacebookErrorComponent,
      cssClass: 'location-selector'
    });
    return await modal.present();
  }

  getFBErrorTexts() {
    return this.translateService
      .get([
        'store.auth.fb_failed.title',
        'store.auth.fb_failed.message',
        'store.auth.fb_failed.button'
      ]);
  }

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private router: Router,
    private alertCtrl: AlertController,
    private appStore$: Store<fromApp.IAppState>,
    private fb: Facebook,
    private loadingCtrl: LoadingController,
    private translateService: TranslateService,
    private modalCtrl: ModalController,
    private trackingService: TrackingService,
    private errorTracker: ErrorTrackerService,
  ) {
    this.loader = this.loadingCtrl.create();
  }
}
