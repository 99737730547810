
import { take } from 'rxjs/operators';
import { Funz } from '../../funz/funz.model';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { chain } from 'lodash';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  EventEmitter,
  Output
} from '@angular/core';
import { CategoryBox } from '../../funz/category.model';
import { NavController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import * as fromCore from '../../core/store/core';
import * as fromApp from '../../core/store/app.reducers';
import { APP_PAGES } from '../../pages';
import { TrackingService } from '../../core/services/tracking.service';
import { PAGE_TO_URL_TRANSFORMER } from '../../app-routing.utils';

@Component({
  selector: 'funz-scroll',
  templateUrl: 'funz-scroll.html',
  styleUrls: ['funz-scroll.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FunzScrollComponent implements OnInit, OnChanges {

  @Input() showHeart = true;
  @Input() category: {
    category: CategoryBox;
    funzes: Funz[];
    param?: string
  };
  @Input() hideSeeAll: boolean;
  @Output() onScroll = new EventEmitter<any>();
  timeOutHolder: any;
  isIL = false;

  constructor(
    private navCtrl: NavController,
    private cd: ChangeDetectorRef,
    private store: Store<fromApp.IAppState>,
    private platform: Platform,
    private trackingService: TrackingService,
  ) {}

  ngOnInit(): void {
    this.store.select(fromCore.getSiteHeadersLocationSuffix).pipe(
      take(1))
      .subscribe(suffix => this.isIL = suffix === 'il');
    this.cd.detach();
  }

  // apply our own diff check to see if the funz are different
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.category) {
      return;
    }
    if (changes.category.firstChange) {
      this.cd.detectChanges();
    } else {
      const { currentValue, previousValue } = changes.category;
      // run CD only if the funz are not equal
      const equal = chain(currentValue.funzes)
        .differenceWith(previousValue.funzes, isEqual)
        .isEmpty()
        .value();

      if (!equal) {
        this.cd.detectChanges();
      }
    }
  }

  get mobileDisplayName() {
    return get(this, 'category.category.mobileDisplayName', '');
  }

  get funzes() {
    return this.category.funzes.slice(0, 10);
  }

  // used by ngFor on the template
  trackByFunzIdFn(index, item) {
    return item.id;
  }

  navigate(category: CategoryBox) {
    const { id, slug, lp } = category;
    this.store.dispatch(new fromCore.SetCategory(category));
    this.navCtrl.navigateRoot(
      PAGE_TO_URL_TRANSFORMER.getUrlByPageName( APP_PAGES.CategoryPage, { slug } )
    );
    this.trackingService.traceAction('category_view_all_click', { id });
  }

  scrollHandler(event) {
    if (get(this, 'category.category')) {
      if (this.platform.is('ios') && this.isIL) {
        if (event.target.scrollLeft < 0) { this.onScroll.emit(this.category.category.id); }
      } else {
        this.onScroll.emit(this.category.category.id);
      }
    }

    if (this.timeOutHolder) {
      clearTimeout(this.timeOutHolder);
      this.timeOutHolder = null;
    }
    this.timeOutHolder = setTimeout(() => {
      // send the event only when the user has stopped scrolling for at least 500ms
      this.trackingService.traceAction('funzes_in_category_scroll', { category: this.mobileDisplayName.trim() });
    }, 500);
  }
}
