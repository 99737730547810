import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'eventBoxDate',
})
export class EventBoxDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string, ...args) {
    const currentLang = this.translateService.currentLang;
    const datePipe: DatePipe = new DatePipe(currentLang);
    const utcOffset = `UTC+${parseInt(value.split('+').pop(), 10)}`;
    return currentLang === 'he' ?
      `${datePipe.transform(value, 'd', utcOffset)} ב${datePipe.transform(value, 'MMMM', utcOffset)}` :
      `${datePipe.transform(value, 'MMMM d', utcOffset)}`;
  }
}
