import { take, skip } from 'rxjs/operators';
import { TrackingService } from '../../core/services/tracking.service';
import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, DoCheck,
  EventEmitter, NgZone,
  Output, QueryList, ViewChildren, OnDestroy, OnInit,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { OpenSignIn } from '../../shared-stores/auth/store';
import * as fromApp from '../../core/store/app.reducers';
import * as fromAuth from '../../shared-stores/auth/store';
import { getSubmitState } from '../../shared-stores/auth/store';
import { FacebookProvider } from '../../shared-stores/auth/providers/facebook';
import { isEmpty, keys } from 'lodash';
import { WindowResizeService } from 'src/app/core/services/window-resize.service';
import { ResetAction, SetValueAction } from 'ngrx-forms';
import { REGISTER_FORM_ID, REGISTER_FORM_INITIAL_STATE_VALUE } from '../../shared-stores/auth/store/register/register.reducers';
import * as fromCore from '../../core/store/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { OpenUrlService } from '../../core/services/open-url.service';
import {
  SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest
} from '@ionic-native/sign-in-with-apple/ngx';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'register-email',
  templateUrl: 'register-email.html',
  styleUrls: ['register-email.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterEmailComponent implements OnInit, DoCheck, OnDestroy {
  @ViewChildren('error') errors: QueryList<any>;
  @Output() register = new EventEmitter();

  showErrors = false;
  showMainError = false;
  formState: Observable<any>;
  fromMenu$: Observable<any> = this.store.select(fromAuth.getAuthFromMenu);
  objectKeys = keys;
  isDisableSubmit$ = this.store.select(getSubmitState);
  facebookSignError$: Observable<boolean> = this.store.select(fromAuth.getFacebookSignError);
  appleSignError$: Observable<boolean> = this.store.select(fromAuth.getAppleSignError);
  windowObj = window as any;
  cordova = !!this.windowObj.cordova;
  passwordFieldType = 'password';
  isIL: boolean;
  tncChecked = false;
  tncShowErrors = false;
  tncText: SafeHtml = '';
  private resizeSubscription: Subscription;

  constructor(
    public platform: Platform,
    private store: Store<fromApp.IAppState>,
    private facebookProvider: FacebookProvider,
    private trackingService: TrackingService,
    private detectorRef: ChangeDetectorRef,
    private windoResizeService: WindowResizeService,
    private translateService: TranslateService,
    private domSanitizer: DomSanitizer,
    private ngZone: NgZone,
    private openUrlService: OpenUrlService,
    private signInWithApple: SignInWithApple,
  ) {
    if (!this.cordova) {
      this.facebookProvider.initialize();
    }
    this.translateService.get('pages.authentication.landing.tnc').subscribe(s => {
      this.tncText = this.domSanitizer.bypassSecurityTrustHtml(s);
    });
    this.formState = this.store.select('auth').pipe(select('registerForm'));
    this.windoResizeService.init();
    this.store.select(fromCore.getSiteHeadersLocationSuffix).pipe(take(1))
      .subscribe(site => this.isIL = site === 'il');
  }

  ngOnInit(): void {
    this.windowObj.openUrl = this.exOpenUrl.bind(this);
    this.resetForm();
  }

  exOpenUrl(key) {
    this.ngZone.run(() => this.inOpenUrl(key));
  }

  inOpenUrl(url) {
    this.openUrlService.open(url);
  }

  onSubmit() {
    this.store.dispatch(new fromAuth.ResetFbError());
    this.formState.pipe(take(1)).subscribe(resolvedFormState => {
      this.showErrors = true;
      this.tncShowErrors = !this.tncChecked && this.isIL;
      if (isEmpty(resolvedFormState.errors) && !this.tncShowErrors) {
        this.trackingService.traceAction('email_login_click', { type: 'Registration' });
        this.register.emit();
      }
    });
  }

  goToEmailSignIn() {
    this.store.dispatch(new OpenSignIn());
  }

  doAppleSignUp() {
    this.store.dispatch(new fromAuth.ShowLoader());
    this.signInWithApple.signin({
      requestedScopes: [
        ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
        ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
      ]
    }).then((res: AppleSignInResponse) => {
      this.store.dispatch(new fromAuth.AppleSignIn(res));
    }).catch((error: AppleSignInErrorResponse) => {
      this.store.dispatch(new fromAuth.AppleSignInError(error));
    });
  }

  doFacebookSignUp() {
    this.showErrors = false;
    this.showMainError = false;
    this.trackingService.traceAction('facebook_login_click', { type: 'Registration' });
    this.store.dispatch(new fromAuth.ShowLoader());
    if (this.cordova) {
      this.store.dispatch(new fromAuth.TryFBSignIn());
    } else {
      this.facebookProvider.signIn();
    }
  }

  onFocus(e) {
    if (!this.resizeSubscription) {
      this.resizeSubscription = this.windoResizeService.resizeInProgress.pipe(skip(1))
      .subscribe(resizeInProgress => {
        if (!resizeInProgress) {
          e.srcElement.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
          this.onBlur();
        }
      });
    } else {
      e.srcElement.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
    }
  }

  resetForm() {
    this.store.dispatch(new SetValueAction(REGISTER_FORM_ID, REGISTER_FORM_INITIAL_STATE_VALUE));
    this.store.dispatch(new ResetAction(REGISTER_FORM_ID));
  }

  onBlur() {
    if (this.resizeSubscription) { this.resizeSubscription.unsubscribe(); }
  }

  toggleTncChecked() {
    this.tncChecked = !this.tncChecked;
  }

  changePasswordFieldType() {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }

  ngDoCheck(): void {
    const newShowMainError = !!(this.errors && this.errors.length);
    if (newShowMainError !== this.showMainError) {
      this.showMainError = newShowMainError;
      this.detectorRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this.windowObj.openUrl = null;
    this.store.dispatch(new fromAuth.ResetFbError());
    this.windoResizeService.destroy();
  }
}
