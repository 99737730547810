import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import * as fromAuth from '../../shared-stores/auth/store';
import * as fromApp from '../../core/store/app.reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../../core/services/authorization.service';
import { AppApi } from '../../core/api/app.api';
import { SiteHeaders } from '../../core/models/siteHeaders.model';
import { FacebookProvider } from '../../shared-stores/auth/providers/facebook';

@Component({
  selector: 'auth',
  templateUrl: 'auth.html'
})
export class AuthComponent implements OnInit {
  @Input() site: SiteHeaders;
  @Input() authType: string;
  @Output() login = new EventEmitter();
  @Output() skip = new EventEmitter();
  windowObj = window as any;

  constructor(
    private store: Store<fromAuth.State>,
    private appStore: Store<fromApp.IAppState>,
    private auth: AuthorizationService,
    private appApi: AppApi,
    private facebookProvider: FacebookProvider
  ) {
    if (!this.windowObj.cordova) {
      this.facebookProvider.initialize();
    }
  }

  ngOnInit(): void {
    this.authType === 'Login' ? this.appStore.dispatch(new fromAuth.OpenSignIn()) : this.appStore.dispatch(new fromAuth.OpenSignUp());
  }

  backToRoot() {
    this.skipLogin();
  }

  skipLogin() {
    this.skip.emit();
  }

  handleLogin() {
    this.store.dispatch(new fromAuth.SubmitLoginForm());
  }

  handleRegister() {
    this.store.dispatch(new fromAuth.SubmitRegisterForm());
  }

  handleResetPassword() {
    this.store.dispatch(new fromAuth.SubmitPasswordForm());
  }
}
