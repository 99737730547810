import {
  Component, Input, OnInit, ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IonSlides, ModalController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { assign } from 'lodash';
import { Observable } from 'rxjs';
import { TrackingService } from 'src/app/core/services/tracking.service';
import { Funz } from 'src/app/funz/funz.model';
import * as fromApp from '../../core/store/app.reducers';
import * as fromFunz from '../../shared-stores/funz/store';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider-modal.component.html',
  styleUrls: ['./image-slider-modal.component.scss'],
})

export class ImageSliderModalComponent implements OnInit {

  @ViewChild(IonSlides, { static: false }) slides: IonSlides;
  @Input() mediaArray: any[];
  @Input() selectedImageIndex = 1;
  @Input() currentFunzId;
  selectedSlideIndex = 1;
  galleryVideosUrls = {};
  funz$: Observable<Funz>;
  displaySelectedIndex = false;
  slideOpts = {
    initialSlide: this.selectedImageIndex || 1,
    speed: 400,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      dynamicBullets: true,
      dynamicMainBullets: 1
    },
    zoom: {
      maxRatio: 2,
    },
  };
  showSlider = false;
  isAndroid = this.platform.is('android');

  constructor(
    public modalCtrl: ModalController,
    private sanitizer: DomSanitizer,
    private trackingService: TrackingService,
    private store: Store<fromApp.IAppState>,
    private platform: Platform
  ) { }

  ngOnInit() {
    if (this.mediaArray?.length) {
      this.generateVideoUrls(this.mediaArray);
    }
    this.funz$ = this.store.select(fromFunz.getFunzById(this.currentFunzId));
  }

  ionViewDidEnter() {
    if (this.slides) {
      this.slides.update();
      this.goToSlide(this.selectedImageIndex);
      this.displaySelectedIndex = this.mediaArray.length > 1;
    }
  }

  goToSlide(index) {
    if (this.slides) {
      this.selectedSlideIndex = index;
      this.slides.slideTo(index);
    }
  }

  async slideChanged() {
    if (this.slides) {
      this.selectedSlideIndex = await this.slides.getActiveIndex();
    }
  }

  generateVideoUrls(gal) {
    if (gal && gal.length) {
      this.galleryVideosUrls = gal.filter(item => item.kind === 'video')
        .reduce((acc, item) => assign(acc, { [item.video_id]: this.getVideoURL(item) }), {});
    }
  }

  getVideoURL(imageGallery) {
    const url = `https://www.youtube.com/embed/${imageGallery.video_id}?autoplay=0&showinfo=0&controls=0`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  trackFunzShare() {
    this.trackingService.traceAction('share_icon_click', { source: 'funz' });
  }
}

