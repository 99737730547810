import * as CoreActions from './core.actions';
import { APP_PAGES } from '../../../pages';
import { AuthHeaders } from '../../models/authHeaders.model';
import { SiteHeaders } from '../../models/siteHeaders.model';
import { CategoryBox } from '../../../funz/category.model';
import { ENV } from '../../../../environments/environment';
import { MarketingParams } from '../../models/marketingParams.model';

export interface ICoreState {
  authHeaders: AuthHeaders;
  siteHeaders: SiteHeaders;
  marketingParams: MarketingParams;
  signedIn: boolean;
  authBootstrap: boolean;
  authenticating: boolean;
  bootstrapped: boolean;
  bootstrapping: boolean;
  rootPage: string;
  requestedPage: string;
  requestedSubPage: string;
  requestedSubPageParams: any;
  isNetworkConnected: boolean;
  isLocationAvailable: boolean;
  deeplinkedPage: string;
  deeplinkedPageParams: any;
  onboarded: boolean;
  previewImageUrl: string;
  loginVideoUrl: string;
  marketingOptIn: boolean;
  firstTime: boolean;
  openedViaDeeplink: boolean;
  onboarding: boolean;
  deeplinkString: string;
  authGuardInitiatedAuthentication: boolean;
  pwaMode: boolean;
  pwaDeeplink: string;
  isGelocationPrompted: boolean;
  lastSelectedCityId: number;
  tracking: any;
  currentCategory: CategoryBox;
  pwaDeeplinkedSlug: string;
  localStorageHeadersLoaded: boolean;
  pwaRequestedPage: string;
  pwaRequestedPageParams: any;
  pwaShowFooter: boolean;
  authHeadersSaved: boolean;
  siteHeadersSaved: boolean;
}

const initialState: ICoreState = {
  authHeaders: {
    accessToken: null,
    client: null,
    expiry: null,
    tokenType: null,
    uid: null
  },
  siteHeaders: {
    domain: null,
    subdomain: null,
    locales: 'en-GB',
    support_email: '',
    support_phone: null,
    direction: '',
    currency_code: null,
    api_domain: null,
    base_domain: ENV.default_base_domain,
    root_domain: ENV.default_root_domain,
    root_api_domain: ENV.default_root_api_domain,
    default_marketing_opt_in: false,
    mobile_hp_image: [],
    mobile_video:
      'https://res.cloudinary.com/dnn7h5tec/video/upload/q_auto,fl_lossy/stage-site-assets/mobile/login-video/vid_uk_nosound.mp4',
    mobile_preview_image:
      'https://res.cloudinary.com/dnn7h5tec/video/upload/q_auto,f_auto,fl_lossy/stage-site-assets/mobile/login-video/vid_uk.png',
    social_usernames: { instagram: '', facebook: '', twitter: '', linkedin: '', youtube: '' },
    city_on_boarding: true,
    cities: [],
    site_id: null,
    supported_site: true,
    general_message: ''
  },
  marketingParams: {},
  authBootstrap: false,
  signedIn: null,
  authenticating: false,
  bootstrapped: false,
  bootstrapping: false,
  rootPage: !!(window as any).cordova ? APP_PAGES.LoaderPage : APP_PAGES.DiscoverPage,
  requestedPage: APP_PAGES.DiscoverPage,
  requestedSubPage: '',
  requestedSubPageParams: false,
  deeplinkedPage: '',
  deeplinkedPageParams: false,
  isNetworkConnected: true,
  isLocationAvailable: false,
  onboarded: false,
  previewImageUrl:
    'https://res.cloudinary.com/dnn7h5tec/video/upload/q_auto,f_auto,fl_lossy/stage-site-assets/mobile/login-video/vid_uk.png',
  loginVideoUrl:
    'https://res.cloudinary.com/dnn7h5tec/video/upload/q_auto,fl_lossy/stage-site-assets/mobile/login-video/vid_uk.mp4',
  marketingOptIn: false,
  firstTime: false,
  openedViaDeeplink: false,
  onboarding: false,
  deeplinkString: '',
  authGuardInitiatedAuthentication: false,
  isGelocationPrompted: false,
  pwaMode: false,
  pwaDeeplink: '',
  lastSelectedCityId: null,
  tracking: {},
  currentCategory: null,
  pwaDeeplinkedSlug: null,
  localStorageHeadersLoaded: false,
  pwaRequestedPage: '',
  pwaRequestedPageParams: false,
  pwaShowFooter: false,
  authHeadersSaved: false,
  siteHeadersSaved: false,
};

declare global {
  interface Window { PwaDeeplink: any; }
}
export function coreReducer(
  state = initialState,
  action: CoreActions.CoreActions
) {
  switch (action.type) {
    case CoreActions.INITIALIZE_PWA:
      return {
        ...state,
        pwaMode: true
      };
    case CoreActions.LOAD_HEADERS:
    case CoreActions.SET_HEADERS:
      return {
        ...state,
        authHeaders: action.payload,
        authBootstrap: true,
        signedIn: !!action.payload.accessToken,
        bootstrapped: true,
        authHeadersSaved: false
      };
    case CoreActions.AUTH_INVALID:
      return {
        ...state,
        authHeaders: {
          accessToken: null,
          client: null,
          expiry: null,
          tokenType: null,
          uid: null
        },
        signedIn: false,
        rootPage: state.rootPage,
        requestedPage: state.rootPage,
        bootstrapped: true,
        requestedSubPageParams: false,
        openedViaDeeplink: false,
        authGuardInitiatedAuthentication: false
      };
    case CoreActions.BOOTSTRAP_AUTH_VALID:
      return {
        ...state,
        bootstrapped: true,
        onboarded: true,
        rootPage: state.pwaDeeplink ? state.rootPage : (state.deeplinkedPage || APP_PAGES.DiscoverPage),
        deeplinkedPage: '',
        onboarding: false
      };
    case CoreActions.LOGIN_SKIPPED:
      return {
        ...state,
        onboarded: true,
        rootPage: (state.pwaDeeplink && state.pwaDeeplink.length) ||
          (state.pwaRequestedPage && state.pwaRequestedPage.length) ?
            state.rootPage :
            (state.deeplinkedPage || APP_PAGES.DiscoverPage),
        deeplinkedPage: '',
        onboarding: false,
        authGuardInitiatedAuthentication: false
      };
    case CoreActions.SET_ROOT_PAGE:
      return {
        ...state,
        rootPage: action.payload
      };
    case CoreActions.ActionTypes.AUTH_COMPLETED: {
      return {
        ...state,
        onboarded: true,
        rootPage:
          state.deeplinkedPage || state.requestedPage || (state.rootPage === APP_PAGES.AuthPage ?  APP_PAGES.DiscoverPage : state.rootPage),
        deeplinkedPage: '',
        requestedPage: '',
        authGuardInitiatedAuthentication: false
      };
    }

    case CoreActions.REQUEST_PAGE: {
      const { signedIn } = state;
      const { payload: { page, params } } = action;
      return {
        ...state,
        requestedSubPage: signedIn ? '' : page,
        requestedSubPageParams: params || false
      };
    }

    case CoreActions.CLEAR_REQUESTED_PAGE_PARAMS: {
      return {
        ...state,
        requestedSubPage: '',
        requestedSubPageParams: false
      };
    }

    case CoreActions.SET_REQUESTED_PAGE_PARAMS: {
      return {
        ...state,
        requestedSubPageParams: action.payload
      };
    }

    case CoreActions.SET_DEEPLINKED_PAGE:
      if (state.deeplinkedPage === action.payload) {
        return state;
      }
      return {
        ...state,
        rootPage: action.payload,
        deeplinkedPage: action.payload,
        openedViaDeeplink: true
      };

    case CoreActions.SET_DEEPLINKED_PAGE_ONBOARDING:
      if (state.deeplinkedPage === action.payload) {
        return state;
      }
      return {
        ...state,
        deeplinkedPage: action.payload,
        openedViaDeeplink: true
      };

    case CoreActions.SET_DEEPLINKED_PAGE_PARAMS:
      return {
        ...state,
        deeplinkedPageParams: action.payload
      };

    case CoreActions.CLEAR_DEEPLINKED_PAGE_PARAMS:
      return {
        ...state,
        deeplinkedPageParams: false
      };

    case CoreActions.RESET_DEEPLINKED_PAGE:
      return {
        ...state,
        deeplinkedPage: initialState.deeplinkedPage
      };

    case CoreActions.SET_CONNECTION_STATUS: {
      return {
        ...state,
        isNetworkConnected: action.payload
      };
    }

    case CoreActions.SET_LOCATION_PERMISSION: {
      return {
        ...state,
        isLocationAvailable: action.payload
      };
    }

    case CoreActions.REQUEST_LOCATION_PERMISSION: {
      return {
        ...state,
        isGelocationPrompted: true
      };
    }

    case CoreActions.ActionTypes.SET_SITE_HEADERS:
      const { payload } = action;
      const protocolUrl = state.siteHeaders.base_domain.split('://');
      const apiPathParts = protocolUrl[1].split('/');
      apiPathParts.shift();
      const apiPath = apiPathParts.join('/');
      const rootDomain = `${protocolUrl[0]}://${payload.domain}`;
      const rootApiDomain = `${protocolUrl[0]}://${payload.api_domain}`;
      const baseDomain = `${rootApiDomain}/${apiPath}`;
      return {
        ...state,
        siteHeaders: {
          ...payload,
          root_domain: rootDomain,
          root_api_domain: rootApiDomain,
          base_domain: baseDomain
        },
        siteHeadersSaved: true
      };

    case CoreActions.SET_DEFAULT_MARKETING_OPT_IN:
      if (!state.onboarded) {
        return {
          ...state,
          marketingOptIn: action.payload
        };
      } else {
        return state;
      }

    case CoreActions.SET_TRACKING:
      return { ...state, tracking: { ...state.tracking, ...action.payload } };

    case CoreActions.SET_CATEGORY:
      return { ...state, currentCategory: action.payload };

    case CoreActions.TOGGLE_MARKETING_OPT_IN:
      return {
        ...state,
        marketingOptIn: !state.marketingOptIn
      };

    case CoreActions.SET_FIRST_TIME: {
      return {
        ...state,
        firstTime: true
      };
    }

    case CoreActions.RESET_FIRST_TIME: {
      return {
        ...state,
        firstTime: false
      };
    }

    case CoreActions.ActionTypes.GET_LOCATION: {
      return {
        ...state,
        onboarding: true
      };
    }

    case CoreActions.SET_DEEPLINK_STRING: {
      return {
        ...state,
        deeplinkString: action.payload
      };
    }

    case CoreActions.SET_AUTH_GUARD_INITIATED_AUTHENTICATION: {
      return {
        ...state,
        authGuardInitiatedAuthentication: true,
      };
    }

    case CoreActions.RESET_PWA_DEEPLINK: {
      return {
        ...state,
        pwaDeeplink: ''
      };
    }

    case CoreActions.SET_LAST_SELECTED_CITY_ID: {
      return {
        ...state,
        lastSelectedCityId: action.payload,
        localStorageHeadersLoaded: true
      };
    }

    case CoreActions.RESET_PWA_DEEPLINKED_SLUG: {
      return {
        ...state,
        pwaDeeplinkedSlug: null
      };
    }

    case CoreActions.REQUEST_PAGE_PWA: {
      return {
        ...state,
        pwaRequestedPage: action.payload.page,
        pwaRequestedPageParams: action.payload.params
      };
    }

    case CoreActions.CLEAR_REQUESTED_PAGE: {
      return {
        ...state,
        pwaRequestedPage: initialState.pwaRequestedPage,
        pwaRequestedPageParams: initialState.pwaRequestedPageParams
      };
    }

    case CoreActions.SHOW_PWA_FOOTER: {
      return {
        ...state,
        pwaShowFooter: true
      };
    }

    case CoreActions.HIDE_PWA_FOOTER: {
      return {
        ...state,
        pwaShowFooter: false
      };
    }

    case CoreActions.AUTH_HEADERS_SAVED: {
      return {
        ...state,
        authHeadersSaved: true
      };
    }

    case CoreActions.SITE_HEADERS_SAVED: {
      return {
        ...state,
        siteHeadersSaved: true
      };
    }

    case CoreActions.SAVE_MARKETING_PARAMS:
    case CoreActions.RESTORE_MARKETING_PARAMS: {
      return {
        ...state,
        marketingParams: action.payload
      };
    }

    default:
      return state;
  }
}
