import { Directive, HostListener, Input } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ModalController } from '@ionic/angular';
import { ISharedUrls } from '../../funz/funz.model';
import { ShareModalViewComponent } from './share-modal-view/share-modal-view.component';

@Directive({
  selector: '[appShare]'
})
export class ShareDirective {
  cordova = !!(window as any).cordova;
  @Input() shareUrl: string;
  @Input() shareText: string;
  @Input() shareUrls: ISharedUrls[];
  @Input() shareSource: string;
  @HostListener('click', ['$event'])
  public handleClick(event: MouseEvent) {
    event.stopImmediatePropagation();
    event.preventDefault();
    this.share();
    return true;
  }

  constructor(
    private socialSharing: SocialSharing,
    private modalCtrl: ModalController,
  ) {}

  async share() {
    if (this.cordova) {
      this.socialSharing.share(this.shareText, '', '', this.shareUrl);
    } else {
      const sharePopup = await this.modalCtrl.create({
        component: ShareModalViewComponent,
        componentProps: { urls: this.shareUrls, shareSource: this.shareSource, shareUrl: this.shareUrl },
        cssClass: 'share-modal-view'
      });
      sharePopup.present();
    }
  }
}
