import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromProfile from '../../core/store/profile';
import * as fromApp from '../../core/store/app.reducers';

@Component({
  selector: 'loyalty-card',
  templateUrl: 'loyalty-card.html'
})
export class LoyaltyCardComponent {
  @Input() points: number;
  @Input() totalLoyaltyPoints: number;
  @Input() slanted: boolean;

  loyaltyCardMessage$ = this.store.select(fromProfile.getLoyaltyCardMessage);
  constructor(private store: Store<fromApp.IAppState>) {}
}
