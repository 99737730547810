import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FilterType } from '../../funz/filter.model';
import { AppApi } from '../../core/api/app.api';
import * as _ from 'lodash';

@Component({
  selector: 'funz-filters',
  templateUrl: 'funz-filters.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FunzFiltersComponent {
  filters: any = [];
  locals: string;
  siteheaders = this.appApi.siteheaders$;

  @Input() filtersData;
  @Input('category')
  set onCategoryView(slug) {
    if (slug && slug.length) {
      this.filtersData[FilterType.CATERGORY] = {
        ...this.filtersData[FilterType.CATERGORY],
        payload: { slug },
        isActive: true,
        selected: 1
      };
      this.onFilterSelect.emit(this.filtersData[FilterType.CATERGORY]);
    }
  }
  @Input() date;
  @Input() location;
  @Input() interests;

  @Output() onFilterSelect = new EventEmitter<any>();

  constructor(private modalCtrl: ModalController, public appApi: AppApi) {
    this.siteheaders.subscribe(site => (this.locals = site.locales));
  }

  get filtersList() {
    return _.toPairs({ ...this.filtersData }).map(([key, value]) => value);
  }

  async openFilterView(filter) {
    const modalData = {};

    modalData[filter.type] = this[filter.type];
    modalData['selected_' + filter.type] = filter;

    const profileModal = await this.modalCtrl.create({
      component: filter.component,
      componentProps: {
        ...modalData
      },
      cssClass: `${this.locals}-theme`
    });
    await profileModal.present();
    profileModal.onDidDismiss().then(data => {
      if (data.data) { this.onFilterSelect.emit(data.data); }
    });
  }
}
