import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { Store, Action, select } from '@ngrx/store';
import * as fromApp from '../../core/store/app.reducers';
import { Observable } from 'rxjs';
import { OpenSignIn } from '../../shared-stores/auth/store';
import { Actions, ofType } from '@ngrx/effects';
import * as fromAuth from '../../shared-stores/auth/store/auth.actions';
import { keys } from 'lodash';
import { take, tap } from 'rxjs/operators';
import { ResetAction, SetValueAction } from 'ngrx-forms';
import { LOGIN_FORM_ID, LOGIN_FORM_INITIAL_STATE_VALUE } from '../../shared-stores/auth/store/login/login.reducers';
import { PASSWORD_FORM_ID, PASSWORD_INITIAL_STATE_VALUE } from '../../shared-stores/auth/store/password/password.reducers';

@Component({
  selector: 'forgot-password',
  templateUrl: 'forgot-password.html',
  styleUrls: ['forgot-password.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @Output() resetPassword = new EventEmitter();

  formState: Observable<any>;
  objectKeys = keys;
  isSent = false;
  disabled = true;

  constructor(
    private store: Store<fromApp.IAppState>,
    private actions$: Actions,
  ) {
    this.formState = this.store.select('auth').pipe(select('passwordForm'));
  }

  ngOnInit(): void {
    this.resetForm();
  }

  onFocus() {
    this.disabled = false;
  }

  goToRecover() {
    this.isSent = false;
  }

  goToLogin() {
    this.store.dispatch(new OpenSignIn());
  }

  resetForm() {
    this.store.dispatch(new SetValueAction(PASSWORD_FORM_ID, PASSWORD_INITIAL_STATE_VALUE));
    this.store.dispatch(new ResetAction(PASSWORD_FORM_ID));
  }

  onSubmit() {
    this.actions$.pipe(
      ofType<Action>(fromAuth.PASSWORD_RESET_SUCCESS, fromAuth.PASSWORD_RESET_ERROR),
      take(1),
      tap((action) => {
        if (action.type === fromAuth.PASSWORD_RESET_SUCCESS) {
          this.isSent = true;
        }
      })
    ).subscribe();
    this.resetPassword.emit();
  }
}
