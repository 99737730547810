import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'share-modal-view',
  templateUrl: 'share-modal-view.component.html',
  styleUrls: ['share-modal-view.component.scss']
})
export class ShareModalViewComponent implements OnInit {

  shareUrl = '';
  shareUrls = [];
  shareSource = '';

  constructor (private modalCtrl: ModalController, private params: NavParams) {
  }

  ngOnInit() {
    this.shareUrls = this.params.get('urls');
    this.shareUrl = this.params.get('shareUrl');
    this.shareSource = this.params.get('shareSource');
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
