import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth/auth.component';
import { IonicModule } from '@ionic/angular';
import { AuthFormsComponent } from './auth-forms/auth-forms';
import { ForgotPasswordComponent } from './forgot-password/forgot-password';
import { LoginEmailComponent } from './login-email/login-email';
import { RegisterEmailComponent } from './register-email/register-email';
import { MarketingOptComponent } from './marketing-opt/marketing-opt';
import { FacebookErrorComponent } from './facebook-error/facebook-error';
import { NgrxFormsModule } from 'ngrx-forms';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '../directives/directives.module';
import { AuthModalComponent } from './auth-modal/auth-modal';
import { GeneralFbOnboardComponent } from '../components/general-fb-onboard/general-fb-onboard';
import { SignupComponent } from './signup/signup.component';

@NgModule({
  declarations: [
    AuthComponent,
    AuthFormsComponent,
    ForgotPasswordComponent,
    LoginEmailComponent,
    RegisterEmailComponent,
    MarketingOptComponent,
    FacebookErrorComponent,
    AuthModalComponent,
    GeneralFbOnboardComponent,
    SignupComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    DirectivesModule,
    NgrxFormsModule,
  ],
  exports: [
    AuthComponent,
    AuthFormsComponent,
    ForgotPasswordComponent,
    LoginEmailComponent,
    RegisterEmailComponent,
    MarketingOptComponent,
    FacebookErrorComponent,
    GeneralFbOnboardComponent,
    SignupComponent
  ],
  entryComponents: [
    FacebookErrorComponent,
    AuthModalComponent,
    GeneralFbOnboardComponent,
  ],
  providers: []
})
export class AuthComponentsModule {}
