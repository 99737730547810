import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { mapKeysToCamelCase } from '../../shared-stores/funz/store/funz.utils';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/app.reducers';
import * as fromCore from './../store/core';
import * as fromCities from '../../core/store/cities';
import { NO_CITY_ID, IS_ONLINE_UK, IS_ONLINE_IL } from '../../funz/city.model';
import { set } from 'lodash';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

enum FunzApi {
  SEARCH = 'search',
  FEEDBACKS = 'feedbacks',
  HOST = 'users',
  EVENTS = 'events',
  SLUG = 's'
}

export enum PersonalizedParam {
  FORYOU = 'for_you',
  BESTSELLERS = 'best_sellers',
  WHOVIEWED = 'customer_who_viewed',
  MOSTVIEWED = 'most_viewed',
}

interface PushQueryOptions {
  id: string;
  name: string;
  avatar_url?: string;
  custom_data?: {};
  user_id: string;
}
interface PusherUserData extends PushQueryOptions {
  created_at: string;
  updated_at: string;
  access_token: string;
  token_type: string;
  expires_in: number;
}

@Injectable()
export class FunzApiService {

  baseDomain: string;
  isOnline: any;

  constructor(
    private httpClient: HttpClient,
    private store: Store<IAppState>,
    private cookieService: CookieService
  ) {
    this.store
      .select(fromCore.getBaseDomain)
      .subscribe(baseDomain => {
        this.baseDomain = baseDomain;
      });
    this.store
      .select(fromCities.getIsOnline)
      .subscribe(isOnline => {
        this.isOnline = isOnline;
      });
  }

  request(params: string, api: string) {
    return this.httpClient.get<any>(
      `${this.baseDomain}/funz/${api}.json?${params}`,
      {
        observe: 'body',
        responseType: 'json'
      }
    );
  }

  requestRoot(params: string, api: string) {
    return this.httpClient.get<any>(
      `${this.baseDomain}/${api}.json?${params}`,
      {
        observe: 'body',
        responseType: 'json'
      }
    );
  }

  requestSite(params: string) {
    return this.httpClient.get<any>(
      `${this.baseDomain}/site.json?${params}`,
      { observe: 'body', responseType: 'json' }
    ).pipe(
      map(response => {
        if (response.cities && response.cities.length) {
          const cities = response.cities.filter(val => val.id !== IS_ONLINE_IL && val.id !== IS_ONLINE_UK);
          return { ...response, cities };
        }
        return response;
      })
    );
  }

  fetchCategoryRow(id: number, slug: string, cityId: number, when: string = null) {
    let requestUrl = `slug=${slug}&discovery=true&all=true&per_page=36`;
    if (cityId !== NO_CITY_ID) {
      requestUrl += `&city_id=${cityId}`;
    } else {
      if (this.isOnline === 0 || this.isOnline === 1) {
        requestUrl += `&online=${this.isOnline}`;
      }
    }
    if (when) {
      requestUrl += `&${when}=true`;
    }
    return this.request(requestUrl, FunzApi.SEARCH).pipe(
      map(response => {
        response.lp = false;
        return { id, slug, response };
      }
    ));
  }

  fetchCategoryRowWithParams(searchParams = new HttpParams({}), cityId: number, refresh?: boolean) {
    let params = searchParams.append('all', 'true').append('discovery', 'true');
    if (cityId && cityId !== NO_CITY_ID) {
      params = params.append('city_id', String(cityId));
    } else {
      if (this.isOnline === 0 || this.isOnline === 1) {
        params = params.append('online', this.isOnline);
      }
    }
    if (refresh) { params = params.append('refresh', 'true'); }
    return this.request(`${params.toString()}`, FunzApi.SEARCH).pipe(
      map(response => {
          response.lp = false;
          return response;
        }
      ));
  }

  fetchCategoryRowByPage(
    id: number,
    slug: string,
    page: number,
    cityId: number
  ) {
    return this.request(
      `slug=${slug}&page=${page}&city_id=${cityId}`,
      FunzApi.SEARCH
    ).pipe(map(response => ({
      id,
      slug,
      response
    })));
  }

  fetchFeebacks(id: number, page: number) {
    return this.httpClient
      .get<any>(
        `${this.baseDomain}/funz/${id}/${
          FunzApi.FEEDBACKS
        }.json?feedback_page=${page}&feedback_page_size=10`,
        {
          observe: 'body',
          responseType: 'json'
        }
      ).pipe(
      map(response => ({
        id,
        response
      })));
  }

  fetchCategories() {
    const url = `${this.baseDomain}/categories.json`;
    return this.httpClient.get<any>(url);
  }

  fetchLive(eventId) {
    const url = `${this.baseDomain}/live/${eventId}`;
    return this.httpClient.get<any>(url);
  }

  fetchRecommended(funzId: number) {
    const url = `${this.baseDomain}/funz/${funzId}/recommended_funz.json?amount=10`;
    return this.httpClient.get<any>(url, { observe: 'body', responseType: 'json' }).pipe(
      map(response =>  response.recommended_funz ? response.recommended_funz.map(mapKeysToCamelCase) : [])
    );
  }

  fetchPersonolized(param: PersonalizedParam, userId = null, funzId = null) {
    let params = new HttpParams();
    const sessionId = this.cookieService.get('personalized_session');
    if (sessionId) {
      params = params.append('session_id', sessionId);
    }
    if (userId) {
      params = params.append('user_id', userId);
    }
    if (funzId && param === PersonalizedParam.WHOVIEWED) {
      params = params.append('funz_id', funzId);
    }
    const url = `${this.baseDomain}/recommended/${param}`;
    return this.httpClient.get<any>(url, { params }).pipe(map(res => {
      if (res && res.recommended_funz && res.recommended_funz.length) {
        return { funzes: res.recommended_funz.map(mapKeysToCamelCase), param }
      } else {
        return null;
      }
    }));
  }

  fetchHost(id: number) {
    return this.httpClient.get<any>(`${this.baseDomain}/${FunzApi.HOST}/${id}`).pipe(
      map((response: any) => {
        if (response && response.user) {
          return {
            ...response.user,
            funzes: response.user.funzes ? response.user.funzes.map(mapKeysToCamelCase) : []
          };
        } else {
          return null;
        }
      }));
  }

  checkEventAvailability(funzId) {
    return this.httpClient.get<any>(
      `${this.baseDomain}/${FunzApi.EVENTS}/${funzId}/available`,
      { observe: 'body', responseType: 'json' }
    );
  }

  search(filterParams = {}, page: any = 1, cityId: any) {
    const requestParams: any = {
      ...filterParams,
      all: true,
      page
    };
    if (cityId && cityId !== NO_CITY_ID) {
      set(requestParams, 'city_id', cityId);
    } else {
      if (this.isOnline === 0 || this.isOnline === 1) {
        set(requestParams, 'online', this.isOnline);
      }
    }

    const paramsHelper = new HttpParams({
      fromObject: requestParams
    });
    let sanitizedParamsHelper = paramsHelper.has('id')
      ? paramsHelper.delete('id')
      : paramsHelper;
    sanitizedParamsHelper =
      sanitizedParamsHelper.has('address') ||
      sanitizedParamsHelper.has('latitude')
        ? sanitizedParamsHelper.delete('cityId')
        : sanitizedParamsHelper;
    const queryParams = sanitizedParamsHelper.toString();

    return this.request(`${queryParams}`, FunzApi.SEARCH).pipe(map(
      (response: any) => ({
        funzes: response.funzes ? response.funzes.map(mapKeysToCamelCase) : [],
        ...response
      })
    ));
  }

  searchWithParams(searchParams = new HttpParams({}), cityId: number, page: number = 1, refresh?) {
    let params: HttpParams = searchParams.append('all', 'true').append('page', String(page));
    if (cityId && cityId !== NO_CITY_ID) {
      params = params.append('city_id', String(cityId));
    } else {
      if (this.isOnline === 0 || this.isOnline === 1) {
        params = params.append('online', this.isOnline);
      }
    }
    if (refresh) { params = params.append('refresh', 'true'); }
    return this.request(`${
      params.toString()}`, FunzApi.SEARCH).pipe(map(
      ({ funzes, current_page, total_pages, canonical_url, status, error }: any) => ({
        funzes: funzes ? funzes.map(mapKeysToCamelCase) : [],
        current_page,
        total_pages,
        canonical_url,
        status,
        error
      })
    ));
  }

  fetchEvents(id: number) {
    return this.request('', `${id}/${FunzApi.EVENTS}`).pipe(
      map(({ events }: any) => ({ events, id }))
    );
  }

  fetchRelatedEvents(funzId) {
    return this.httpClient.get<any>(
      `${this.baseDomain}/funz/${funzId}/related_events`,
      {
        observe: 'body',
        responseType: 'json'
      }
    );
  }

  donate(amount, eventId, vendor) {
    return this.httpClient.post<any>(`${this.baseDomain}/donations`, {
        donation: { amount, event_id: eventId, vendor }
      }, {
      observe: 'body',
      responseType: 'json',
      withCredentials: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  authenticateChatUser(): Observable<PusherUserData> {
    const url = `${this.baseDomain}/chat_room/authenticate`;
    return this.httpClient.post<PusherUserData>(url, {}, {
      observe: 'body',
      responseType: 'json',
      withCredentials: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  chatProviderUrl = () => `${this.baseDomain}/chat_room/token_provider`;

  authenticateSendbirdChatUser(existingUid = null): Observable<PusherUserData> {
    const url = `${this.baseDomain}/sendbird_chat_room/authenticate`;
    return this.httpClient.post<PusherUserData>(url, {
      existingUid,
    }, {
      observe: 'body',
      responseType: 'json',
      withCredentials: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  fetchTickets(funzId) {
    const url = `${this.baseDomain}/my/funz/${funzId}/tickets/ticket_options`;
    return this.httpClient.get<any>(url);
  }

  fetchTicketsByEventId(funzId, eventId) {
    const url = `${this.baseDomain}/my/funz/${funzId}/tickets/ticket_options?event_id=${eventId}`;
    return this.httpClient.get<any>(url);
  }

  checkTicketsAvailability(funzId, payload, eventId) {
    const url = `${this.baseDomain}/my/funz/${funzId}/tickets/check_availability`;
    return this.httpClient.post<any>(url, { ticket_data: payload, event_id: eventId });
  }

}
