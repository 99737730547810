import { Component, Renderer2, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'rerequst-location-access',
  templateUrl: 'rerequst-location-access.html',
  styleUrls: ['rerequst-location-access.scss'],
})
export class RerequestLocationAccessComponent implements OnInit {
  public platformSpecific: string;

  constructor(public modalCtrl: ModalController, private renderer: Renderer2) {
    const platform = (window as any).cordova.platformId;
    if (platform === 'android') {
      this.platformSpecific = 'components.rerequest-location-access.subheader_android';
    } else {
      this.platformSpecific = 'components.rerequest-location-access.subheader_ios';
    }
  }

  ngOnInit() {
    const ionApp = document.getElementsByTagName('ion-app')[0];
    this.renderer.removeClass(ionApp, 'disable-scroll');
  }

  openSettings() {
    this.modalCtrl.dismiss(true);
  }

  closeModal() {
    this.modalCtrl.dismiss(false);
  }

}
