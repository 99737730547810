import { Component, Input, OnInit } from '@angular/core';
import { FunzDisplayedStars } from '../../funz/funz.model';

@Component({
  selector: 'funz-stars',
  templateUrl: 'funz-stars.html',
  styleUrls: ['funz-stars.scss']
})
export class FunzStarsComponent implements OnInit {
  @Input() stars: FunzDisplayedStars;
  @Input() withParentheses = true;
  @Input() feedbackCount: number | string;
  @Input() noReviewsSign = false;

  public fullStars: any = [];
  public emptyStars: any = [];

  ngOnInit() {
    this.fullStars = this.filledArray(this.stars.full_stars);
    this.emptyStars = this.filledArray(this.stars.empty_stars);
  }

  private filledArray(count: number) {
    return Array(count).fill(1);
  }

}
