
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { assign } from 'lodash';
import { Observable } from 'rxjs';
import { TrackingService } from 'src/app/core/services/tracking.service';
import { Funz } from 'src/app/funz/funz.model';
import * as fromApp from '../../core/store/app.reducers';
import * as fromFunz from '../../shared-stores/funz/store';
import { ImageSliderModalComponent } from '../image-slider/image-slider-modal.component';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})

export class ImageGalleryComponent implements OnInit {

  @Input() mediaArray: any[];
  @Input() selectedImageIndex = 0;
  @Input() currentFunzId;
  selectedSlideIndex = 0;
  galleryVideosUrls = {};
  funz$: Observable<Funz>;
  isAndroid = this.platform.is('android');

  constructor(
    public modalCtrl: ModalController,
    private sanitizer: DomSanitizer,
    private trackingService: TrackingService,
    private store: Store<fromApp.IAppState>,
    private platform: Platform
  ) { }

  ngOnInit() {
    if (this.mediaArray?.length) {
      this.generateVideoUrls(this.mediaArray);
    }
    this.funz$ = this.store.select(fromFunz.getFunzById(this.currentFunzId));
  }

  async onMediaClick(selectedImageIndex) {
    const props = {
      mediaArray: this.mediaArray.map(item =>
        item.kind === 'picture' && !item.animated && item.image.includes(item.height + ',')
          ? { ...item, image: item.image.replace(item.height + ',', '') }
          : item
      ),
      selectedImageIndex,
      currentFunzId: this.currentFunzId
    }
    const modal = await this.modalCtrl.create({
      component: ImageSliderModalComponent,
      componentProps: props,
      swipeToClose: true,
      animated: false,
      mode: 'ios'
    });
    await modal.present();
  }

  generateVideoUrls(gal) {
    if (gal && gal.length) {
      this.galleryVideosUrls = gal.filter(item => item.kind === 'video')
        .reduce((acc, item) => assign(acc, { [item.video_id]: this.getVideoURL(item) }), {});
    }
  }

  getVideoURL(imageGallery) {
    const url = `https://www.youtube.com/embed/${imageGallery.video_id}?autoplay=0&showinfo=0&controls=0`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  trackFunzShare() {
    this.trackingService.traceAction('share_icon_click', { source: 'funz' });
  }

  getBackgroundImage(url: string) {
    return `url(${url})`;
  }

}