import { Action } from '@ngrx/store';

export enum ActionTypes {
  GET_INTERESTS = '[Interests] GET_INTERESTS',
  GET_INTERESTS_SUCCESS = '[Interests] GET_INTERESTS_SUCCESS',
  GET_INTERESTS_FAILURE = '[Interests] GET_INTERESTS_FAILURE',
  REQUEST_INTERESTS = '[Interests] REQUEST_INTERESTS'
}

export class GetInterests implements Action {
  readonly type = ActionTypes.GET_INTERESTS;
  constructor() {}
}
export class GetInterestsSuccess implements Action {
  readonly type = ActionTypes.GET_INTERESTS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetInterestsFailure implements Action {
  readonly type = ActionTypes.GET_INTERESTS_FAILURE;
  constructor(public payload: any) {}
}
export class RequestInterests implements Action {
  readonly type = ActionTypes.REQUEST_INTERESTS;
  constructor() {}
}

export type Actions =
  | GetInterests
  | GetInterestsSuccess
  | GetInterestsFailure
  | RequestInterests;
