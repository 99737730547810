import * as fromActions from './categories.actions';
import { CategoryBox } from '../../../funz/category.model';

export const storeKey = 'categories';

export interface ICategoriesState {
  categories: CategoryBox[];
  loaded: boolean;
}

const initialState: ICategoriesState = {
  categories: [],
  loaded: false
};

export function reducer(
  state = initialState,
  action: fromActions.Actions
): ICategoriesState {
  switch (action.type) {
    case fromActions.ActionTypes.FETCH_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: action.payload,
        loaded: true
      };
    }
    case fromActions.ActionTypes.FETCH_CATEGORIES_ERROR: {
      return {
        ...state,
        categories: [],
        loaded: true
      };
    }
    default: {
      return state;
    }
  }
}
