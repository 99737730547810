import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TrackingService } from '../../core/services/tracking.service';

@Component({
  selector: 'order-subscription',
  templateUrl: 'order-subscription.html',
  styleUrls: ['order-subscription.scss']
})
export class OrderSubscriptionComponent {
  @Input() amounts: any = {};
  @Input() errorStatus: any = { displayError: false };
  @Input() currencyCode: string;
  @Output() onMoreInfoViewOpen = new EventEmitter();
  @Output() onItemSelect = new EventEmitter();

  public subscriptionSelected: boolean;

  constructor(private trackingService: TrackingService) {}

  showMoreInfo(event) {
    event.stopPropagation();
  }

  openMoreInfoView(event) {
    this.trackingService.traceAction('subscription_hint_click');
    event.stopPropagation();
    this.onMoreInfoViewOpen.emit();
  }

  onSelect(subscriptionSelected) {
    const { subscription_data: { subscription_price, plan_system_name } } = this.amounts;
    if (plan_system_name) {
      this.errorStatus.displayError = false;
    }
    this.onItemSelect.emit({
      subscription_selected: subscriptionSelected,
      subscription_price,
      plan_system_name
    });
  }

}
