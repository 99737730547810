import { Component, Input } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { PAGE_TO_URL_TRANSFORMER } from 'src/app/app-routing.utils';
import { GeneralPwaService } from 'src/app/core/services/general-pwa.service';
import { TrackingService } from 'src/app/core/services/tracking.service';
import { IAppState } from 'src/app/core/store/app.reducers';
import { APP_PAGES } from 'src/app/pages';
import { ResetDate } from 'src/app/shared-stores/search';
import { SearchProxy } from 'src/app/shared-stores/search/search.proxy';

@Component({
  selector: 'app-header',
  templateUrl: 'app-header.html',
  styleUrls: ['app-header.scss']
})
export class AppHeaderComponent {

  @Input() title = '';
  @Input() backBtn = false;
  @Input() closeBtn = false;
  @Input() homePage = false;
  cordova = !!(window as any).cordova;
  isAndroid = this.platform.is('android');

  constructor(
    private navCtrl: NavController,
    private trackingService: TrackingService,
    private generalPwaService: GeneralPwaService,
    private store: Store<IAppState>,
    private platform: Platform
  ) {}

  goBack() {
    this.navCtrl.back({ animated: false });
  }

  goToSearchPage() {
    this.generalPwaService.noSuggest.next(true);
    this.navCtrl.navigateForward(PAGE_TO_URL_TRANSFORMER.getUrlByPageName(APP_PAGES.SearchPage));
    this.trackingService.traceAction('search_click');
  }

  openDiscoverPage() {
    if (!this.homePage) {
      this.navCtrl.navigateRoot(PAGE_TO_URL_TRANSFORMER.getUrlByPageName(APP_PAGES.DiscoverPage));
      this.store.dispatch(new ResetDate());
      this.trackingService.traceAction('logo_click');
    }
  }

  openMenu() {
    this.generalPwaService.showMenu.next(true);
    // this.appFlyerService.closeBar();
    this.trackingService.traceAction('menu_click');
    this.trackingService.tracePageView('menu_pageview');
  }
}
