import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthorizationService } from '../services/authorization.service';
import { AppApi } from '../api/app.api';
import { ModalController } from '@ionic/angular';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { RequireAuthModalComponent } from '../../components/require-auth/require-auth';
import { AuthModalComponent } from '../../auth-components/auth-modal/auth-modal';
import * as CoreStore from '../store/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/app.reducers';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthorizationService,
    private appApi: AppApi,
    public modalCtrl: ModalController,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<IAppState>,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.store.select(CoreStore.getBootstrapped).pipe(
      filter((bootstraped) => !!bootstraped),
      switchMap(() => {
        return this.auth.isAuthorized().pipe(
          take(1),
          switchMap((isAuthorized) => {
            if (isAuthorized) {
              return of(true);
            } else {
              return this.handleNonAuthorized();
            }
          }),
        );
      })
    );
  }

  async handleNonAuthorized() {
    const authModal = await this.modalCtrl.create({
      component: RequireAuthModalComponent,
      cssClass: 'auth-popup'
    });
    await authModal.present();
    const authType = await authModal.onDidDismiss();
    if (authType.data) {
      const modal = await this.modalCtrl.create({
        component: AuthModalComponent,
        componentProps: { authType: authType.data }
      });
      await modal.present();
      const isAuthenticated = await modal.onDidDismiss();
      return isAuthenticated.data;
    } else if (this.route.snapshot.firstChild === null) {
      return this.router.navigateByUrl('home');
    }
  }
}
