import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { TrackingService } from '../../core/services/tracking.service';

@Component({
  selector: 'order-payment-wait',
  templateUrl: 'order-payment-wait.html',
  styleUrls: ['order-payment-wait.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPaymentWaitComponent {

  funzTitle: string;
  errorMessage: string;
  errorDescription: string;

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private trackingService: TrackingService,
  ) {
    this.funzTitle = this.navParams.get('funzTitle');
    this.trackingService.traceAction('order_payment_failure_wait_3_min');
  }

  close(param = false) {
    this.modalCtrl.dismiss(param);
  }
}
