export const ENV = {
  mode: 'XYZ',
  default_base_domain: 'https://api-uk.funzing.xyz/api/v2',
  default_root_domain: 'https://uk.funzing.xyz',
  default_root_api_domain: 'https://api-uk.funzing.xyz',
  codePush_ios_deployment_key: 'wGCpYc0vYWq9RRrtEtvVWF-BoD6jHkz760CTM',
  codePush_android_deployment_key: 'eZehEw84FBE-QTPIVqRhpNYfApHVaF0OElccW',
  hockeyapp_android_app_id: '79cdd127d9c540efb900820e1e959235',
  hockeyapp_ios_app_id: '892a096933dc43da90d6c1e7d9b85613',
  one_signal_app_id: '0da453ed-77d1-4ee3-b145-7d91fa5642fb',
  one_signal_google_project_number: '793522398911',
  cloudinary_base_url: 'https://res.cloudinary.com/dnn7h5tec/image/upload/',
  cloudinary_image_set: 'prod',
  mapbox_token:
    'pk.eyJ1IjoicGVkcm9ndXNtYW4iLCJhIjoiY2poeWx4c2c4MGpqczNxbnZ6ajBpN29zdCJ9.K2vQrB0pZbbA0QDH2_RHNA',
  seatsio_public_key: '49be9f21-ddf8-439a-8d7e-7db025d230b4',
  facebook_app_id: '1672913426179078',
  facebook_app_name: 'Funzing QA XYZ',
  google_web_application_client_id: '355852510617-gjiblrd0i9d7f9ctvniaboigv2l0dbqf.apps.googleusercontent.com',
  core_domain: 'funzing.xyz',
  cached_json_external_url: '/assets/api',
  cooladata_app_key: 'w3ytn9mn8puzqbwpjkb6wpp83b0b54cd',
  onesignal: {
    il: {
      chrome_id: '341e950b-1b65-43b2-90c0-d424d95ba191',
      safari_id: 'web.onesignal.auto.2e77cfdc-f6e8-4572-82d4-363b6713f2bc',
    },
    uk: {
      chrome_id: '1db424c5-b1e2-4899-8910-b673e33266dd',
      safari_id: 'web.onesignal.auto.062d21d9-5bd4-46cb-a28c-e44e38d1b29c',
    }
  },
  production: false
};
