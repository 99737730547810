import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ENV } from 'src/environments/environment';

declare var growPayment;

@Injectable({ providedIn: 'root' })
export class GrowPaymentService {

  growPaymentResult$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
  ) {}

  init() {
    return new Promise((resolve, reject) => {
      const configureGrowSdk = () => {
        let config = {
          environment: ENV.production ? 'PRODUCTION' : 'DEV',
          version: 1,
          events: { 
            onSuccess: (response) => {
              this.growPaymentResult$.next({ type: 'onSuccess', response });
            },
            onFailure: (response) => {
              this.growPaymentResult$.next({ type: 'onFailure', response });
            },
            onError: (response) => {
              this.growPaymentResult$.next({ type: 'onError', response });
            },
            onTimeout: (response) => {
              this.growPaymentResult$.next({ type: 'onTimeout', response });
            },
            onWalletChange: (response) => {
              this.growPaymentResult$.next({ type: 'onWalletChange', response });
            }
          }
        };   
        growPayment.init(config).then(res => {
          resolve(true);
        }).catch(() => {
          reject();
        });
      }
      const s = document.createElement('script');     
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.meshulam.co.il/sdk/gs.min.js';
      s.onload = configureGrowSdk;
      document.getElementsByTagName('head')[0].appendChild(s);
    })
  }
}
