import { ENV } from '../../../../environments/environment';
import { Store } from '@ngrx/store';
import * as fromAuth from '../store';
import { Injectable } from '@angular/core';
import { FBSignIn, FBSignInError } from '../store';
import * as LogRocket from 'logrocket';
declare let FB: any;

@Injectable()
export class FacebookProvider {

  constructor(private store: Store<fromAuth.State>) {}

  initialize() {
    return new Promise((resolve, reject) => {
      this.loadFBScript(resolve);
    });
  }

  signIn() {
    return FB.login((response: any) => {
      if (response.authResponse) {
        const authResponse = response.authResponse;
        FB.api('/me', { fields: 'id,name,picture,email' }, (fbUser: any) => {
          const accessToken = authResponse.accessToken;
          const expiresIn = authResponse.expiresIn;
          this.store.dispatch(new FBSignIn({ accessToken, expiresIn }));
        });
      } else {
        const errorText = 'Facebook login or signup error';
        LogRocket.error(errorText, JSON.stringify(response, null, 2));
        this.store.dispatch(new FBSignInError(errorText));
      }
    }, {
      scope: 'public_profile,email',
      enable_profile_selector: true,
      auth_type: 'rerequest',
      return_scopes: true
    });
  }


  share(url) {
    this.initialize().then(() => {
      FB.ui({
        method: 'share',
        href: url
      });
    });
  }

  send(url) {
    this.initialize().then(() => {
      FB.ui({
        method: 'send',
        href: url
      });
    });
  }

  /* tslint:disable */
  loadFBScript(resolve) {
    if (document.getElementById('facebook-jssdk')) {
      resolve();
    } else {
      (function(d, s, id) {
         let js, fjs = d.getElementsByTagName(s)[0];
         if (d.getElementById(id)) {return; }
         js = d.createElement(s); js.id = id;
         js.src = 'https://connect.facebook.net/en_US/sdk.js';
         fjs.parentNode.insertBefore(js, fjs);
       }(document, 'script', 'facebook-jssdk'));

      (window as any).fbAsyncInit = () => {
        FB.init({
          appId: ENV.facebook_app_id,
          autoLogAppEvents: true,
          cookie: true,
          xfbml: true,
          version: 'v19.0'
        });
        FB.AppEvents.logPageView();
        resolve();
      };
    }
  }
}
