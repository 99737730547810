import { Component, Input, OnInit } from '@angular/core';
import { AdminParticipantsStats } from '../../funz/funz.model';
import { get, isEmpty } from 'lodash';

@Component({
  selector: 'appAdminParticipantsStats',
  templateUrl: 'funz-admin-participants-stats.html',
  styleUrls: ['funz-admin-participants-stats.scss']
})
export class FunzAdminParticipantsStatsComponent implements OnInit {
  @Input() public adminParticipantsStats: AdminParticipantsStats;
  public participantsStatsAvailable = false;

  ngOnInit() {
    if (
      !isEmpty(this.adminParticipantsStats) &&
      this.adminParticipantsStats.ticketsUsed !== null &&
      this.adminParticipantsStats.maxPeople !== null
    ) {
      this.participantsStatsAvailable = true;
    }
  }
}
