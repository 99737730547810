export const NO_CATEGORY_ID = -1;

export interface ICategoryCollection { [index: number]: Category; }

export class Category {
  public id: number;
  public name?: string;
  public mobileDisplayName: string;
  public imageUrl: string;
  public imageUrlWide: string;
  public slug?: string;
  public lp?: boolean;
  public funzIds: number[];
  public loadedPages: number;
  public totalPages: number;
  public canonicalUrl?: string;
}

export interface ICategoryResponse {
  id?: number;
  name?: string;
  mobile_display_name?: string;
  image_url?: string;
  image_url_wide?: string;
  slug?: string;
  lp?: boolean;
  funzes: any[];
  funz_ids?: number[];
  loaded_pages?: number;
  total_pages?: number;
  current_page?: number;
  canonical_url?: string;
}

export interface CategoryBox {
  id: number;
  image_url: string;
  image_url_wide: string;
  slug: string;
  lp: boolean;
  mobile_display_name: string;
  related: boolean;
}
