import { APP_INITIALIZER, ErrorHandler, NgModule, Injectable } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { IonicStorageModule, StorageConfig } from '@ionic/storage';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Network } from '@ionic-native/network/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core';
import { DiagnosticProvider } from './providers/diagnostic/diagnostic';

import { Store } from '@ngrx/store';
import * as fromCore from './core/store/core';
import * as fromSearch from './shared-stores/search';
import * as fromCities from './core/store/cities';
import { IAppState } from './core/store/app.reducers';
import { filter, take } from 'rxjs/operators';

import * as Hammer from 'hammerjs';
import * as LogRocket from 'logrocket';
import * as moment from 'moment';
import 'moment/locale/he';
import { SearchStoreModule } from './shared-stores/search/search.module';
import { LocationSelectorComponent } from './components/location-selector/location-selector';
import { RerequestLocationAccessComponent } from './components/rerequst-location-access/rerequst-location-access';
import { registerLocaleData } from '@angular/common';
import localeHe from '@angular/common/locales/he';
import localeHeExtra from '@angular/common/locales/extra/he';
import { AuthComponentsModule } from './auth-components/auth-components.module';
import { RequireAuthModalComponent } from './components/require-auth/require-auth';
import { FacebookProvider } from './shared-stores/auth/providers/facebook';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { AuthStoreModule } from './shared-stores/auth/auth.store.module';
import { TextBasedModalModule } from './components/text-based-modal/text-based-modal.module';
import { ErrorTrackerService } from './core/services/error-tracker.service';
import { CookieConsentToastComponent } from './components/cookie-consent-toast/cookie-consent-toast';
import { MenuPageModule } from './pages/menu/menu.module';
import VersionJson from 'src/assets/api/version.json';

import { SocialLoginModule, GoogleLoginProvider } from 'angularx-social-login';
import { ENV } from 'src/environments/environment';

registerLocaleData(localeHe, localeHeExtra);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json?v=' + VersionJson.version);
}
const windowObj = window as any;
const storageModuleOptions: StorageConfig = { };
if (!windowObj.cordova)  {
  storageModuleOptions.name = '__funzing_app';
  storageModuleOptions.driverOrder = ['websql'];
}

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL }
  };
}

@Injectable()
export class CustomErrorhandler extends ErrorHandler {
  constructor(private errorTracker: ErrorTrackerService) {
    super();
  }

  handleError(error) {
    super.handleError(error);
    this.errorTracker.trackError(error);
  }
}

export function initApplication(store: Store<IAppState>) {
  return () =>
    new Promise(resolve => {
      store.dispatch(new fromCore.FetchSiteHeaders());
      store.dispatch(new fromSearch.InitFilter());
      store.dispatch(new fromCities.InitRecentCities());
      store
        .select(fromCore.getSiteHeaders)
        .pipe(
          filter(
            siteHeaders =>
              siteHeaders !== null &&
              siteHeaders !== undefined &&
              siteHeaders.subdomain !== null
          ),
          take(1)
        )
        .subscribe(siteData => {
          LogRocket.log('siteData', JSON.stringify(siteData, null, 2));
          store.dispatch(new fromCore.SetSiteHeaders({ ...siteData }));
          const domainName = siteData.domain;
          windowObj.dataLayer.push({ domain_name: domainName.replace('api-', '') });
          windowObj.dataLayer.push({ currency_code: siteData.currency_code });
          moment.locale(siteData.locales);
          resolve(true);
        });
    });
}

@NgModule({
  declarations: [
    AppComponent,
    LocationSelectorComponent,
    RerequestLocationAccessComponent,
    RequireAuthModalComponent,
    CookieConsentToastComponent,
  ],
  entryComponents: [
    LocationSelectorComponent,
    RerequestLocationAccessComponent,
    RequireAuthModalComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    VirtualScrollerModule,
    MenuPageModule,
    SocialLoginModule,
    IonicModule.forRoot({
      scrollPadding: false
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AuthComponentsModule,
    SearchStoreModule,
    AuthStoreModule,
    TextBasedModalModule,
    HammerModule
  ],
  providers: [
    StatusBar,
    ScreenOrientation,
    SplashScreen,
    AppVersion,
    Device,
    Facebook,
    Geolocation,
    SocialSharing,
    InAppBrowser,
    OneSignal,
    FirebaseX,
    Network,
    Diagnostic,
    DiagnosticProvider,
    LaunchNavigator,
    GooglePlus,
    CallNumber,
    Keyboard,
    CookieService,
    Clipboard,
    SignInWithApple,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
    { provide: ErrorHandler, useClass: CustomErrorhandler },
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      multi: true,
      deps: [Store]
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(ENV.google_web_application_client_id)
          }
        ]
      }
    },
    FacebookProvider,
    AppRate,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
