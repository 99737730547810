import { Funz } from '../../funz/funz.model';
import { get, has } from 'lodash';

export class FunzHelperService {
  funz: Funz;
  constructor(funz: any) {
    this.funz = funz;
  }

  trackingEventStatus() {
    let status = null;
    if (
      !has(this.funz, 'showTicketsLeft') ||
      !has(this.funz, 'allEventsSoldOut') ||
      !has(this.funz, 'numberOfUpcomingDates') ||
      !has(this.funz, 'datesWithTicketsLeft')
    ) {
      return status;
    }
    if (this.funz.showTicketsLeft && !this.funz.allEventsSoldOut && this.funz.numberOfUpcomingDates > 0) {
      status = 'limited tickets left';
    } else if (this.funz.allEventsSoldOut) {
      status = 'sold out';
    } else if (!this.funz.allEventsSoldOut && this.funz.datesWithTicketsLeft > 0 && this.funz.numberOfUpcomingDates === 1) {
      status = 'one event';
    } else if (!this.funz.allEventsSoldOut && this.funz.datesWithTicketsLeft > 0 && this.funz.numberOfUpcomingDates > 1) {
      status = 'multiple events';
    } else if (this.funz.numberOfUpcomingDates === 0) {
      status = 'no events';
    }
    return status;
  }
}
