import { Component } from '@angular/core';
import * as fromProfile from '../../core/store/profile';
import * as fromApp from '../../core/store/app.reducers';
import { Store } from '@ngrx/store';
import { NavController, Platform } from '@ionic/angular';
import { PAGE_TO_URL_TRANSFORMER } from '../../app-routing.utils';
import { APP_PAGES } from '../../pages';

@Component({
  selector: 'share-with-friends',
  templateUrl: 'share-with-friends.html'
})
export class ShareWithFriendsComponent {
  referralCreditsAmount$ = this.store.select(
    fromProfile.getReferralInviteReferralCreditsAmount
  );

  constructor(
    private store: Store<fromApp.IAppState>,
    private navCtrl: NavController,
    public platform: Platform,
  ) {
    this.store.dispatch(new fromProfile.GetProfileInvites(''));
  }

  navigateToSharePage() {
   this.navCtrl.navigateRoot(PAGE_TO_URL_TRANSFORMER.getUrlByPageName(APP_PAGES.SharePage));
  }
}
