
import {
  concatMap,
  tap,
  switchMap,
  withLatestFrom,
  catchError, delayWhen,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromLogin from './login.actions';
import { HttpClient } from '@angular/common/http';
import * as fromApp from '../../../../core/store/app.reducers';
import * as fromCore from './../../../../core/store/core';
import { Store } from '@ngrx/store';
import * as fromAuth from '../auth.actions';
import { SetUserDefinedPropertyAction } from 'ngrx-forms';
import { LoadingController } from '@ionic/angular';
import { from } from 'rxjs';
import { TrackingService, ADAPTIVE_CUSTOMER_LOGIN } from '../../../../core/services/tracking.service';
import { get } from 'lodash';

@Injectable()
export class LoginEffects {
  loader: any;
  cordova = !!(window as any).cordova;

  @Effect()
  submitLoginForm = this.actions$
    .pipe(
      ofType(fromLogin.SUBMIT_LOGIN_FORM),
      tap(() => this.appStore$.dispatch(new fromAuth.DisableSubmit())),
      withLatestFrom(
        this.appStore$.select(state => state.auth.loginForm.value),
        this.appStore$.select(fromCore.getBaseDomain),
        this.appStore$.select(fromCore.getLastSelectedCityId)
      ),
      delayWhen(() => from(this.loadingCtrl.create().then((loader) => this.loader = loader))),
      switchMap( ([action, state, baseDomain, cityId]) => {
        this.loader.present();
        const payloadParams = {
          ...state,
          city_id: cityId
        };
        return this.httpClient
          .post(`${baseDomain}/auth/sign_in`, payloadParams, {
            observe: 'body',
            responseType: 'json'
          }).pipe(
          concatMap((response: any) => {
            this.loader.dismiss();
            this.trackingService.sendUtms(true).then(() => {
              setTimeout(() => {
                this.trackingService.traceAction('email_login_success', {
                  type: 'Login',
                  email: get(response, 'user_data.email')
                });
                if (this.cordova) {
                  this.trackingService.trackAppsFlyer('af_login', {
                    af_customer_user_id: get(response, 'user_data.user_id'),
                    af_registration_method: 'email'
                  });
                }
                this.trackingService.trackGAEvent(ADAPTIVE_CUSTOMER_LOGIN);
              }, 1000)
            });
            this.appStore$.dispatch(new fromAuth.Signin(response));
            return [new fromAuth.Signin(response), new fromAuth.EnableSubmit()];
          }),
            catchError(error => {
              this.loader.dismiss();
              this.trackingService.traceAction('email_login_failure', {
                type: 'Login',
                email: get(payloadParams, 'email')
              });
              this.appStore$.dispatch(new fromAuth.EnableSubmit());
              if (error.error.errors) {
                this.appStore$.dispatch(
                  new SetUserDefinedPropertyAction(
                    'login-form',
                    'serverError',
                    error.error.errors[0]
                  )
                );
              } else {
                this.appStore$.dispatch(
                  new SetUserDefinedPropertyAction(
                    'login-form',
                    'serverError',
                    error.message
                  )
                );
                // should throw error! Need to deal with error handling
              }
              return null;
            })
          );
      })
    );

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private appStore$: Store<fromApp.IAppState>,
    private loadingCtrl: LoadingController,
    private trackingService: TrackingService,
  ) {
    this.loader = null;
  }
}
