import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICitiesState, storeKey } from './cities.reducers';
import { City, NO_CITY_ID, IS_ONLINE_UK, IS_ONLINE_IL } from '../../../funz/city.model';
import { getCityOnBoarding, getSiteHeadersLocationSuffix } from '../core';

export const selectCities = createFeatureSelector<ICitiesState>(storeKey);

export const getAllCities = createSelector(
  selectCities,
  getCityOnBoarding,
  (cities: ICitiesState, cityOnBoarding) => cityOnBoarding ? cities.cities : [cities.allCity, ...cities.cities]
);

export const getCities = createSelector(
  selectCities,
  (cities: ICitiesState) => cities.cities
);

export const getSelectedCityId = createSelector(
  selectCities,
  (cities: ICitiesState) => cities.selectedCityId
);

export const getSelectedCity = createSelector(
  getAllCities,
  getSelectedCityId,
  getSiteHeadersLocationSuffix,
  (cities: City[], cityId: number, siteheaders: string) => {
    if (cityId && cityId === NO_CITY_ID) { return { id: NO_CITY_ID } as City; }
    if (cityId && cityId === IS_ONLINE_UK ) { return { id: IS_ONLINE_UK } as City; }
    if (cityId && cityId === IS_ONLINE_IL ) { return { id: IS_ONLINE_IL } as City; }
    const selectedCity = cities.find((city: City) => city.id === cityId);
    if (selectedCity) { return selectedCity; }
    const defaultCityId = siteheaders === 'uk' ? 1 : 4;
    return cities.filter(city => city.id === defaultCityId)[0];
  }
);

export const getRecentCities = createSelector(
  selectCities,
  (state) => state.recentCities
);

export const getIsOnline = createSelector(
  selectCities,
  (state) => state.isOnline
);

export const getRecentCitiesWithoutSelected = createSelector(
  getRecentCities,
  getSelectedCityId,
  (recentCities, cityId) =>
    (recentCities && recentCities.length && recentCities[0].id === cityId) ? recentCities.slice(1) : recentCities
);
