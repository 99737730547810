import { AuthorizationService } from './authorization.service';
import { ResponseHandlerService } from './response-handler.service';
import { FunzApiService } from './funz-api.service';
import { FunzMyApiService } from './funz-my-api.service';
import { FunzOrderService } from './funz-order.service';
import { ImageHandlingService } from './image-handling.service';
import { SeoService } from './seo.service';
import { TrackingService } from './tracking.service';
import { MenuService } from './menu.service';
import { GeneralPwaService } from './general-pwa.service';
import { GeneralHelperService } from './general-helper.service';
import { SlugService } from './slug.service';
import { DocumentService } from './document.service';
import { WindowResizeService } from './window-resize.service';
import { ParamsService } from './params.service';
import { AndroidBackBtnService } from './android-back-btn.service';
import { SplashScreenService } from './splash-screen.service';
import { OpenUrlService } from './open-url.service';
import { StatusBarService } from './status-bar.service';
import { ErrorTrackerService } from './error-tracker.service';
import { RoutingStateService } from './routing-state.service';
import { PwaApiService } from './pwa-api.service';

export const APP_SERVICES = [
  AuthorizationService,
  ResponseHandlerService,
  FunzApiService,
  FunzMyApiService,
  FunzOrderService,
  ImageHandlingService,
  SeoService,
  TrackingService,
  MenuService,
  GeneralHelperService,
  SlugService,
  DocumentService,
  WindowResizeService,
  ParamsService,
  AndroidBackBtnService,
  SplashScreenService,
  OpenUrlService,
  StatusBarService,
  ErrorTrackerService,
  RoutingStateService,
  GeneralPwaService,
  PwaApiService,
];
