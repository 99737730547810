import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizeDate',
  pure: false
})
export class LocalizeDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, pattern: string = 'mediumDate'): any {
    let timeZoneOffset;
    let timeValue = value;
    if (value.includes('Z')) {
      timeValue = new Date(value);
      timeZoneOffset = timeValue.getTimezoneOffset();
    } else if (value.includes('+')) {
      timeZoneOffset = parseInt(value.split('+').pop(), 10);
    }
    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
    return datePipe.transform(timeValue, pattern, `UTC+${timeZoneOffset}`);
  }
}
