import { Component, Input, EventEmitter, Output } from '@angular/core';
import * as fromFunz from '../../shared-stores/funz/store';
import * as fromApp from '../../core/store/app.reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'events-scroll',
  templateUrl: 'events-scroll.html'
})
export class EventsScrollComponent {
  @Input() events: Event[];
  @Input() funz: any;
  @Input() totalLeftEvents: number;
  @Output() onMoreDatesClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSoldOut: EventEmitter<void> = new EventEmitter<void>();
  @Output() onOrderEventNowClick = new EventEmitter<any>();

  constructor(private store: Store<fromApp.IAppState>) {}

  trackByFn(index, item) {
    return index; // or item.id
  }
}
