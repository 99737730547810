
import { throwError as observableThrowError,  Observable } from 'rxjs';
import {
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse, HttpRequest, HttpHandler
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../store/app.reducers';
import { AuthHeaders } from '../models/authHeaders.model';
import * as _ from 'lodash';
import { ENV } from '../../../environments/environment';
import { ResponseHandlerService } from '../services/response-handler.service';
import * as fromCore from '../store/core';
import { SiteHeaders } from '../models/siteHeaders.model';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private site$ = this.store.select(fromCore.getSiteHeaders);
  private authHeaders: AuthHeaders;
  private siteHeaders: SiteHeaders;

  constructor(
    private store: Store<fromApp.IAppState>,
    private responseHandlerService: ResponseHandlerService
  ) {
    this.store
      .select(fromCore.getAuthHeaders)
      .subscribe(
        (authHeaders: AuthHeaders) => (this.authHeaders = authHeaders)
      );
    this.site$.subscribe(site => (this.siteHeaders = site));
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      this.authHeaders.accessToken &&
      request.url.includes(this.siteHeaders.domain)
    ) {
      const headers = _.mapKeys(this.authHeaders, (value, key) => {
        return _.snakeCase(key);
      });
      request = request.clone({
        setHeaders: headers,
        withCredentials: true
      });
    } else if (request && request.url && request.url.includes('site.json')) {
      request = request.clone({
        withCredentials: true
      });
    }

    return next
      .handle(request)
      .pipe(
        tap((event: any) => {
          if (event instanceof HttpResponse) {
            const { headers, body } = event;
            this.responseHandlerService.handleHeaders(headers);
            this.responseHandlerService.handleProfileUpdate(body);
          }
        }),
        catchError((response) => {
          if (response instanceof HttpErrorResponse) {
            this.responseHandlerService.handleError(response, request.url);
          }
          return observableThrowError(response);
        })
      );
  }
}
