import { TrackingService } from '../../core/services/tracking.service';
import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { IOrderItem } from '../../core/store/activities';
import { NavController } from '@ionic/angular';
import { APP_PAGES } from '../../pages';
import { PAGE_TO_URL_TRANSFORMER } from 'src/app/app-routing.utils';

@Component({
  selector: 'order-owner',
  template: `
  <ion-item lines="none" class="order-owner">
    <ion-avatar item-start (click)="goToHost(order.owner.id)">
      <img [src]="order.owner.avatar">
    </ion-avatar>
    <div class="host-name" (click)="goToHost(order.owner.id)">{{ ownerName }}</div>
    <div class="owner-actions" slot="end">
      <button appContactMe
        (click)="track('chat')"
        [contactId]="order.owner.id"
        [contactParams]="params">
        <i class="n2-ico-mask n2-ico-mask__mail"></i>
      </button>
      <button (click)="onCallNumber()">
        <i class="n2-ico-mask n2-ico-mask__phone"></i>
      </button>
    </div>
  </ion-item>
  `,
  styleUrls: ['order-owner.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderOwnerComponent implements OnInit {
  @Input() order: IOrderItem;
  @Output() callNumber = new EventEmitter<string>();

  constructor(
    private navCtrl: NavController,
    private trackingService: TrackingService,
  ) {}

  ngOnInit() {}

  get ownerName() {
    const { owner } = this.order;
    return owner.full_name || owner.name;
  }

  get params() {
    return {
      order_id: this.order.id,
      conversation_id: this.order.owner.conversation_id,
      name: this.ownerName
    };
  }

  goToHost(id: number) {
   this.navCtrl.navigateRoot(PAGE_TO_URL_TRANSFORMER.getUrlByPageName(APP_PAGES.HostPage, { id }));
  }

  onCallNumber() {
    this.track('phone');
    const { owner: { phone } } = this.order;
    this.callNumber.emit(phone);
  }

  track(type) {
    this.trackingService.traceAction('contact_host_click', { type });
  }
}
