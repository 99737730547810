
import { take, withLatestFrom, switchMap, map, catchError, tap } from 'rxjs/operators';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { AlertController, LoadingController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Observable, of, EMPTY as empty } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';
import * as fromActivities from '../activities';
import { FunzMyApiService } from '../../../core/services/funz-my-api.service';
import { TrackingService } from '../../services/tracking.service';
import { ICommentEvent } from '../../../components/order/order';
import * as fromModels from '../../models/activities.models';
import * as fromCore from '../core';
import { TranslateService } from '@ngx-translate/core';
import { IAppState } from '../app.reducers';
import * as _ from 'lodash';
import { ErrorTrackerService } from '../../services/error-tracker.service';

@Injectable()
export class ActivitiesEffects {

  windowObj = window as any;

  @Effect()
  fetchNotifications$: Observable<Action> = this.actions$
    .pipe(
      ofType(fromActivities.ActionTypes.FETCH_NOTIFICATIONS),
      switchMap(({ payload: page }: any) =>
        this.funzMyApi.fetchNotifications(page)
      ),
      map((response: fromModels.INotificationsResponse) => new fromActivities.SetNotifications(response))
    );

  @Effect()
  fetchMoreNotifications$: Observable<Action> = this.actions$
    .pipe(
      ofType(fromActivities.ActionTypes.FETCH_MORE_NOTIFICATIONS),
      switchMap(({ payload: page }: any) =>
        this.funzMyApi.fetchNotifications(page)
      ),
      map((response: fromModels.INotificationsResponse) => new fromActivities.AddNotifications(response))
    );

  @Effect()
  markAsReadNotifications: Observable<Action> = this.actions$
    .pipe(
      ofType(fromActivities.ActionTypes.UPDATE_READ_NOTIFICATION),
      switchMap(({ payload: { id } }: any) => this.funzMyApi.markAsRead(id)),
      map((response: any) => new fromActivities.UpdateReadNotificationSuccess(response))
    );

  @Effect()
  fetchOrders$: Observable<Action> = this.actions$.pipe(
    ofType(fromActivities.ActionTypes.FETCH_ORDERS),
    switchMap(() => this.funzMyApi.fetchOrders()),
    map(response => {
      return new fromActivities.FetchOrdersSuccess(response)
    })
  );

  @Effect()
  fetchOrder$: Observable<any> = this.actions$.pipe(
    ofType(fromActivities.ActionTypes.FETCH_ORDER),
    map((action: any) => action.payload),
    switchMap((id: string | number) => {
      return this.funzMyApi.fetchOrder(id).pipe(
        map(response => new fromActivities.FetchOrderSuccess(response)),
        catchError(error => this.handleError(error))
    )})
  );

  @Effect({ dispatch: false })
  fetchOrderSuccess$: Observable<any> = this.actions$.pipe(
    ofType(fromActivities.ActionTypes.FETCH_ORDER_SUCCESS),
    withLatestFrom(this.store.select(fromActivities.getActivityParams)),
    switchMap(([action, activityParams]) => {
      const order = _.get(action, 'payload');
      const orderId = _.get(action, 'payload.id');
      return [];
    })
  );

  @Effect()
  saveOrderComment$: Observable<Action> = this.actions$
    .pipe(
      ofType(fromActivities.ActionTypes.SAVE_ORDER_COMMENT),
      map((action: fromActivities.SaveOrderComment) => action.payload),
      tap(() => this.showLoading()),
      switchMap((comment: ICommentEvent) =>
        this.funzMyApi.saveOrderComment(comment).pipe(
          map(response => new fromActivities.SaveOrderCommentSuccess(response)),
          catchError((e) => of (new fromActivities.SaveOrderCommentError(e))
          )
        )
      ),
      tap(() => this.dismissLoading()),
    );

  @Effect()
  cancelOrder$: Observable<Action> = this.actions$
    .pipe(
      ofType(fromActivities.ActionTypes.CANCEL_ORDER),
      map((action: fromActivities.CancelOrder) => action.payload),
      switchMap((id: number) => this.funzMyApi.cancelOrder(id)),
      map(() => {
        this.showOrderCancelSuccess();
        return new fromActivities.CancelOrderSuccess('cancelled');
      })
    );

  // CONVERSATIONS

  @Effect()
  fetchConversations$: Observable<Action> = this.actions$
    .pipe(
      ofType(fromActivities.ActionTypes.FETCH_CONVERSATIONS),
      switchMap(({ payload: page }: any) =>
        this.funzMyApi.fetchConversations(page)
      ),
      map((response: fromModels.IConversationsResponse) => new fromActivities.SetConversations(response))
    );

  @Effect()
  fetchMoreConversations$: Observable<Action> = this.actions$
    .pipe(
      ofType(fromActivities.ActionTypes.FETCH_MORE_CONVERSATIONS),
      switchMap(({ payload: page }: any) =>
        this.funzMyApi.fetchConversations(page)
      ),
      map((response: fromModels.IConversationsResponse) => new fromActivities.AddConversations(response))
    );

  @Effect()
  fetchMessages$ = this.actions$
    .pipe(
      ofType(fromActivities.ActionTypes.FETCH_MESSAGES),
      switchMap(({ payload: { id, page } }: any) =>
        this.funzMyApi
          .fetchMessages(id, page)
          .pipe(
            map((response: any) => new fromActivities.FetchMessagesSuccess(response)),
            catchError((error: any) => of(new fromCore.HandleError(error))))
      )
    );

  @Effect()
  replyMessage$ = this.actions$
    .pipe(
      ofType(fromActivities.ActionTypes.REPLY_MESSAGE),
      map((action: any) => action.payload),
      switchMap((message: fromModels.IMessageReply) =>
        this.funzMyApi
          .replyMessage(message).pipe(
          map((response: { message: fromModels.IMessageReplyResponse, host_id?: number }) => {
            return new fromActivities.ReplyMessageSuccess(response.message);
          }),
          catchError(msg => {
            this.errorTracker.trackError(msg);
            return of(
              new fromActivities.ReplyMessageFailure(msg)
            );
          }), )
      )
    );

  @Effect({ dispatch: false })
  callNumber$ = this.actions$
    .pipe(
      ofType(fromActivities.ActionTypes.CALL_PHONE_NUMBER),
      map((action: fromActivities.CallPhoneNumber) => action.payload),
      tap((phoneNumber: string) => {
        if (this.windowObj.cordova) {
          this.callNumber
            .callNumber(phoneNumber, true)
            .catch(error => new fromCore.HandleError(error));
        } else {
          this.windowObj.open('tel:' + phoneNumber);
        }
      })
      // map(() => new fromActivities.CallPhoneNumberSuccess()),
      // catchError(error => of(new fromCore.HandleError(error)))
    );

  private loader;

  constructor(
    private actions$: Actions,
    private funzMyApi: FunzMyApiService,
    private alertCtrl: AlertController,
    private translateService: TranslateService,
    private callNumber: CallNumber,
    private loadingCtrl: LoadingController,
    private trackingService: TrackingService,
    private store: Store<IAppState>,
    private errorTracker: ErrorTrackerService,
  ) {}

  showOrderCancelSuccess() {
    this.getOrderCancelSuccessTexts().subscribe(async texts => {
      const alert = await this.alertCtrl.create({
          header: texts['store.order.cancel_success.title'],
          message: texts['store.order.cancel_success.message'],
          buttons: [texts['store.order.cancel_success.button']]
        });
      await alert.present();
    });
  }

  getOrderCancelSuccessTexts() {
    return this.translateService
      .get([
        'store.order.cancel_success.title',
        'store.order.cancel_success.message',
        'store.order.cancel_success.button'
      ]).pipe(
      take(1));
  }

  async showLoading() {
    if (this.loader) {
      return;
    }
    this.loader = await this.loadingCtrl.create();
    await this.loader.present();
  }

  dismissLoading() {
    this.loader.dismiss();
    this.loader = null;
  }

  handleError(error) {
    return of(new fromCore.HandleError(error));
  }
}
