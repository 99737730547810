import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'order-event-availability',
  templateUrl: 'order-event-availability.html',
  styleUrls: ['order-event-availability.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderEventAvailabilityComponent {

  constructor(private modalCtrl: ModalController) {
  }

  dismiss(param = false) {
    this.modalCtrl.dismiss(param);
  }

}
