import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as fromModels from '../models/activities.models';
import { Observable } from 'rxjs';
import { mapKeysToCamelCase } from '../../shared-stores/funz/store/funz.utils';
import { ICommentEvent } from '../../components/order/order';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/app.reducers';
import * as fromCore from './../store/core';
import { ISubscription } from '../store/activities';
import { TrackingService } from './tracking.service';
import { set } from 'lodash';
import { IPushNotificationSetting } from '../models/activities.models';

export interface INotification {
  id: number;
  created_at: Date;
  alert: string;
  unread: boolean;
  extra_data: {
    action_type: 'event' | string;
    action_id: number;
  };
}

export interface INotificationsResponse {
  notifications: fromModels.INotification[];
  canonical_url: string;
  page_title: string;
  total_pages: number;
}

export interface IConversationsResponse {
  conversations: fromModels.IConversation[];
  total_pages: number;
  canonical_url: string;
  page_title: string;
}

export interface FbOnboard {
  signedIn: boolean;
  userId: string;
  name: string;
  email: string;
  notSubscribedToMarketingEmails: boolean;
  needsToFbOnboard: boolean;
}

enum FunzMyApi {
  NOTIFICATIONS = 'notifications',
  NOTIFICATIONS_SETTINGS = 'activity',
  MARK_AS_READ = 'notifications',
  CONVERSATIONS = 'conversations',
  CONVERSATION = 'conversations/:id',
  MESSAGES = 'messages',
  BOOKMARKS = 'bookmarks',
  CREDITS = 'credits',
  ORDERS = 'orders',
  FEEDBACKS = 'feedbacks',
  UPDATE_PHONE = 'profiles/update_phone',
  UPDATE_SUBSCRIBED_TO_MARKETING_EMAILS = 'profiles/update_subscribed_to_marketing_emails',
  UPDATE_FB_ONBOARD = 'profiles/facebook_onboard',
  FOLLOW = 'relationships',
  UPDATE_DEVICE = 'profiles/device_info',
  DATE_REQUEST = 'funz/:id/date_requests',
  SUBSCRIPTION = 'subscription',
  SUBSCRIPTION_CANCEL = 'subscription/:id/cancel',
  SEND_MESSAGE = 'funz/:id/send_message',
}

@Injectable()
export class FunzMyApiService {
  baseDomain: string;

  constructor(
    private httpClient: HttpClient,
    private store: Store<IAppState>,
    private trackingService: TrackingService,
  ) {
    this.store
      .select(fromCore.getBaseDomain)
      .subscribe(baseDomain => {
        this.baseDomain = baseDomain;
      });
  }

  request(params = '', api: string) {
    return this.httpClient.get<any>(
      `${this.baseDomain}/my/${api}.json?${params}`,
      {
        observe: 'body',
        responseType: 'json'
      }
    );
  }

  post(api: string, body) {
    return this.httpClient.post<any>(`${this.baseDomain}/my/${api}`, body, {
      observe: 'body',
      responseType: 'json'
    });
  }

  patch(api: string, body) {
    return this.httpClient.patch<any>(`${this.baseDomain}/my/${api}`, body, {
      observe: 'body',
      responseType: 'json'
    });
  }

  delete(api: string) {
    return this.httpClient.delete<any>(`${this.baseDomain}/my/${api}`, {
      observe: 'body',
      responseType: 'json'
    });
  }

  updateFeedback(api: string, body) {
    return this.httpClient.patch<any>(
      `${this.baseDomain}/my/${api}/${body.order_id}`,
      {
        feedback: body.feedback
      }
    );
  }

  // TODO: add endpoint for profile
  UpdateProfile(id: string, body: object, api: string): Observable<any> {
    // user_id was needed until now, but not for the api call
    // set(body, 'user_id', null);
    // remove entries with null values
    body = this.trackingService.cleanObject(body);
    return this.httpClient.put<any>(
      `${this.baseDomain}/my/${api}/${id}`,
      { user: { ...body } },
      {
        observe: 'body',
        responseType: 'json'
      }
    );
  }

  fetchNotifications(page = 1) {
    return this.request(`page=${page}&all=true`, FunzMyApi.NOTIFICATIONS).pipe(map(
      ({ notifications, total_pages, canonical_url, page_title }: INotificationsResponse) => ({
        notifications,
        total_pages,
        canonical_url,
        page_title
      })
    ));
  }

  markAsRead(funzId: number) {
    return this.httpClient
      .put(
        `${this.baseDomain}/my/${FunzMyApi.MARK_AS_READ}/${funzId}/mark_as_read?all=true`,
        {
          observe: 'body',
          responseType: 'json'
        }
      ).pipe(
      map(({ id, unread }: INotification) => ({ id, unread })));
  }

  markBookmark(funzId: number) {
    return this.post(FunzMyApi.BOOKMARKS, {
      bookmark: { funz_id: funzId }
    });
  }

  deleteBookmark(funzId: number) {
    return this.delete(`${FunzMyApi.BOOKMARKS}/${funzId}.json`);
  }

  fetchBookmarks() {
    return this.request('', FunzMyApi.BOOKMARKS).pipe(map(({ bookmarks }: any) =>
      bookmarks.map(mapKeysToCamelCase)
    ));
  }

  fetchOrders() {
    return this.request('', FunzMyApi.ORDERS).pipe(
      map(({ orders, canonical_url, page_title }: any) => ({ orders, canonical_url, page_title }))
    );
  }

  fetchOrder(orderId: string | number) {
    return this.request('', `${FunzMyApi.ORDERS}/${orderId}`);
  }

  saveOrderComment(comment: ICommentEvent) {
    if (comment.first_feedback) {
      return this.post(FunzMyApi.FEEDBACKS, comment);
    } else {
      return this.updateFeedback(FunzMyApi.FEEDBACKS, comment);
    }
  }

  updateDevice(body) {
    return this.patch(FunzMyApi.UPDATE_DEVICE, { device: { ...body } });
  }

  updateUserPhone(phone: string) {
    return this.patch(FunzMyApi.UPDATE_PHONE, { phone });
  }

  updateUserSubscribedToMarketingEmails(
    subscribedToMarketingEmails: boolean
  ) {
    return this.patch(FunzMyApi.UPDATE_SUBSCRIBED_TO_MARKETING_EMAILS, {
      subscribedToMarketingEmails
    });
  }

  updateUserFacebookOnboard(payload: any) {
    return this.patch(FunzMyApi.UPDATE_FB_ONBOARD, payload);
  }

  cancelOrder(id: number) {
    return this.post(`${FunzMyApi.ORDERS}/${id}/cancel`, {});
  }

  checkOrderStatus(id: number) {
    return this.request('', `${FunzMyApi.ORDERS}/${id}/check_status`);
  }

  fetchConversations(page = 1) {
    return this.request(`page=${page}`, FunzMyApi.CONVERSATIONS).pipe(map(
      ({ conversations, total_pages, canonical_url, page_title }: IConversationsResponse) => ({
        conversations,
        total_pages,
        canonical_url,
        page_title
      })
    ));
  }

  fetchMessages(conversationId: number, page = 1) {
    const params = page > 1 ? `page=${page}` : '';
    return this.request(
      params,
      FunzMyApi.CONVERSATION.replace(':id', conversationId as any)
    );
  }

  createConversation(params) {
    return this.post(FunzMyApi.MESSAGES, params);
  }

  replyMessage(message: fromModels.IMessageReply) {
    return this.httpClient.post(
      `${this.baseDomain}/my/${FunzMyApi.MESSAGES}.json`,
      { message },
      {
        observe: 'body',
        responseType: 'json'
      }
    );
  }

  follow(followedId: number) {
    return this.post(FunzMyApi.FOLLOW, { relationship: { followed_id: followedId } });
  }

  unfollow(id: number) {
    return this.delete(`${FunzMyApi.FOLLOW}/${id}`);
  }

  dateRequest(funzId, body, isPrivate: boolean = false) {
    const API = FunzMyApi.DATE_REQUEST.replace(':id', funzId);
    const URL = `${this.baseDomain}/${API}.json`;
    body.closed_group = isPrivate;
    return this.httpClient.post<any>(URL, { body }, {
      observe: 'body',
      responseType: 'json',
      withCredentials: true,
      headers: new HttpHeaders ({
        'Content-Type': 'application/json'
      })
    });
  }

  sendMessage(funzId, body) {
    const API = FunzMyApi.SEND_MESSAGE.replace(':id', funzId);
    const URL = `${this.baseDomain}/${API}.json`;
    return this.httpClient.post<any>(URL, { message: body }, {
      observe: 'body',
      responseType: 'json',
      withCredentials: true,
      headers: new HttpHeaders ({
        'Content-Type': 'application/json'
      })
    });
  }

  fetchSubscription(): Observable<ISubscription> {
    return this.request('', FunzMyApi.SUBSCRIPTION).pipe(map((val) => val ? val.subscription : {}));
  }

  fetchNotificationsSettings(): Observable<IPushNotificationSetting[]> {
    return this.request('platform=app', FunzMyApi.NOTIFICATIONS_SETTINGS);
  }

  changeNotificationSetting(settingKey: string, settingValue: boolean) {
    return this.httpClient.put<any>(
      `${this.baseDomain}/my/${FunzMyApi.NOTIFICATIONS_SETTINGS}/${settingKey}`,
      { enabled : settingValue, platform: 'app' },
      {
        observe: 'body',
        responseType: 'json'
      }
    );
  }

  cancelSubscription(subscriptionId): Observable<ISubscription> {
    const API = FunzMyApi.SUBSCRIPTION_CANCEL.replace(':id', subscriptionId);
    const URL = `${this.baseDomain}/my/${API}.json`;
    return this.httpClient.post<any>(URL, null, {
      observe: 'body',
      responseType: 'json'
    });
  }
}
