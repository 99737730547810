import { Component, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'chat-modal',
  templateUrl: 'chat.modal.html',
})
export class ChatModalComponent {
  @Input() id;
  @Input() ownerId;
  @Input() params;

  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
  ) {
    this.ownerId = navParams.get('owner_id');
    this.params = navParams.get('params');
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
