import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/app.reducers';
import * as fromCategories from '../../core/store/categories';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class SlugService {
  constructor(private store: Store<IAppState>, private router: Router) {}

  isLandingPage(slug) {
    return new Observable(observer => {
      this.store.select(fromCategories.getCategories).subscribe(slugs => {
        if (slugs.length > 0) {
          if (slugs.filter(s => !s.lp).map(s => s.slug).includes(slug)) {
            observer.next(this.router.parseUrl(`category/${slug}`));
          } else {
            observer.next(true);
          }
        } else {
          observer.next(false);
        }
        observer.complete();
      });
    });
  }
}
