import { NgModule } from '@angular/core';
import { PluralPhrasePipe } from './plural-phrase/plural-phrase';
import { CommonModule } from '@angular/common';
import { ParseFloatPipe } from './parse-float/parse-float';
import { FilterQueryPipe } from './filter-query/filter-query';
import { AmCalendarPipe } from './parse-date/am-calendar';
import { LocalizeDatePipe } from './localize-date/localize-date';
import { KeyValuePipe } from './keys/keys';
import { ValuesPipe } from './values/values';
import { AddCommaPipe } from './add-comma/add-comma';
import { CityAreaPipe } from './city-area/city-area';
import { EventDatePipe } from './event-date/event-date';
import { FunzBoxDatePipe } from './funz-box-date/funz-box-date';
import { EventBoxDatePipe } from './event-box-date/event-box-date';
import { DaysHoursPipe } from './days-hours/days-hours';

@NgModule({
  declarations: [
    PluralPhrasePipe,
    ParseFloatPipe,
    FilterQueryPipe,
    AmCalendarPipe,
    LocalizeDatePipe,
    KeyValuePipe,
    ValuesPipe,
    AddCommaPipe,
    CityAreaPipe,
    EventDatePipe,
    FunzBoxDatePipe,
    EventBoxDatePipe,
    DaysHoursPipe
  ],
  imports: [ CommonModule ],
  exports: [
    PluralPhrasePipe,
    ParseFloatPipe,
    FilterQueryPipe,
    AmCalendarPipe,
    LocalizeDatePipe,
    KeyValuePipe,
    ValuesPipe,
    AddCommaPipe,
    CityAreaPipe,
    EventDatePipe,
    FunzBoxDatePipe,
    EventBoxDatePipe,
    DaysHoursPipe
  ]
})
export class PipesModule { }
