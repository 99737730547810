import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { IOrderDetails } from '../../core/store/activities';
import { ICommentEvent } from './order';
import { ADAPTIVE_COMMENT_FEEDBACK, FEEDBACK_LIKE, FEEDBACK_UNLIKE, TrackingService } from '../../core/services/tracking.service';
import { keys } from 'lodash';

@Component({
  selector: 'order-feedback',
  templateUrl: 'order-feedback.html',
  styleUrls: ['order-feedback.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderFeedbackComponent {
  @Input() order: IOrderDetails;
  @Input() showReply = false;
  @Input() full = false;
  @Output() comment = new EventEmitter<ICommentEvent>();

  public editMode = false;
  public commentForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private trackingService: TrackingService
    ) {
    this.commentForm = this.fb.group({
      commentText: ['', Validators.compose([this.nospaceValidator()])],
    });
  }

  commentOrder(like: boolean) {
    const data = {
      first_feedback: keys(this.order.feedback).length === 0,
      order_id: this.order.id,
      feedback: {
        like,
        comment: this.commentForm.value.commentText
      }
    };
    if (this.editMode) { this.toggleEdit(); }
    this.cd.detectChanges();
    this.trackingService.trackGAEvent(ADAPTIVE_COMMENT_FEEDBACK);
    if (like) {
      this.trackingService.trackGAEvent(FEEDBACK_LIKE);
    } else {
      this.trackingService.trackGAEvent(FEEDBACK_UNLIKE);
    }
    this.comment.emit(data);
  }

  toggleEdit() {
    this.editMode = !this.editMode;
  }

  editComment() {
    this.toggleEdit();
    if (this.editMode) {
      this.commentForm.patchValue(
        {
          commentText: this.hasComment ? this.order.feedback.comment : ''
        },
        { emitEvent: false }
      );
    }
  }

  getOwnerImage(url: string) {
    return `url(${url})`;
  }

  get feedback() {
    return this.order.feedback;
  }

  get hasFeedback() {
    return this.feedback && this.feedback.id;
  }

  get likeStatus() {
    return this.feedback.like ? 'thumbs-up' : 'thumbs-down';
  }

  get hasComment() {
    return this.hasFeedback && this.feedback.comment;
  }

  get hasReply() {
    return this.hasFeedback && this.feedback.seller_reply;
  }

  nospaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { required: 'Field is required.' };

    };
  }
}
