import { Component, Input } from '@angular/core';
import { Event } from '../../funz/event.model';

@Component({
  selector: 'event-box',
  templateUrl: 'event-box.html'
})
export class EventBoxComponent {
  @Input() event: Event;

  constructor() {}
}
