import { NgModule } from '@angular/core';
import { ENV } from '../../../environments/environment';

import { MetaReducer, StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

import { reducers } from './app.reducers';
import { APP_EFFECTS } from './app.effects';
import * as LogRocket from 'logrocket';
import createNgrxMiddleware from 'logrocket-ngrx';
import { CategoriesProxy } from './categories/categories.proxy';
import * as fromCore from './core';

const logrocketOpts = {
  actionSanitizer(action) {
    if (action.type.includes('ngrx/forms')) {
      return null;
    }
    return action;
  }
};

const logrocketMiddleware = createNgrxMiddleware(LogRocket, logrocketOpts);

export function getMetaReducers(): MetaReducer<any>[] {
  return [logrocketMiddleware];
}

@NgModule({
  imports: [
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(APP_EFFECTS),
    ENV.mode !== 'Prod'
      ? StoreDevtoolsModule.instrument({ maxAge: 300 })
      : []
  ],
  declarations: [],
  exports: [],
  providers: [
    CategoriesProxy,
    {
      provide: USER_PROVIDED_META_REDUCERS,
      useFactory: getMetaReducers,
    }
  ]
})
export class CoreStoreModule {}
