import { take } from 'rxjs/operators';
import {
  Directive,
  HostListener,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { AuthorizationService } from '../../core/services/authorization.service';
import { AppApi } from '../../core/api/app.api';
import { FunzMyApiService } from '../../core/services/funz-my-api.service';
import { ModalController } from '@ionic/angular';
import { RequireAuthModalComponent } from '../require-auth/require-auth';
import { AuthModalComponent } from 'src/app/auth-components/auth-modal/auth-modal';

@Directive({
  selector: '[appFollow]'
})
export class FollowDirective {
  @Input() appFollow: number; // id to follow
  @Input() followedStatus: boolean; // current status

  @Output() followed = new EventEmitter<boolean>();

  @HostListener('click', ['$event'])
  public handleClick(event: MouseEvent) {
    event.stopImmediatePropagation();
    event.preventDefault();
    let isAuthorized = false;
    this.auth.isAuthorized().subscribe(res => (isAuthorized = res));
    if (isAuthorized) {
      return this.handleAuthorized();
    }
    return this.handleNotAuthorized();
  }

  constructor(
    private auth: AuthorizationService,
    private appApi: AppApi,
    private funzMyApi: FunzMyApiService,
    private modalCtrl: ModalController
  ) {}

  handleAuthorized() {
    return this.followedStatus
      ? this.funzMyApi
          .unfollow(this.appFollow).pipe(
          take(1))
          .subscribe((response: any) => this.followed.emit(response.success))
      : this.funzMyApi
          .follow(this.appFollow).pipe(
          take(1))
          .subscribe((response: any) => this.followed.emit(response.success));
  }

  async handleNotAuthorized() {
    const authModal = await this.modalCtrl.create({
      component: RequireAuthModalComponent,
      cssClass: 'auth-popup'
    });
    await authModal.present();
    const authType = await authModal.onDidDismiss();
    if (authType && authType.data) {
      const modal = await this.modalCtrl.create({
        component: AuthModalComponent,
        componentProps: { authType: authType.data }
      });
      await modal.present();
      modal.onDidDismiss().then(isAuthenticated => {
        if (isAuthenticated.data) { this.handleAuthorized(); }
      });
    }
  }

}
