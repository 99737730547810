import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ISubscription } from '../../core/store/activities';

@Component({
  selector: 'order-subscription-link',
  templateUrl: 'order-subscription-link.html',
  styleUrls: ['order-subscription-link.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderSubscriptionLinkComponent {

  @Input() subscription: ISubscription;
  @Output() linkClick = new EventEmitter();

  onLinkClicked() {
    this.linkClick.emit();
  }
}
