import { Component } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { FbOnboard } from '../../core/services/funz-my-api.service';

@Component({
  selector: 'general-fb-onboard',
  templateUrl: 'general-fb-onboard.html',
  styleUrls: ['general-fb-onboard.scss']
})
export class GeneralFbOnboardComponent {
  fbOnboardData: FbOnboard;

  constructor(
    public navParams: NavParams,
    public modalCtrl: ModalController,
  ) {
    this.fbOnboardData = this.navParams.get('fbOnboardData');
  }

  closeModal(userPressedOk: boolean = false) {
    this.modalCtrl.dismiss({
      success: userPressedOk,
      notSubscribedToMarketingEmails: this.fbOnboardData.notSubscribedToMarketingEmails
    });
  }

  toggleMarketingOptIn() {
    this.fbOnboardData.notSubscribedToMarketingEmails = !this.fbOnboardData.notSubscribedToMarketingEmails;
  }
}
