import { Injectable } from '@angular/core';
import { FunzMenuComponent } from './funz-menu.component';
import { ModalController } from '@ionic/angular';

@Injectable()
export class FunzMenuModalService {

  private modal;
  constructor(private modalCtrl: ModalController) { }

  openModal() {
    if (!this.modal) {
      this.modalCtrl.create({
        component: FunzMenuComponent
      }).then((modal) => {
        modal.present();
        this.modal = modal;
        modal.onDidDismiss().then(() => this.modal = null);
      });
    }
  }

  closeModal() {
    if (this.modal) { this.modal.dismiss(); }
  }

  toggleModal() {
    if (this.modal) { this.closeModal(); } else { this.openModal(); }
  }

  isModalOpen(): boolean {
    return !!this.modal;
  }
}
