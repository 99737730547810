import {
  createFormGroupState, createFormStateReducerWithUpdate, updateGroup,
  validate
} from 'ngrx-forms';
import { email, required } from 'ngrx-forms/validation';
import { Action } from '@ngrx/store';

export interface FormValue {
  email: string;
  password: string;
}

export const LOGIN_FORM_ID = 'login-form';

export const LOGIN_FORM_INITIAL_STATE_VALUE =  {
  email: '',
  password: ''
};

export const INITIAL_STATE = createFormGroupState<FormValue>(LOGIN_FORM_ID, LOGIN_FORM_INITIAL_STATE_VALUE);

const validateAndUpdateFormState = updateGroup<FormValue>({
  email: validate([required, email]),
  password: validate(required)
});

const validationFormGroupReducer = createFormStateReducerWithUpdate<FormValue>(validateAndUpdateFormState);

export function loginReducer(state = INITIAL_STATE, action: Action) {
  return validationFormGroupReducer(state, action);
}
