import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { CategoryBox } from '../../funz/category.model';
import { ImageHandlingService } from '../../core/services/image-handling.service';

@Component({
  selector: 'category-box',
  templateUrl: 'category-box.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryBoxComponent {
  category: CategoryBox;
  @Input('category')
  set _category(category: CategoryBox) {
    this.category = {
      ...category,
      image_url: this.fetchImageUrl(category.image_url)
    };
  }

  constructor(
    private imageHandling: ImageHandlingService
  ) {}

  fetchImageUrl(imageUrl) {
    return this.imageHandling.getProcessedAssestsUrl(imageUrl, {
      height: 183,
      width: 145
    });
  }
}
