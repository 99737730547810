
import { map } from 'rxjs/operators';
import { Component, Input } from '@angular/core';
import * as fromAuth from '../../shared-stores/auth/store/auth.reducers';
import * as fromApp from '../../core/store/app.reducers';
import * as fromCore from '../../core/store/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SiteHeaders } from '../../core/models/siteHeaders.model';

@Component({
  selector: 'marketing-opt',
  template: `
    <div class="promotion-opt">
      <i class="n2-ico-mask n2-ico-mask__checkbox-outline" *ngIf="(marketingOptInState | async)" (click)="toggleMarketingOptIn()"></i>
      <i class="n2-ico-mask n2-ico-mask__square-outline" *ngIf="!(marketingOptInState | async)" (click)="toggleMarketingOptIn()"></i>
      <div (click)="toggleMarketingOptIn()">
        {{translation | translate}}
      </div>
    </div>
  `
})
export class MarketingOptComponent {
  marketingOptInState: Observable<boolean>;
  @Input() translation = 'pages.authentication.marketing_opt_in';

  constructor(
    private store: Store<fromAuth.State>,
    private appStore: Store<fromApp.IAppState>
  ) {
    this.marketingOptInState = this.appStore
      .select(fromCore.getMarketingOptIn).pipe(
      map(
        (marketingOptIn) =>
          marketingOptIn
      ));
  }

  toggleMarketingOptIn() {
    this.store.dispatch(new fromCore.ToggleMarketingOptIn());
  }
}
