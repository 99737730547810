import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { TrackingService } from '../../core/services/tracking.service';

@Component({
  selector: 'order-payment-error',
  templateUrl: 'order-payment-error.html',
  styleUrls: ['order-payment-error.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPaymentErrorComponent {

  supportEmail: string;
  errorMessage: string;
  errorDescription: string;

  constructor(
    private modalCtrl: ModalController,
    private inAppBrowser: InAppBrowser,
    private navParams: NavParams,
    private trackingService: TrackingService,
  ) {
    this.supportEmail = this.navParams.get('supportEmail');
    this.errorMessage = this.navParams.get('errorMessage');
    this.errorDescription = this.navParams.get('errorDescription');
    this.trackingService.traceAction('order_payment_failure', {
      error_msg: this.errorMessage
    });
  }

  sendEmail() {
    this.inAppBrowser.create(
      `mailto:${this.supportEmail}`,
      '_blank',
      'location=no,hideurlbar=yes,zoom=no'
     );
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
