import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { APP_PAGES } from '../../pages';
import { PAGE_TO_URL_TRANSFORMER } from '../../app-routing.utils';
import { FunzMenuModalService } from '../funz-menu/funz-menu-modal.service';
import { TrackingService } from '../../core/services/tracking.service';
import { SearchProxy } from '../../shared-stores/search/search.proxy';
import { GeneralPwaService } from 'src/app/core/services/general-pwa.service';

@Component({
  selector: 'mobile-footer-menu',
  templateUrl: 'mobile-footer-menu.html',
  styleUrls: ['mobile-footer-menu.scss']
})
export class MobileFooterMenuComponent {
  APP_PAGES = APP_PAGES;

  constructor(
    private navCtrl: NavController,
    private modalService: FunzMenuModalService,
    private generalPwaService: GeneralPwaService,
    private searchProxy: SearchProxy,
    private trackingService: TrackingService,
  ) {}

  routeToPage(appPage: string) {
    if (appPage !== APP_PAGES.SearchPage) {
      this.searchProxy.resetFilter();
    }
    switch (appPage) {
      case APP_PAGES.DiscoverPage: {
        this.trackingService.traceAction('menu_discover_click');
        this.searchProxy.resetDate();
        break;
      }
      case APP_PAGES.SearchPage: {
        this.generalPwaService.noSuggest.next(true);
        this.trackingService.traceAction('search_click');
        break;
      }
      case APP_PAGES.WishlistPage: {
        this.trackingService.traceAction('menu_wishlist_click');
        break;
      }
      case APP_PAGES.OrdersPage: {
        this.trackingService.traceAction('menu_orders_click');
        break;
      }
      case APP_PAGES.MenuPage: {
        this.trackingService.traceAction('menu_more_click');
        if (this.isActiveMenu(APP_PAGES.MenuPage)) {
          history.back();
          return;
        }
        break;
      }
      default: {
        break;
      }
    }

    this.navCtrl.navigateRoot(PAGE_TO_URL_TRANSFORMER.getUrlByPageName(appPage));
  }

  isActiveMenu(pageName: string) {
    return !this.modalService.isModalOpen() && location.href.includes(PAGE_TO_URL_TRANSFORMER.getUrlByPageName(pageName));
  }
}
