import { TrackingService } from '../../core/services/tracking.service';
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { IOrderItem } from '../../core/store/activities';

@Component({
  selector: 'order-cover',
  templateUrl: 'order-cover.html',
  styleUrls: ['order-cover.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderCoverComponent {
  order: IOrderItem;
  @Input('order') set _order(order: IOrderItem) {
    this.order = order;
  }
  @Input() full = false;
  @Output() select = new EventEmitter();

  constructor(private trackingService: TrackingService) {}

  getBgImage(url: string) {
    return `url(${url})`;
  }

  get orderStatus() {
    const statusLabel = 'components.order.status';
    return `${statusLabel}.${this.order.status}`;
  }

  isOrderPink(item: IOrderItem) {
    return ['cancelled', 'evcancelled'].indexOf(item.status) > -1;
  }

  isOrderAvailable(item: IOrderItem) {
    return ['upcoming', 'started'].indexOf(item.status) > -1;
  }

  showOrder() {
    this.select.emit();
  }

  trackClick() {
    this.trackingService.traceAction('share_icon_click', {
      source: 'orders'
    });
  }
}
