import { Component } from '@angular/core';
import * as fromApp from '../../core/store/app.reducers';
import * as fromProfile from '../../core/store/profile';
import { Store } from '@ngrx/store';

@Component({
  selector: 'loyalty-card-wide',
  templateUrl: 'loyalty-card-wide.html'
})
export class LoyaltyCardWideComponent {
  showPoints: number;
  showTotalLoyaltyPoints = 5;
  loyaltyCardMessage$ = this.store.select(fromProfile.getLoyaltyCardMessage);
  totalLoyaltyPoints$ = this.store.select(fromProfile.getTotalLoyaltyPoints);

  constructor(private store: Store<fromApp.IAppState>) {
    this.totalLoyaltyPoints$.subscribe((currentTotalLoyaltyPoints: number) => {
      if (currentTotalLoyaltyPoints === 5) {
        this.showPoints = 999;
      } else if (currentTotalLoyaltyPoints === 0) {
        this.showPoints = 1;
        this.showTotalLoyaltyPoints = 0;
      } else {
        this.showPoints = currentTotalLoyaltyPoints;
      }
    });
  }
}
