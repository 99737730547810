import * as fromActions from './activities.actions';
import { IOrdersState, IOrderItem } from '../activities';
import * as fromModels from '../../models/activities.models';
import { get } from 'lodash';

export enum ActivityTypes {
  Orders = 'orders',
  Notifications = 'notifications',
  Inbox = 'inbox'
}

export interface IActivitiesState {
  notifications: fromModels.INotificationsResponse;
  orders: IOrdersState;
  inbox: {
    conversations: fromModels.IConversationsResponse;
    messages: fromModels.IMessages | any;
  };
  error?: object;
  selectedActivity: ActivityTypes;
  activityParams: any;
  // layout
  sendingMessage: boolean;
  messageSent: boolean;
  notificationsFetched: boolean;
  ordersFetched: boolean;
  conversationsFetched: boolean;
}

const initialState: IActivitiesState = {
  notifications: {
    notifications: [],
    total_pages: 0,
    canonical_url: null,
    page_title: null
  },
  orders: {
    entities: [],
    active: {},
    canonical_url: null,
    page_title: null
  },
  inbox: {
    conversations: {
      conversations: [],
      total_pages: 0,
      canonical_url: null,
      page_title: null
    },
    messages: new fromModels.MessageConversation()
  },
  selectedActivity: ActivityTypes.Orders,
  activityParams: {},
  // layout
  sendingMessage: false,
  messageSent: false,
  notificationsFetched: false,
  ordersFetched: false,
  conversationsFetched: false
};

export function reducer(
  state = initialState,
  action: fromActions.ActivitiesActions
) {
  switch (action.type) {
    case fromActions.ActionTypes.SET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: { ...action.payload },
        notificationsFetched: true
      };
    }

    case fromActions.ActionTypes.ADD_NOTIFICATIONS: {
      const { payload: { notifications, total_pages, canonical_url, page_title } } = action;
      return {
        ...state,
        notifications: {
          notifications: [
            ...state.notifications.notifications,
            ...notifications
          ],
          total_pages,
          canonical_url,
          page_title
        }
      };
    }

    case fromActions.ActionTypes.UPDATE_READ_NOTIFICATION_SUCCESS: {
      const { payload: { id, unread } } = action;
      const { notifications: { notifications, total_pages } } = state;
      const newItems = [...notifications];
      const notification = newItems.forEach(
        (noti: fromModels.INotification) => {
          if (noti.id === id) {
            noti.unread = unread;
          }
        }
      );
      return {
        ...state,
        notifications: {
          notifications: newItems,
          total_pages
        }
      };
    }

    case fromActions.ActionTypes.FETCH_ORDER: {
      return {
        ...state,
        ordersFetched: false
      };
    }

    case fromActions.ActionTypes.FETCH_ORDERS_SUCCESS: {
      return {
        ...state,
        orders: {
          ...state.orders,
          entities: [...action.payload.orders],
          canonical_url: action.payload.canonical_url,
          page_title: action.payload.page_title
        },
        ordersFetched: true
      };
    }

    case fromActions.ActionTypes.SET_CONVERSATIONS: {
      const { inbox } = state;
      return {
        ...state,
        inbox: {
          ...inbox,
          conversations: { ...action.payload }
        },
        conversationsFetched: true
      };
    }

    case fromActions.ActionTypes.FETCH_ORDER_SUCCESS: {
      return {
        ...state,
        orders: {
          ...state.orders,
          active: action.payload
        }
      };
    }

    case fromActions.ActionTypes.ADD_CONVERSATIONS: {
      const { payload: { conversations, total_pages, canonical_url, page_title } } = action;
      const { inbox } = state;
      return {
        ...state,
        inbox: {
          ...inbox,
          conversations: {
            conversations: [
              ...inbox.conversations.conversations,
              ...conversations
            ],
            total_pages,
            canonical_url,
            page_title
          }
        }
      };
    }

    case fromActions.ActionTypes.RESET_ACTIVE_ORDER: {
      return {
        ...state,
        orders: {
          ...state.orders,
          active: {}
        }
      };
    }

    case fromActions.ActionTypes.RESET_MESSAGES: {
      const { inbox } = state;
      return {
        ...state,
        inbox: {
          ...inbox,
          messages: new fromModels.MessageConversation()
        }
      };
    }

    case fromActions.ActionTypes.SAVE_ORDER_COMMENT_SUCCESS: {
      // update active order and orde rin orders
      const { payload } = action;
      const { orders, orders: { entities } } = state;
      const newOrders = entities.map(order => {
        if (order.id === payload.order_id) {
          return {
            ...order,
            feedback: payload
          };
        }
        return order;
      });
      return {
        ...state,
        orders: {
          ...orders,
          entities: [...newOrders],
          active: {
            ...orders.active,
            feedback: payload,
            can_edit_feedback: true,
          }
        }
      };
    }

    case fromActions.ActionTypes.FETCH_MESSAGES_SUCCESS: {
      const { payload: { conversation, total_pages, canonical_url, page_title } } = action;
      const { inbox, inbox: { messages } } = state;
      const stateMessages = messages.messages || [];
      const sortedMessages = [...stateMessages, ...conversation.messages]
        .sort((a: fromModels.IMessage, b: fromModels.IMessage) => {
          const aDate = new Date(a.created_at).getTime();
          const bDate = new Date(b.created_at).getTime();
          return bDate - aDate;
        })
        .reverse();

      return {
        ...state,
        inbox: {
          ...inbox,
          messages: {
            ...conversation,
            messages: sortedMessages,
            total_pages,
            canonical_url,
            page_title
          }
        }
      };
    }

    case fromActions.ActionTypes.REPLY_MESSAGE: {
      const { payload: { recipient_id, body, conversation_id } } = action;
      const { inbox, inbox: { messages } } = state;
      return {
        ...state,
        inbox: {
          ...inbox,
          messages: {
            ...messages,
            messages: [
              ...messages.messages
            ]
          }
        },
        sendingMessage: true,
        messageSent: false
      };
    }

    case fromActions.ActionTypes.REPLY_MESSAGE_SUCCESS: {
      const { payload: { author_id, body, created_at, id } } = action;
      const { inbox, inbox: { messages } } = state;
      const filteredMessages = messages.messages.filter(
        message => message.created_at
      );
      return {
        ...state,
        inbox: {
          ...inbox,
          messages: {
            ...messages,
            messages: [
              ...filteredMessages,
              {
                author_id,
                body,
                created_at,
                id
              }
            ]
          }
        },
        sendingMessage: false,
        messageSent: true
      };
    }

    case fromActions.ActionTypes.REPLY_MESSAGE_FAILURE: {
      const { payload: { author_id, body, created_at } } = action;
      const { inbox, inbox: { messages } } = state;
      const filteredMessages = messages.messages.filter(
        message => message.created_at
      );

      return {
        ...state,
        inbox: {
          ...inbox,
          messages: {
            ...messages,
            messages: [...filteredMessages]
          }
        },
        sendingMessage: false,
        messageSent: false,
        error: {
          title: 'Error',
          subTitle: 'Error sending message'
        }
      };
    }

    case fromActions.ActionTypes.CLEAR_ERROR_MESSAGE: {
      return {
        ...state,
        error: undefined
      };
    }

    case fromActions.ActionTypes.SET_ACTIVITY_TYPE: {
      const { payload: { activityType, params } } = action;

      return {
        ...state,
        selectedActivity: activityType,
        activityParams: params || {}
      };
    }

    case fromActions.ActionTypes.CLEAR_ACTIVITY_PARAMS: {

      return {
        ...state,
        activityParams: {}
      };
    }

    case fromActions.ActionTypes.CANCEL_ORDER_SUCCESS: {
      const { payload } = action;

      const orders = state.orders.entities.map((order: IOrderItem) => {
        if (order.id === get(state, 'orders.active.id')) {
          return {
            ...order,
            status: payload,
            can_be_cancelled: false
          };
        }
        return order;
      });

      return {
        ...state,
        orders: {
          ...state.orders,
          entities: [...orders],
          active: {
            ...state.orders.active,
            status: payload,
            can_be_cancelled: false
          }
        }
      };
    }

    case fromActions.ActionTypes.RESET_INBOX_MESSAGE: {
      const { inbox } = state;
      return {
        ...state,
        inbox: {
          conversations: {
            conversations: [],
            total_pages: 0
          },
          messages: new fromModels.MessageConversation()
        }
      };
    }

    default:
      return state;
  }
}
