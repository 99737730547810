import { Component } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'text-based-modal',
  templateUrl: 'text-based-modal.html',
  styleUrls: ['text-based-modal.scss']
})
export class TextBasedModalComponent {

  textContent: SafeHtml;
  title: string;

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private sanitizer: DomSanitizer
  ) {
    this.textContent = sanitizer.bypassSecurityTrustHtml(this.navParams.get('text'));
    this.title = this.navParams.get('title');
  }

  close() {
    this.modalCtrl.dismiss({});
  }
}
