import { Injectable } from '@angular/core';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { BehaviorSubject } from 'rxjs';
import { startWith, pairwise } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()
export class StatusBarService {

  stateWhite$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  whiteRoutes = ['home', 'category', 'funz', '/s/' ];

  constructor(private statusBar: StatusBar, private router: Router) {
  }

  init() {
    if (!!(window as any).cordova) {
      this.statusBar.overlaysWebView(true);
      this.statusBar.styleLightContent();
      this.router.events.subscribe(async event => {
        if (event instanceof NavigationEnd) {
          if (this.whiteRoutes.some((route) => (location.pathname).includes(route))) {
            this.stateWhite$.next(true);
          } else {
            this.stateWhite$.next(false);
          }
        }
      });
      this.listenState();
    }
  }

  private listenState() {
    this.stateWhite$.pipe(startWith(true), pairwise()).subscribe(([prev, curr]) => {
      if (prev !== curr) {
        curr ? this.statusBar.styleLightContent() : this.statusBar.styleDefault();
      }
    });
  }
}
