import { TrackingService } from '../../core/services/tracking.service';
import { IonContent, AlertController } from '@ionic/angular';
import { Component, ViewChild, AfterViewInit, OnDestroy, ElementRef, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChatProxy } from './chat.proxy';
import { IMessageReply } from '../../core/models/activities.models';
import { Subscription } from 'rxjs';
import { AuthorizationService } from '../../core/services/authorization.service';
import { Title } from '@angular/platform-browser';
import { SeoService } from '../../core/services/seo.service';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { keys } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

const findNewLinesRegxp = /([\n\r])+/gim;

@Component({
  selector: 'funz-chat',
  templateUrl: './chat.html',
  styleUrls: ['chat.scss'],
})
export class ChatComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() id;
  @Input() ownerId;
  @Input() params;
  @Output() onBack: EventEmitter<void> = new EventEmitter<void>();
  conversation$ = this.chatProxy.conversation$;
  username$ = this.chatProxy.username$;
  avatar$ = this.chatProxy.avatar$;
  messages$ = this.chatProxy.messages$;
  totalPages$ = this.chatProxy.totalPages$;
  errorMessage$ = this.chatProxy.errorMessage$;
  canonicalUrlAndPageTitle$ = this.chatProxy.canonicalUrlAndPageTitle$;
  rootDomain$ = this.chatProxy.rootDomain$;
  isLoading = true;

  public reply: FormGroup;
  private form$: Subscription;

  private pageTitleAndCanonicalUrlSubscription: Subscription;

  @ViewChild(IonContent) private content: IonContent;
  @ViewChild('messageArea') messageArea: ElementRef;
  public textareaRows = 0;
  private txtareaRef;
  public currentPage = 1;
  public totalPages = 0;
  showInfinite = false;
  scrollOffset = 0;
  public username = '';

  private pageTitle: string;
  private canonicalUrl: string;

  infiniteScroll: any;

  constructor(
    private chatProxy: ChatProxy,
    private formBuilder: FormBuilder,
    private titleService: Title,
    private seoService: SeoService,
    private inAppBrowser: InAppBrowser,
    private auth: AuthorizationService,
    private alertCtrl: AlertController,
    private trackingService: TrackingService,
    private route: ActivatedRoute,
  ) {
    this.reply = this.formBuilder.group({
      replyText: ''
    });
    this.form$ = this.reply.valueChanges.subscribe(form =>
      this.updateRowsByText(form.replyText)
    );
  }

  ngAfterViewInit() {
    // TODO improve logic of the row count
    // this.txtareaRef = this.messageArea.nativeElement.querySelector('textarea');
    this.messages$.subscribe(messages => {
      if (this.isLoading) {
        setTimeout(() => this.isLoading = false);
      }
      this.initiateContent(messages);
    });
    this.totalPages$
      .pipe(take(2))
      .subscribe(totalPages => (this.totalPages = totalPages));
    this.errorMessage$.subscribe(error => {
      if (error) {
        this.openErrorDialog(error);
      }
    });
    this.setRowsToChat(1);
  }

  ngOnDestroy() {
    if (this.pageTitleAndCanonicalUrlSubscription) { this.pageTitleAndCanonicalUrlSubscription.unsubscribe(); }
    if (this.form$) { this.form$.unsubscribe(); }
    this.chatProxy.resetMessages();
  }


  ngOnInit() {
    this.auth.isAuthorizedToViewPwa().then((v) =>  {
      if (!v) { this.onBack.emit(); }
    });
    const conversationId = this.getId();
    const params = this.getParams();
    const resolvedId = conversationId || params.conversation_id;
    this.chatProxy.resetMessages();
    this.currentPage = 1;
    this.username = params.name;
    if (resolvedId) {
      this.chatProxy.fetchMessages(resolvedId);
      this.isLoading = true;
    }
    if (this.pageTitle) {
      this.titleService.setTitle(this.pageTitle);
    }
    if (this.canonicalUrl) {
      this.seoService.setCanonicalURL(this.canonicalUrl);
    }
    this.pageTitleAndCanonicalUrlSubscription = this.canonicalUrlAndPageTitle$
      .subscribe(({ page_title, canonical_url }) => {
        if (canonical_url) {
          this.canonicalUrl = canonical_url;
          this.seoService.setCanonicalURL(canonical_url);
          if (page_title) {
            this.pageTitle = page_title;
            this.titleService.setTitle(page_title);
          } else {
            this.pageTitle = 'Funzing';
            this.titleService.setTitle(this.pageTitle);
          }
        }
      });
    this.trackingService.tracePageView('contact_pageview');
  }

  updateRowsByText(text: string) {
    if (text) {
      const newlines = text.match(findNewLinesRegxp);
      this.textareaRows = newlines && newlines.length ? newlines.length : 0;
      this.handleNewLines(this.textareaRows);
    }
  }

  initiateContent(messages) {
    if (messages && messages.length) {
      if (this.infiniteScroll) {
        this.infiniteScroll.complete();
      }
      setTimeout(() => {
        const yOffset =
          document.getElementById('scroll').scrollHeight -
          this.scrollOffset +
          65;
        if (this.content) {
          try {
            this.content.scrollToPoint(0, yOffset, 0);
          } catch (error) {}
        }
      }, 10);
      this.showInfinite = true;
    }
  }

  handleNewLines(newlines: number) {
    const rows = newlines > 3 ? 4 : newlines > 0 ? newlines + 1 : 1;
    this.setRowsToChat(rows);
  }

  setRowsToChat(rows: number) {
    // TODO improve logic of the row count
    // this.txtareaRef.setAttribute('rows', rows);
  }

  goBack() {
    this.onBack.emit();
  }

  getId() {
    const navParamsId = this.id;
    return navParamsId === 'new' ? null : Number(navParamsId);
  }

  getParams() {
    const params = this.params;
    const paramKeys = params && keys(params);
    return paramKeys && paramKeys.length ? params : {};
  }

  handleReply(conversation) {
    const ownerId = this.ownerId;
    const params = this.getParams();
    const message: IMessageReply = {
      recipient_id: ownerId || conversation.other_party_id,
      body: this.reply.value.replyText,
      conversation_id: conversation.id,
      ...params
    };
    this.chatProxy.replyMessage(message);
    setTimeout(() => {
      if (this.content) {
        this.content.scrollToBottom(0);
      }
    }, 0);

    this.reply.setValue({ replyText: '' }, { emitEvent: false });
    this.setRowsToChat(1);
  }

  isCurrentUser(messageAuthorId: number, currentConversationAuthorId: number) {
    return messageAuthorId !== currentConversationAuthorId;
  }

  doInfinite(event) {
    this.infiniteScroll = event.target;
    if (this.showInfinite) {
      this.showInfinite = false;
      this.scrollOffset = document.getElementById('scroll').scrollHeight;
      this.currentPage++;
      if (this.currentPage <= this.totalPages) {
        this.chatProxy.fetchMessages(this.getId(), this.currentPage);
      }
    }
  }

  openTermOfService() {
    this.rootDomain$.pipe(take(1)).subscribe(rootDomain => {
      const hideBannerParam = !!(window as any).cordova ? '?no_banner' : '';
      this.inAppBrowser.create(
        `${rootDomain}/terms-of-service${hideBannerParam}`,
        '_blank',
        'location=no,hideurlbar=yes,zoom=no'
      );
    });
  }

  async openErrorDialog(error) {
    // 1. Create instance & present
    const alert = await this.alertCtrl.create({
      header: error.title,
      subHeader: error.subTitle,
      buttons: [
        {
          text: 'Dismiss',
          handler: () => {
            this.chatProxy.clearErrorMessage();
            return true;
          }
        }
      ]
    });

    await alert.present();
  }

}
