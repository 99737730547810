import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

// created with 'ionic g pipe EventDate'
@Pipe({
  name: 'eventDate',
})
export class EventDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any): any {
    const currentLang = this.translateService.currentLang;
    const datePipe: DatePipe = new DatePipe(currentLang);
    const utcOffset = `UTC+${parseInt(value.split('+').pop(), 10)}`;
    const fullMonth =  datePipe.transform(value, 'LLLL', utcOffset);
    const monthString = fullMonth.length <= 4 ?
      fullMonth :
      `${datePipe.transform(value, 'LLL', utcOffset)}${currentLang === 'he' ? '' : '.'}`;
    return currentLang === 'he' ?
      `${datePipe.transform(value, ' EEEE, d', utcOffset)} ${monthString} ` :
      `${monthString} ${datePipe.transform(value, 'd, EEEE', utcOffset)}`;
  }
}
