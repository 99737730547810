import * as fromActions from './cities.actions';
import { City, NO_CITY_ID } from '../../../funz/city.model';
export const storeKey = 'cities';
export const recentCitiesStorageKey = 'funz-recent-cities';

export interface ICitiesState {
  cities: City[];
  selectedCityId: number;
  allCity: City;
  isOnline: number;
  recentCities: City[];
}

const initialState: ICitiesState = {
  cities: [],
  selectedCityId: null,
  allCity: { id: NO_CITY_ID, name: '', latitude: 0, longitude: 0, image_url: '' },
  isOnline: null,
  recentCities: []
};

export function reducer(
  state = initialState,
  action: fromActions.Actions
): ICitiesState {
  switch (action.type) {
    case fromActions.ActionTypes.FETCH_CITIES_SUCCESS: {
      const { payload: { cities } } = action;
      return {
        ...state,
        cities: [...cities]
      };
    }

    case fromActions.ActionTypes.LOAD_SELECTED_CITY: {
      return {
        ...state,
        selectedCityId: action.payload
      };
    }

    case fromActions.ActionTypes.SET_ONLINE: {
      return {
        ...state,
        isOnline: action.payload
      };
    }

    case fromActions.ActionTypes.INIT_RECENT_CITIES_SUCCESS: {
      return {
        ...state,
        recentCities: action.recentCities
      };
    }

    case fromActions.ActionTypes.SET_SELECTED_CITY: {
      const { payload: { id } } = action;
      let recentCities = (state.recentCities) ? state.recentCities.slice() : [];
      const selectedCity = state.cities.find((city) => city.id === id);
      if (selectedCity) {
        recentCities = [selectedCity]
          .concat(recentCities.filter(city => city.id !== id))
          .slice(0, 4);
      }
      return {
        ...state,
        recentCities,
        selectedCityId: id
      };
    }

    default: {
      return state;
    }
  }
}
