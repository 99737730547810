import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'owner-name',
  templateUrl: 'owner-name.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OwnerNameComponent {
  @Input()
  funzState = {
    owner: {
      id: 0,
      name: '',
      about: '',
      avatar: ''
    }
  };
}
