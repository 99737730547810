import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'subscription-banner',
  templateUrl: 'subscription-banner.html'
})
export class SubscriptionBannerComponent {

  @Output() onMoreInfoViewOpen = new EventEmitter<any>();

  constructor() { }
}
