import {
  Component,
  EventEmitter,
  Output,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { AppApi } from '../../core/api/app.api';
import { APP_PAGES } from '../../pages';
import { Store } from '@ngrx/store';
import { IAppState } from '../../core/store/app.reducers';
import * as fromCore from '../../core/store/core';
import * as fromProfile from '../../core/store/profile';
import * as LogRocket from 'logrocket';

@Component({
  moduleId: 'funz-tabs',
  selector: 'funz-tabs',
  templateUrl: 'funz-tabs.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FunzTabsComponent {
  @Input() selectedTab = APP_PAGES.DiscoverPage;
  @Output() select = new EventEmitter<string>();

  unreadNotificationsCounter$ = this.store.select(fromProfile.getHaveUnreads);

  tabs = [
    {
      page: APP_PAGES.DiscoverPage,
      text: 'tabs.discover',
      icon: 'ion-ios-custom-discover',
      useOverlay: false
    },
    {
      page: APP_PAGES.SearchPage,
      text: 'tabs.search',
      icon: 'ion-ios-custom-search-outline',
      useOverlay: false
    },
    {
      page: APP_PAGES.WishlistPage,
      text: 'tabs.wishlist',
      icon: 'ion-ios-custom-wishlist-m-outline',
      useOverlay: false
    },
    {
      page: APP_PAGES.ActivitiesPage,
      text: 'tabs.activities',
      icon: 'ion-ios-custom-activities-outline',
      useOverlay: true
    },
    {
      page: APP_PAGES.ProfilePage,
      text: 'tabs.profile',
      icon: 'ion-ios-custom-profile',
      useOverlay: false
    }
  ];

  constructor(
    private appApi: AppApi,
    private store: Store<IAppState>
    ) {}

  onTabSelect(event: Event, page: APP_PAGES) {
    event.preventDefault();
    LogRocket.log('funz-tab clicked: ' + page);
    this.selectedTab = page;
    this.appApi.goToPage(page);
    this.select.emit(page);
  }

  isActive(page: string) {
    return page === this.selectedTab;
  }

  isActiveOverlay(page: string, useOverlay: boolean) {
    return useOverlay;
  }
}
