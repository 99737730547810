import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ISubscription } from '../../core/store/activities';
import { NavController } from '@ionic/angular';
import { APP_PAGES } from '../../pages';
import { IAppState } from '../../core/store/app.reducers';
import { Store } from '@ngrx/store';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { TrackingService } from '../../core/services/tracking.service';
import { PAGE_TO_URL_TRANSFORMER } from '../../app-routing.utils';

@Component({
  selector: 'order-subscription-info',
  templateUrl: 'order-subscription-info.html',
  styleUrls: ['order-subscription-info.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderSubscriptionInfoComponent {

  @Input() subscription: ISubscription;
  @Input() supportEmail: string;
  @Input() rootDomain: string;

  constructor(
    private navCtrl: NavController,
    private store: Store<IAppState>,
    private inAppBrowser: InAppBrowser,
    private trackingService: TrackingService,
  ) {}

  goToLecturePage() {
    this.navCtrl.navigateRoot(
      PAGE_TO_URL_TRANSFORMER.getUrlByPageName(APP_PAGES.CategoryPage, { slug: 'lectures', id: 4 })
    );
  }

  openTermOfService() {
    const hideBannerParam = !!(window as any).cordova ? '?no_banner' : '';
    this.inAppBrowser.create(
      `${this.rootDomain}/terms-of-service${hideBannerParam}`,
      '_blank',
      'location=no,hideurlbar=yes,zoom=no'
    );
  }

  sendEmail() {
    this.inAppBrowser.create(
      `mailto:${this.supportEmail}`,
      '_blank',
      'location=no,hideurlbar=yes,zoom=no'
    );
  }

  goToSubscriptionSettingPage() {
    this.navCtrl.navigateRoot(
      PAGE_TO_URL_TRANSFORMER.getUrlByPageName(APP_PAGES.SubscriptionSettingsPage)
    );
  }
}
