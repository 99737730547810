import { Subscription ,  Observable } from 'rxjs';
import { Directive, Output, EventEmitter } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { Platform } from '@ionic/angular';
import { AppApi } from '../../core/api/app.api';

export enum ConnectionStatus {
  Online,
  Offline
}

@Directive({
  selector: '[appNetwork]' // Attribute selector
})
export class NetworkDirective {
  disconnectSubscription: Subscription;
  connectSubscription: Subscription;
  previousStatus: ConnectionStatus;

  constructor(
    private network: Network,
    private platform: Platform,
    private appApi: AppApi
  ) {
    this.initializeNetworkEvents();
  }

  public initializeNetworkEvents(): void {
    this.disconnectSubscription = this.network.onDisconnect().subscribe(() => {
      this.appApi.onConnectionChange(false);
      this.previousStatus = ConnectionStatus.Offline;
    });

    this.connectSubscription = this.network.onConnect().subscribe(() => {
      if (this.previousStatus === ConnectionStatus.Offline) {
        this.appApi.onConnectionChange(true);
      }
      this.previousStatus = ConnectionStatus.Online;
    });
  }
}
