import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICategoriesState, storeKey } from './categories.reducers';

export const selectCategories = createFeatureSelector<ICategoriesState>(storeKey);

export const getCategories = createSelector(
  selectCategories,
  (state) => state.categories
);

export const getCategoriesCollection = createSelector(
  getCategories,
  (categories) => categories.reduce(
    (acc, value) => {
      acc[value.id] = value;
      return acc;
    }, {})
);

export const getRelatedCategories = createSelector(
  getCategories,
  (categories) => categories.filter((c) => c.related)
);

export const getCategoriesLoaded = createSelector(
  selectCategories,
  (state) => state.loaded
);

export const getCategoriesIds = createSelector(
  getCategories,
  (categories) => categories ? categories.map(c => c.id) : []
);

export const getCurrentCategory = createSelector(
  getCategories,
  (categories, props) =>  categories.find(cat => cat.slug === props.slug)
);

export const getCurrentCategoryDisplayName = createSelector(
  getCurrentCategory,
  (category) => category.mobile_display_name
);
