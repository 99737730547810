import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'facebook-error',
  templateUrl: 'facebook-error.html',
  styleUrls: ['facebook-error.scss']
})
export class FacebookErrorComponent {
  constructor(private modalCtrl: ModalController) {}

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
