import * as fromCore from './core';
import * as fromProfile from './profile';
import * as fromActivities from './activities';
import * as fromInterests from './interests';
import * as fromCities from './cities';
import * as fromCategories from './categories';
import { ActionReducerMap } from '@ngrx/store';

export interface IAppState {
  funz?: any;
  core: fromCore.ICoreState;
  auth?: any;
  profile: fromProfile.IProfileState;
  activities: fromActivities.IActivitiesState;
  bookmarks?: any;
  host?: any;
  interests: fromInterests.IInterestsState;
  cities: fromCities.ICitiesState;
  categories: fromCategories.ICategoriesState;
}

export const reducers: ActionReducerMap<IAppState> = {
  core: fromCore.coreReducer,
  profile: fromProfile.profileReducer,
  activities: fromActivities.reducer,
  interests: fromInterests.reducer,
  cities: fromCities.reducer,
  categories: fromCategories.reducer,
};
