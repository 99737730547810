import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/app.reducers';
import { NavController } from '@ionic/angular';
import * as CoreStore from '../store/core';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as LogRocket from 'logrocket';

@Injectable()
export class OnboardingGuard implements CanActivate {

  constructor(
    private navCtrl: NavController,
    private store: Store<IAppState>,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    LogRocket.log('Onboarding canActivate guard entered');
    return this.store.select(CoreStore.getLastSelectedCityId).pipe(
      take(1),
      map((lastSelectedId) => {
        LogRocket.log(`Onboarding canActivate guard. lastSelectedId: ${lastSelectedId}`);
        if (lastSelectedId) {
          return this.router.navigate(['home']).then(() => false);
        } else {
          return true;
        }
      })
    );
  }
}
