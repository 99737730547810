import { Component, Input } from '@angular/core';
import { AppApi } from 'src/app/core/api/app.api';

@Component({
  selector: 'general-alert',
  templateUrl: 'general-alert.html',
  styleUrls: ['general-alert.scss']
})
export class GeneralAlertComponent {

  @Input() msg: string;
  closed = false;

  constructor(
    private appApi: AppApi,
  ) {}

  close() {
    this.appApi.setLocalStorageExpiration('general_alert');
    this.closed = true;
  }

}
