import { TrackingService } from '../../core/services/tracking.service';
import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { IOrderItem } from '../../core/store/activities';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromApp from '../../core/store/app.reducers';
import * as fromCore from '../../core/store/core';

export interface ICommentEvent {
  first_feedback: boolean;
  order_id: number;
  feedback: {
    like: boolean;
    comment: string;
  };
}

enum OrderStatusText {
  cancelled = 'Booking Cancelled',
  evcancelled = 'Event Cancelled',
  ended = 'Recent Experience',
  upcoming = 'Recent Experience',
  started = 'Recent Experience'
}

@Component({
  selector: 'order',
  templateUrl: 'order.html',
  styleUrls: ['order.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderComponent {
  @Input() item: IOrderItem;
  @Output() comment = new EventEmitter<ICommentEvent>();
  @Output() show = new EventEmitter<IOrderItem>();
  @Output() callNumber = new EventEmitter<string>();

  public commentForm: FormGroup;
  showingFooter = false;

  constructor(
    private fb: FormBuilder,
    private store: Store<fromApp.IAppState>,
    private trackingService: TrackingService,
  ) {
    this.store.dispatch(new fromCore.HidePwaFooter());
    this.commentForm = this.fb.group({
      commentText: ['', Validators.required]
    });
  }

  getBgImage(url: string) {
    return `url(${url})`;
  }

  isOrderPink(item: IOrderItem) {
    return ['cancelled', 'evcancelled'].indexOf(item.status) > -1;
  }

  isOrderAvailable(item: IOrderItem) {
    return ['upcoming', 'started'].indexOf(item.status) > -1;
  }

  commentOrder(comment: ICommentEvent) {
    this.comment.emit(comment);
  }

  showOrder() {
    this.store.dispatch(new fromCore.HidePwaFooter());
    this.trackingService.traceAction('order_click', {
      order_id: this.item.id
    });
    this.show.emit(this.item);
  }

  get showFeedback() {
    return this.item.allow_feedbacks || this.item.status === 'ended';
  }

  onCallNumber(phoneNumber) {
    this.callNumber.emit(phoneNumber);
  }
}
