import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bit-results',
  templateUrl: './bit-results.component.html',
  styleUrls: ['./bit-results.component.scss']
})
export class BitResultsComponent implements OnInit, AfterViewInit {

  result = null;
  statusCheckInterval;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    // alert('ngAfterViewInit');
    this.result = this.activatedRoute.snapshot.paramMap.get('result');
    // alert('this.result ' + this.result);
    setTimeout(() => {
      this.activatedRoute.queryParams.subscribe(params => {
        // alert('params ' + JSON.stringify(params) )
        if (this.result === 'success' && params.order_id) {
          this.close(params.order_id)
        }
      })
    }, 3000)
  }

  close(orderId = null) {
    // alert('Close ' + JSON.stringify((parent as any)));
    try {
      // console.log('window.parent', window.parent);
      (window.parent as any).closeBitPayment(orderId);
    } catch (error) {
      console.log('Err Err', error);
    }
  }

}
