
import { map } from 'rxjs/operators';
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit
} from '@angular/core';
import { Funz } from '../../funz/funz.model';
import { NavController } from '@ionic/angular';
import { chain } from 'lodash';
import isEqual from 'lodash/isEqual';
import { ImageHandlingService } from '../../core/services/image-handling.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../core/store/app.reducers';
import { SiteHeaders } from '../../core/models/siteHeaders.model';
import * as fromCore from './../../core/store/core';
import { PAGE_TO_URL_TRANSFORMER } from '../../app-routing.utils';
import { APP_PAGES } from '../../pages';

@Component({
  selector: 'funz-slides',
  templateUrl: 'funz-slides.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FunzSlidesComponent implements OnInit {
  direction$ = this.store
    .select(fromCore.getSiteHeaders).pipe(
    map((siteHeaders: SiteHeaders) => siteHeaders.direction));
  direction = 'rtl';
  viewFunz: Funz[] = [];
  showSlider = false;
  imageUrls = {};

  constructor(
    public store: Store<IAppState>,
    private navCtrl: NavController,
    private cd: ChangeDetectorRef,
    private imageHandling: ImageHandlingService
  ) {
    this.direction$.subscribe(direction => (this.direction = direction));
  }

  ngOnInit(): void {
    this.cd.detach();
  }

  @Input()
  set funzes(value: Funz[]) {
    // detect changes only once
    if (value && this.viewFunz.length === 0) {
      this.refreshSlider(value);
    } else {
      // run CD only if the funz are not equal
      const equal = chain(this.getIds(value))
        .differenceWith(this.getIds(this.viewFunz), isEqual)
        .isEmpty()
        .value();
      if (!equal) {
        this.refreshSlider(value);
      }
    }
  }

  trackByFunzIdFn(index, item) {
    return item.id;
  }

  onClickSlide(event, funzId) {
    this.navCtrl.navigateRoot(
      PAGE_TO_URL_TRANSFORMER.getUrlByPageName(APP_PAGES.FunzPage, { id: funzId })
    );
  }

  refreshSlider(value: Funz[]) {
    this.showSlider = false;
    this.cd.detectChanges();
    this.viewFunz = value;
    this.setImageUrlsObject();
    this.showSlider = true;
    this.cd.detectChanges();
  }

  getIds(valueArray) {
    return valueArray.map(value => value.id);
  }

  setImageUrlsObject() {
    this.imageUrls = {};
    this.viewFunz.forEach(
      funz =>
        (this.imageUrls[funz.id] = this.imageHandling.getFullWidthImage(
          funz.pictureUid,
          300
        ))
    );
  }
}
