import { Component, Input, ElementRef, Renderer2 } from '@angular/core';

const ionApp = document.getElementsByTagName('ion-app')[0];

@Component({
  selector: 'sticky-loader',
  templateUrl: 'sticky-loader.html',
  styleUrls: ['sticky-loader.scss']
})
export class StickyLoaderComponent {

  @Input() home = false;
  @Input() whiteBg = false;
  @Input() set loading(val) {
    if (val) {
      if (this.home) {
        this.renderer.setStyle(this.el.nativeElement, 'display', 'initial');
        const elHeight = window.innerHeight - this.el.nativeElement.nextElementSibling.offsetTop;
        const elOffsetTop = this.el.nativeElement.offsetTop;
        const parentElScrollTop = this.el.nativeElement.parentElement.scrollTop;
        if (parentElScrollTop < (elOffsetTop / 2)) {
          this.setMarginTop((elHeight / 2 - 64) + 'px');
        } else {
          this.setMarginTop('25%');
        }
      }
      this.renderer.addClass(this.el.nativeElement, 'loading');
    } else {
      const scrollEl = this.el.nativeElement.offsetParent
        ? this.el.nativeElement.offsetParent
        : this.el.nativeElement.parentNode.offsetParent;
      if (scrollEl) { scrollEl.scrollTo(0, 1); }
      this.renderer.removeClass(this.el.nativeElement, 'loading');
    }
  }

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  setMarginTop(val) {
    this.renderer.setStyle(this.el.nativeElement.lastElementChild.lastElementChild, 'margin-top', val);
  }

}
