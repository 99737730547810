import * as fromActions from './search.actions';
import { SearchFilterFieldType } from './search.filter.model';
import { deepCopyObject } from '../../core/services/funz.utils';
import { assign, isEqual } from 'lodash';

export const storeKey = 'search';

export interface State {
  total_pages: number;
  current_page: number;
  isSearching: boolean;
  isSearchingNext: boolean;
  isSearchingError: boolean;
  canonical_url: string;
  filter: any;
  slug: string;
  landing_page: boolean;
  description: string;
  mobile_display_name: string;
  image_url: string;
}

export enum SearchDateTypes {
  ANYTIME = 'anytime',
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  THIS_WEEK = 'next_7_days',
  UPCOMMING_WEEKEND = 'this_weekend',
  CUSTOM_DATE = 'from_date'
}


const initialFilterData = {
  [SearchFilterFieldType.QUERY]: '',
  [SearchFilterFieldType.DATE]: {
    title: 'components.filter.date.title.anytime',
    type: !!SearchDateTypes ? SearchDateTypes.ANYTIME : 'anytime'
  },
  [SearchFilterFieldType.CATEGORIES]: [],
  [SearchFilterFieldType.INTERESTS]: [],
  [SearchFilterFieldType.PRICE]: {}
};

const initialState: State = {
  total_pages: 1,
  current_page: 1,
  isSearching: false,
  isSearchingNext: false,
  isSearchingError: false,
  canonical_url: '',
  filter: deepCopyObject(initialFilterData),
  slug: null,
  landing_page: false,
  description: '',
  mobile_display_name: '',
  image_url: '',
};

export function reducer(
  state = initialState,
  action: fromActions.Actions
): State {
  switch (action.type) {
    case fromActions.ActionTypes.SEARCH_SUCCESS: {
      const { payload: { current_page, total_pages, canonical_url } } = action;
      return {
        ...state,
        current_page,
        total_pages,
        canonical_url,
        isSearching: false,
        isSearchingError: false,
      };
    }

    case fromActions.ActionTypes.SEARCH_ERROR: {
      return {
        ...state,
        isSearching: false,
        isSearchingError: true,
      };
    }

    case fromActions.ActionTypes.SEARCH_NEXT_PAGE_SUCCESS: {
      const { payload: { current_page, total_pages } } = action;
      return {
        ...state,
        current_page,
        total_pages,
        isSearching: false,
        isSearchingNext: false
      };
    }


    case fromActions.ActionTypes.SEARCH: {
      if (action.refresh) {
        return {
          ...state
        };
      } else {
        return {
          ...state,
          isSearching: true,
          isSearchingError: false,
          isSearchingNext: true,
          slug: null,
          landing_page: false,
        };
      }
    }

    case fromActions.ActionTypes.SEARCH_NEXT_PAGE: {
      return {
        ...state,
        isSearching: true,
        isSearchingError: false,
        isSearchingNext: true
      };
    }

    case fromActions.ActionTypes.UPDATE_FILTER: {
      return {
        ...state,
        filter: deepCopyObject(assign({}, state.filter, action.filter))
      };
    }

    case fromActions.ActionTypes.INIT_FILTER_SUCCESS: {
      if (!action || !action.filter) {
        return {
          ...state,
          filter: deepCopyObject(initialFilterData)
        };
      } else {
        if (isEqual(action.filter, state.filter)) {
          return state;
        } else {
          return {
            ...state,
            filter: action.filter
          };
        }
      }
    }

    case fromActions.ActionTypes.INIT_MAX_PRICE: {
      const oldPriceFilter = (state.filter[SearchFilterFieldType.PRICE] || {});
      if (!action || !oldPriceFilter) {
        return state;
      }
      if (action.maxPrice !== oldPriceFilter.maxPrice) {
        return {
          ...state,
          filter: {
            ...state.filter,
            [SearchFilterFieldType.PRICE]: {
              ...state.filter[SearchFilterFieldType.PRICE],
              minPrice: 0,
              maxPrice: action.maxPrice,
              lower: oldPriceFilter.lower || 0,
              upper: oldPriceFilter.upper || action.maxPrice
            }
          }
        };
      } else { return state; }
    }

    case fromActions.ActionTypes.RESET_FILTER: {
      const oldFilter = state.filter;
      const oldFilterPrice = oldFilter[SearchFilterFieldType.PRICE];
      const newFilter = deepCopyObject(initialFilterData);
      newFilter[SearchFilterFieldType.DATE] = { ...oldFilter[SearchFilterFieldType.DATE] };
      newFilter[SearchFilterFieldType.PRICE] = {
        minPrice: 0,
        maxPrice: oldFilterPrice.maxPrice,
        lower: 0,
        upper: oldFilterPrice.maxPrice
      };
      return {
        ...state,
        filter: newFilter
      };
    }

    case fromActions.ActionTypes.RESET_DATE: {
      const newFilter = deepCopyObject(state.filter);
      newFilter[SearchFilterFieldType.DATE] = deepCopyObject(initialFilterData[SearchFilterFieldType.DATE]);
      return {
        ...state,
        filter: newFilter,
      };
    }

    case fromActions.ActionTypes.FETCH_SLUG_DEEPLINK: {
      return {
        ...state,
        isSearching: true,
        landing_page: true,
        slug: action.payload
      };
    }

    case fromActions.ActionTypes.LOAD_LANDING_PAGE: {
      const { payload } = action;
      return {
        ...state,
        isSearching: false,
        isLoaded: true,
        ...payload
      };
    }

    default: {
      return state;
    }
  }
}
