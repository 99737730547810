import { Action } from '@ngrx/store';
import { City } from '../../../funz/city.model';

export enum ActionTypes {
  FETCH_CITIES = '[Cities] FETCH_CITIES',
  FETCH_CITIES_SUCCESS = '[Cities] FETCH_CITIES_SUCCESS',
  FETCH_SELECTED_CITY = '[Cities] FETCH_SELECTED_CITY',
  LOAD_SELECTED_CITY = '[Cities] LOAD_SELECTED_CITY',
  SET_SELECTED_CITY = '[Cities] SET_SELECTED_CITY',
  LOAD_ONLINE = '[Cities] LOAD_ONLINE',
  SET_ONLINE = '[Cities] SET_ONLINE',
  FETCH_NEAREST_CITY = '[Cities] FETCH_NEAREST_CITY',
  SKIP_CITY_SELECTION = '[Cities] SKIP_CITY_SELECTION',
  SET_USER_CITY = '[Cities] SET_USER_CITY',
  CHECK_CITIES_FETCHED = '[Cities] CHECK_CITIES_FETCHED',
  INIT_RECENT_CITIES = '[Cities] INIT_RECENT_CITIES',
  INIT_RECENT_CITIES_SUCCESS = '[Cities] INIT_RECENT_CITIES_SUCCESS',
}

export class FetchCityList implements Action {
  readonly type = ActionTypes.FETCH_CITIES;
  constructor() {}
}

export class FetchCityListSuccess implements Action {
  readonly type = ActionTypes.FETCH_CITIES_SUCCESS;
  constructor(public payload: any) {}
}

export class FetchSelectedCity implements Action {
  readonly type = ActionTypes.FETCH_SELECTED_CITY;
  constructor() {}
}

export class LoadSelectedCity implements Action {
  readonly type = ActionTypes.LOAD_SELECTED_CITY;
  constructor(public payload: any) {}
}

export class SetSelectCity implements Action {
  readonly type = ActionTypes.SET_SELECTED_CITY;
  constructor(public payload: any) {}
}

export class LoadOnline implements Action {
  readonly type = ActionTypes.LOAD_ONLINE;
  constructor(public payload: any) {}
}

export class SetOnline implements Action {
  readonly type = ActionTypes.SET_ONLINE;
  constructor(public payload: any) {}
}

export class FetchNearestCity implements Action {
  readonly type = ActionTypes.FETCH_NEAREST_CITY;
  constructor(public payload?: any) {}
}

export class SkipCitySelection implements Action {
  readonly type = ActionTypes.SKIP_CITY_SELECTION;
  constructor(public payload: any) {}
}

export class SetUserCity implements Action {
  readonly type = ActionTypes.SET_USER_CITY;
  constructor(public payload: any) {}
}

export class CheckCitiesFetched implements Action {
  readonly type = ActionTypes.CHECK_CITIES_FETCHED;
  constructor() {}
}

export class InitRecentCities implements Action {
  readonly type = ActionTypes.INIT_RECENT_CITIES;
  constructor() {}
}

export class InitRecentCitiesSuccess implements Action {
  readonly type = ActionTypes.INIT_RECENT_CITIES_SUCCESS;
  constructor(public recentCities: City[]) {}
}

export type Actions =
  | FetchCityList
  | FetchCityListSuccess
  | FetchSelectedCity
  | LoadSelectedCity
  | SetSelectCity
  | LoadOnline
  | SetOnline
  | FetchNearestCity
  | SkipCitySelection
  | SetUserCity
  | InitRecentCities
  | InitRecentCitiesSuccess;
