import { Injectable } from '@angular/core';
import { FunzApiService } from '../../core/services/funz-api.service';
import { ModalController } from '@ionic/angular';
import { TextBasedModalComponent } from './text-based-modal';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TextBasedModalService {
  urlToTranslationIdMap;

  constructor(
    private modalCtrl: ModalController,
    private apiService: FunzApiService,
    private translateService: TranslateService,
  ) {
    this.urlToTranslationIdMap = {
      about: { body: 'funzing.pages.static_pages.about.body', titleKey: 'component.contact-modal.about_us' },
      'about-mobile': { body: 'funzing.pages.static_pages.about.body_mobile', titleKey: 'component.contact-modal.about_us' },
      faq: { body: 'funzing.pages.static_pages.faq.body', titleKey: 'component.contact-modal.faq' },
      affiliates:
        { body: 'funzing.pages.static_pages.affiliates.body_part_1.initial_content', titleKey: 'component.contact-modal.affiliate' },
      'cancellation-policy':
        { body: 'funzing.pages.static_pages.cancellation_policy.body', titleKey: 'component.contact-modal.cancellation' },
      'terms-of-service': { body: 'funzing.pages.static_pages.terms_of_service.body', titleKey: 'component.contact-modal.terms' },
      'privacy-policy': { body: 'funzing.pages.static_pages.privacy_policy.body', titleKey: 'component.contact-modal.privacy' },
    };
  }

  openModal(url) {
    const translationObject = this.urlToTranslationIdMap[url];
    if (translationObject) {
      this.apiService.requestRoot(`id=${translationObject.body}`, 'sites/content').subscribe(async (v) => {
        const modal = await this.modalCtrl.create({
          component: TextBasedModalComponent,
          componentProps: {
            text: v.text,
            title: this.translateService.instant(translationObject.titleKey)
          }
        });
        modal.present();
      });
    }
  }
}
