import { Injectable } from '@angular/core';
import { APP_PAGES } from '../../pages';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/app.reducers';
import * as fromProfile from '../store/profile';
import * as fromCore from '../../core/store/core';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty, assign } from 'lodash';
import { take } from 'rxjs/operators';

@Injectable()
export class MenuService {
  cordova = !!(window as any).cordova;
  private profileDetails$ = this.store.select(fromProfile.getNameAndAvatar);
  private locale$ = this.store.select(fromCore.getSelectedLanguage);
  public menuData: any = {
    loaded: false,
    navigationLinks: []
  };

  constructor(
    private store: Store<IAppState>,
    private translate: TranslateService
  ) {
    if (!isEmpty(this.translate.store.translations)) {
      this.loadMenuData();
    }
    this.translate.onLangChange.subscribe(() => {
      if (this.menuData.loaded) {
        this.regenerateMenuData();
      } else {
        this.loadMenuData();
      }
    });
  }

  loadMenuData() {
    if (this.menuData.loaded) {
      return;
    }
    assign(this.menuData, {
      loaded: true,
      authMenuItems: this.generateAuthMenuItems(),
      navigationLinks: this.generateNavigationLinks(),
      logoutMenuItem: this.generateLogoutMenuItem(),
      rootDomain: '',
      userItems: {
        signedIn: false,
        profilePage: { component: APP_PAGES.ProfilePage },
        userName: null,
        userAvatar: null,
        email: null
      }
    });

    this.profileDetails$.subscribe(profileDetails => {
      this.menuData.userItems.signedIn = profileDetails.signedIn;
      this.menuData.rootDomain = profileDetails.rootDomain;
      if (profileDetails.signedIn) {
        this.menuData.userItems.userName = profileDetails.name;
        this.menuData.userItems.userAvatar = profileDetails.avatar_url;
        this.menuData.userItems.email = profileDetails.email;
      }
    });
  }

  regenerateMenuData() {
    this.locale$.pipe(take(1)).subscribe(locale => {
      this.menuData.navigationLinks = this.generateNavigationLinks(locale === 'he');     
    });
    this.menuData.authMenuItems = this.generateAuthMenuItems();
    this.menuData.logoutMenuItem = this.generateLogoutMenuItem();
  }

  generateAuthMenuItems() {
    return {
      Login: {
        title: this.translate.instant(
          'pages.authentication.register.signin.link'
        ),
        authType: 'Login'
      },
      Registration: {
        title: this.translate.instant('pages.authentication.login.signup.link'),
        authType: 'SignUp'
      }
    };
  }

  generateNavigationLinks(isHe = true) {
    const hideBannerParam = this.cordova ? '?no_banner' : '';
    const links: any = [
      {
        pageName: 'home',
        title: this.translate.instant('menu.home'),
        component: APP_PAGES.DiscoverPage,
        icon: 'funz-home',
        requireLogin: false
      },
      {
        pageName: 'orders',
        title: this.translate.instant('menu.orders'),
        component: APP_PAGES.OrdersPage,
        icon: 'funz-order',
        requireLogin: true
      },
      {
        pageName: 'bookmarks',
        title: this.translate.instant('menu.wishlist'),
        component: APP_PAGES.WishlistPage,
        icon: 'funz-heart',
        requireLogin: true
      },
      {
        pageName: 'notifications',
        title: this.translate.instant('menu.notifications'),
        component: APP_PAGES.NotificationsPage,
        icon: 'funz-notification',
        requireLogin: true
      },
      {
        pageName: 'conversations',
        title: this.translate.instant('menu.conversations'),
        component: APP_PAGES.ConversationsPage,
        icon: 'funz-conversation',
        requireLogin: true
      },
      {
        title: this.translate.instant('menu.b2b'),
        external: `b2b${hideBannerParam}`,
        icon: 'funz-team-building',
        requireLogin: false
      },
      {
        pageName: 'invite',
        title: this.translate.instant('menu.invite_a_friend'),
        component: APP_PAGES.SharePage,
        icon: 'funz-invite',
        requireLogin: false
      },
      {
        title: this.translate.instant('menu.gift_card'),
        external: `gift_card${hideBannerParam}`,
        icon: 'funz-gift',
        requireLogin: false
      },
      {
        pageName: 'settings',
        title: this.translate.instant('menu.contact_us'),
        component: APP_PAGES.SettingsPage,
        icon: 'funz-contact',
        requireLogin: false
      }
    ];
    return isHe ? links.filter(v => v.pageName !== 'invite') : links;
  }

  generateLogoutMenuItem() {
    return {
      title: this.translate.instant('menu.logout'),
      action: 'logout',
      icon: 'funz-logout'
    };
  }
}
