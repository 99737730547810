import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import * as fromActions from './interests.actions';
import * as fromInterestsSelectors from './interests.selectors';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { FunzApiService } from '../../services/funz-api.service';
import { IAppState } from '../app.reducers';

@Injectable()
export class InterestsEffects {
  @Effect()
  getInterestsTags$: Observable<Action> = this.actions$
    .pipe(
      ofType(fromActions.ActionTypes.GET_INTERESTS),
      switchMap(action => this.funzApi.requestRoot('', 'interests')),
      map(data => new fromActions.GetInterestsSuccess(data))
    );

  @Effect()
  requestInterests$: Observable<Action> = this.actions$
    .pipe(
      ofType(fromActions.ActionTypes.REQUEST_INTERESTS),
      withLatestFrom(this.store.select(fromInterestsSelectors.hasInterests)),
      filter((hasInterestsResponse: any) => !hasInterestsResponse[1]),
      map(() => new fromActions.GetInterests())
    );

  constructor(
    private actions$: Actions,
    private funzApi: FunzApiService,
    private store: Store<IAppState>
  ) {}
}
