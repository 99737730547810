import { Observable ,  from as fromPromise } from 'rxjs';
import { Injectable } from '@angular/core';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';

@Injectable()
export class DiagnosticProvider {
  constructor(private diagnostic: Diagnostic) {
    this.isLocationAvailable();
  }

  isLocationAvailable(): Observable<any> {
    return fromPromise(this.diagnostic.isLocationAvailable());
  }

  requestLocationAuthorization() {
    return this.diagnostic.requestLocationAuthorization();
  }
}
