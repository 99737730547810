import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { CategoryBox } from '../../funz/category.model';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import * as fromCore from '../../core/store/core';
import * as fromApp from '../../core/store/app.reducers';
import { PAGE_TO_URL_TRANSFORMER } from '../../app-routing.utils';
import { APP_PAGES } from '../../pages';

@Component({
  selector: 'category-scroll',
  templateUrl: 'category-scroll.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryScrollComponent {
  @Input() categories: CategoryBox[];

  constructor(
    private navCtrl: NavController,
    private store: Store<fromApp.IAppState>
  ) {}

  onClickCategory(category: CategoryBox) {
    this.store.dispatch(new fromCore.SetCategory(category));

    const { id, slug } = category;
    this.navCtrl.navigateRoot(
      PAGE_TO_URL_TRANSFORMER.getUrlByPageName(APP_PAGES.CategoryPage, { id: slug })
    );
  }

  trackByFn(index, item) {
    return index; // or item.id
  }
}
