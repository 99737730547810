import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/app.reducers';
import * as fromCore from '../store/core';
import { PAGE_CHANGE, TrackingService } from './tracking.service';
import { ENV } from '../../../environments/environment';

@Injectable()
export class SeoService {
  constructor(
    @Inject(DOCUMENT) private doc,
    private trackingService: TrackingService,
    private store: Store<IAppState>
  ) {}

  canonicalLink: HTMLLinkElement;
  canonicalLinkCreated = false;
  rootCanonicalUrl: string;
  currentCanonicalUrl: string;

  createLinkForCanonicalURL() {
    return new Promise((resolve, reject) => {
      if (this.canonicalLinkCreated) {
        resolve();
      } else {
        this.canonicalLink = this.doc.createElement('link');
        this.canonicalLink.setAttribute('rel', 'canonical');
        this.doc.head.appendChild(this.canonicalLink);
        this.canonicalLinkCreated = true;
        resolve();
      }
    });
  }

  setCanonicalURL(canonicalUrl) {
    if (!!canonicalUrl && this.currentCanonicalUrl !== canonicalUrl) {
      this.currentCanonicalUrl = canonicalUrl;
      const pwaCanonicalUrl = this.addPwaTo(canonicalUrl + location.search);
      const relativeCanonicalUrl =  pwaCanonicalUrl.split(ENV.core_domain)[1] || '/';
      this.createLinkForCanonicalURL().then(() => {
        this.canonicalLink.setAttribute('href', canonicalUrl);
      });
      this.trackingService.pushToDataLayer({ canonicalUrl, pwaCanonicalUrl, relativeCanonicalUrl });
      this.trackingService.trackGAEvent(PAGE_CHANGE);
    }
  }

  unsetCanoncialURL() {
    this.createLinkForCanonicalURL().then(() => {
      this.canonicalLink.setAttribute('href', this.rootCanonicalUrl);
    });
  }

  useRootCanonicalURL() {
    this.setCanonicalURL(this.rootCanonicalUrl);
  }

  setRootCanonicalURL() {
    this.createLinkForCanonicalURL().then(() => {
      this.store
        .select(fromCore.getRootDomain)
        .subscribe(rootDomain => {
          this.rootCanonicalUrl = rootDomain;
        });
    });
  }

  addPwaTo(url) {
    if (url.includes('pwa=')) {
      return url;
    } else {
      return (url).includes('?') ? `${url}&pwa=true` : `${url}?pwa=true`;
    }
  }
}
