
import { interval as observableInterval, timer as observableTimer,  Observable, Subject, Subscription } from 'rxjs';

import { take, map,  takeUntil } from 'rxjs/operators';
import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'order-countdown-timer',
  templateUrl: 'order-countdown-timer.html'
})
export class OrderCountdownTimerComponent {

  text: string;
  timeDisplay: string;

  @Input() timeToSet: number = 5 * 60 * 1000;
  @Input('start') set _startCountdown(isStartTime) {
    const timeInterval = this.timeToSet - (new Date().getTime() - isStartTime);

    if (isStartTime && timeInterval > 0) {
      this.restart();
      this.start(timeInterval);
    } else {
      this.onCountdownFinish.emit();
    }

  }
  @Input('restart') set _restartCountdown(isRestart) {
    if (isRestart) { this.restart(); }
  }

  @Output() onCountdownStart = new EventEmitter<boolean>();
  @Output() onCountdownTick = new EventEmitter<number>();
  @Output() onCountdownFinish = new EventEmitter<boolean>();

  counter$: Subscription;
  timer$: Observable<number>;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor() {
  }

  start(timeInterval: number = 0) {

    // Set the timer to 'timeToSet'
    this.timer$ = observableTimer(timeInterval);

    this.destroy$ = new Subject<boolean>();

    // Start the countdown
    this.counter$ = observableInterval(1000).pipe(
      map((v) => (Math.trunc(timeInterval / 1000) - 1) - v),
      take(1000), )
      .pipe(
        takeUntil(this.timer$)
      ).subscribe(
        (counter: number) => {
          this.onCountdownTick.emit(this.setText(counter));
        },
        null,
        () => {
          this.setText(0);
          this.onCountdownFinish.emit();
        }
      );

    // Trigger onStart Action
    this.onCountdownStart.emit();
  }

  restart() {
    this.onDestroy();
  }

  setText(counter) {
    const minutes = Math.trunc(counter / 60);
    const seconds = counter % (60);

    this.timeDisplay = `${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;
    return counter;
  }

  onDestroy() {
    if (this.counter$ && !this.counter$.closed) {
      this.counter$.unsubscribe();
      this.destroy$.next(true);
      this.destroy$.unsubscribe();
    }
  }

}
