import { Action } from '@ngrx/store';
import { IFunzBookmarkResponse } from '../../funz/funz.model';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 */
export enum BookmarksActionTypes {
  FETCH_BOOKMARKS = '[Bookmarks] FETCH_BOOKMARKS',
  FETCH_BOOKMARKS_SUCCESS = '[Bookmarks] FETCH_BOOKMARKS_SUCCESS',
  UPDATE_BOOKMARKS = '[Bookmarks] UPDATE_BOOKMARKS'
}

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 */
export class FetchBookmarks implements Action {
  readonly type = BookmarksActionTypes.FETCH_BOOKMARKS;

  constructor() {}
}

export class FetchBookmarksSuccess implements Action {
  readonly type = BookmarksActionTypes.FETCH_BOOKMARKS_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateBookmarks implements Action {
  readonly type = BookmarksActionTypes.UPDATE_BOOKMARKS;

  constructor(public payload: IFunzBookmarkResponse) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type BookmarksActions =
  | FetchBookmarks
  | FetchBookmarksSuccess
  | UpdateBookmarks;
