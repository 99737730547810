import {
  createFormGroupState, createFormStateReducerWithUpdate, updateGroup,
  validate
} from 'ngrx-forms';
import { email, required } from 'ngrx-forms/validation';
import { Action } from '@ngrx/store';

export interface FormValue {
  email: string;
}

export const PASSWORD_FORM_ID = 'password-form';

export const PASSWORD_INITIAL_STATE_VALUE = { email: '' };
export const INITIAL_STATE = createFormGroupState<FormValue>(PASSWORD_FORM_ID, PASSWORD_INITIAL_STATE_VALUE);

const validateAndUpdateFormState = updateGroup<FormValue>({
  email: validate([required, email])
});
const validationFormGroupReducer = createFormStateReducerWithUpdate<FormValue>(validateAndUpdateFormState);

export function passwordReducer(state = INITIAL_STATE, action: Action) {
  return validationFormGroupReducer(state, action);
}
