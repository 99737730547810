import { Action } from '@ngrx/store';

export enum ActionTypes {
  FETCH_CATEGORIES = '[Categories] fetch categories',
  FETCH_CATEGORIES_SUCCESS = '[Categories] fetch categories success',
  FETCH_CATEGORIES_ERROR = '[Categories] fetch categories error',
}

export class FetchCategories implements Action {
  readonly type = ActionTypes.FETCH_CATEGORIES;
  constructor() {}
}

export class FetchCategoriesSuccess implements Action {
  readonly type = ActionTypes.FETCH_CATEGORIES_SUCCESS;
  constructor(public payload: any) {}
}

export class FetchCategoriesError implements Action {
  readonly type = ActionTypes.FETCH_CATEGORIES_ERROR;
  constructor() {}
}

export type Actions =
  | FetchCategories
  | FetchCategoriesSuccess
  | FetchCategoriesError;
