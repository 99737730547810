import { NgModule } from '@angular/core';
import { MenuPage } from './menu';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { FunzMenuComponent } from 'src/app/components/funz-menu/funz-menu.component';

const routes: Routes = [
  {
    path: 'menu',
    component: MenuPage
  }
];

@NgModule({
  declarations: [
    MenuPage,
    FunzMenuComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    FunzMenuComponent
  ]
})
export class MenuPageModule {}
