import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'gift-card-modal',
  templateUrl: 'gift-card-modal.html',
  styleUrls: ['gift-card-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GiftCardModalComponent {

  rootDomain: string;
  constructor(
    private modalCtrl: ModalController,
    private inAppBrowser: InAppBrowser,
    private navParams: NavParams,
  ) {
    this.rootDomain = this.navParams.get('rootDomain');
  }

  goToGiftCard() {
    const hideBannerParam = !!(window as any).cordova ? '?no_banner' : '';
    this.inAppBrowser.create(`${this.rootDomain}/gift_card${hideBannerParam}`, '_blank', 'location=no,hideurlbar=yes,zoom=no');
    this.modalCtrl.dismiss();
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
