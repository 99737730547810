import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'order-event-orderable',
  templateUrl: 'order-event-orderable.html',
  styleUrls: ['order-event-orderable.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderEventOrderableComponent {

  constructor(private modalCtrl: ModalController) {
  }

  dismiss(param = false) {
    this.modalCtrl.dismiss(param);
  }

}
