import * as fromAuth from './auth.actions';
import { ActionReducerMap, combineReducers } from '@ngrx/store';
import * as fromRegister from './register/register.reducers';
import * as fromLogin from './login/login.reducers';
import * as fromPassword from './password/password.reducers';
import { FormGroupState } from 'ngrx-forms';
import { InjectionToken } from '@angular/core';

export interface AuthState {
  authenticating: boolean;
  displayedComponent: string;
  initiated: boolean;
  signedIn: boolean;
  disableSubmit: boolean;
  fromMenu: boolean;
  facebookSignError: boolean;
  appleSignError: boolean;
}

const initialState: AuthState = {
  authenticating: false,
  displayedComponent: !!(window as any).cordova ? 'root' : 'signup',
  initiated: false,
  signedIn: false,
  disableSubmit: false,
  fromMenu: true,
  facebookSignError: false,
  appleSignError: false,
};

export interface State {
  auth: AuthState;
  registerForm: FormGroupState<fromRegister.FormValue>;
}

export function authReducer(
  state = initialState,
  action: fromAuth.AuthActions
) {
  switch (action.type) {
    case fromAuth.TRY_FB_SIGNIN:
      return {
        ...state,
        authenticating: true,
        facebookSignError: false,
      };
    case fromAuth.SIGNUP:
      return {
        ...state,
        signedIn: false,
        authenticating: false
      };
    case fromAuth.SIGNIN:
      return {
        ...state,
        signedIn: true,
        authenticating: false
      };
    case fromAuth.SIGNUP_ERROR:
      return {
        ...state,
        authenticating: false
      };
    case fromAuth.FB_SIGNIN_ERROR:
      return {
        ...state,
        facebookSignError: true,
        authenticating: false
      };
    case fromAuth.SIGNIN_ERROR:
      return {
        ...state,
        authenticating: false
      };
    case fromAuth.LOGOUT:
      return {
        ...state,
        signedIn: false
      };
    case fromAuth.OPEN_SIGNUP:
      return {
        ...state,
        displayedComponent: 'Registration',
        authenticating: false
      };
    case fromAuth.OPEN_SIGNUP_PAGE:
      return {
        ...state,
        displayedComponent: 'SignUp',
        authenticating: false
      };
    case fromAuth.OPEN_SIGNIN:
      return {
        ...state,
        displayedComponent: 'Login',
        authenticating: true
      };
    case fromAuth.OPEN_PASSWORD_RESET:
      return {
        ...state,
        displayedComponent: 'password',
        authenticating: true
      };
    case fromAuth.BACK_TO_ROOT:
      return {
        ...state,
        displayedComponent: 'root',
        authenticating: false
      };
    case fromAuth.DISABLE_SUBMIT:
      return {
        ...state,
        disableSubmit: true
      };
    case fromAuth.ENABLE_SUBMIT:
      return {
        ...state,
        disableSubmit: false
      };
    case fromAuth.RESET_FB_ERROR:
      return  {
        ...state,
        facebookSignError: false,
      };
    case fromAuth.SET_FROM_MENU:
      return {
        ...state,
        displayedComponent: !!(window as any).cordova ? 'root' : 'signup',
        fromMenu: action.fromMenu
      };
    default:
      return state;
  }
}
export const authReducers = combineReducers({
  auth: authReducer,
  registerForm: fromRegister.registerReducer,
  loginForm: fromLogin.loginReducer,
  passwordForm: fromPassword.passwordReducer,
});

export function getAuthReducers() {
  return authReducers;
}

export const reducerToken = new InjectionToken<ActionReducerMap<State>>(
  'Reducers'
);

export const authReducerProvider = [
  { provide: reducerToken, useFactory: getAuthReducers }
];
