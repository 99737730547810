import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SearchEffects } from './search.effects';
import { reducer, storeKey } from './search.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(storeKey, reducer),
    EffectsModule.forFeature([SearchEffects])
  ]
})
export class SearchStoreModule {}
