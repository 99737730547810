import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { IOrderItem, IOrderDetails } from '../../core/store/activities';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'order-details',
  templateUrl: 'order-details.html',
  styleUrls: ['order-details.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailsComponent implements OnInit {
  @Input() order: IOrderItem | IOrderDetails | any;
  @Input() full = false;
  @Input() liveFunz = false;
  @Input() moreLink = true;
  @Input() withSubscription = false;

  constructor(
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
  }

  get time() {
    return new Intl.DateTimeFormat('he').format(
      new Date(this.order.start_time)
    );
  }
}
