import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'auth-forms',
  templateUrl: 'auth-forms.html',
  styleUrls: ['auth-forms.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthFormsComponent {
  @Input() displayedComponent: string;
  @Output() close = new EventEmitter<void>();
  @Output() login = new EventEmitter<void>();
  @Output() register = new EventEmitter<void>();
  @Output() resetPassword = new EventEmitter<void>();

  constructor() {}

  backToRoot() {
    this.close.emit();
  }

  handleLogin() {
    this.login.emit();
  }

  handleRegister() {
    this.register.emit();
  }

  handleResetPassword() {
    this.resetPassword.emit();
  }
}
