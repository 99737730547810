import { Component, Input, OnInit } from '@angular/core';
import { AdminStats } from '../../funz/funz.model';
import { get, isEmpty } from 'lodash';

@Component({
  selector: 'appAdminStats',
  templateUrl: 'funz-admin-stats.html',
  styleUrls: ['funz-admin-stats.scss']
})
export class FunzAdminStatsComponent implements OnInit {
  @Input() public adminStats: AdminStats;
  public preparedStats30: any = [];
  public preparedStats7: any = [];

  ngOnInit() {
    if (!isEmpty(get(this, 'adminStats.thirtyDays'))) {
      this.preparedStats30.push(...[
        { text: `Last 30 days (including)`, class: 'label label__primary' },
        { text: `views: ${this.adminStats.thirtyDays.views}`, class: 'label label__default' },
        { text: `orders: ${this.adminStats.thirtyDays.orders} (tickets: ${this.adminStats.thirtyDays.tickets})`,
          class: 'label label__warning' },
        { text: `orders conversion: ${this.adminStats.thirtyDays.conversion}%`, class: 'label label__success' },
        // hide for now since Nimrod doesn't want to see it
        // { text: `No dates views: ${this.adminStats.thirtyDays.no_dates_views}`, class: 'label label__danger' },
      ]);
    }

    if (!isEmpty(get(this, 'adminStats.sevenDays'))) {
      this.preparedStats7.push(...[
        { text: `Last 7 days (including)`, class: 'label label__primary' },
        { text: `views: ${this.adminStats.sevenDays.views}`, class: 'label label__default' },
        { text: `orders: ${this.adminStats.sevenDays.orders} (tickets: ${this.adminStats.sevenDays.tickets})`,
          class: 'label label__warning' },
        { text: `orders conversion: ${this.adminStats.sevenDays.conversion}%`, class: 'label label__success' },
        // hide for now since Nimrod doesn't want to see it
        // { text: `No dates views: ${this.adminStats.sevenDays.no_dates_views}`, class: 'label label__danger' },
      ]);
    }
  }
}
