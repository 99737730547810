import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { IOrderItem } from '../../core/store/activities';

@Component({
  selector: 'order-meta',
  template: `
  <div class="order-meta card-bg">
    <div class="order-number">{{ 'component.order.order-meta.order_number' | translate }} {{ order.id }}</div>
    <div class="order-date">{{ order.start_time | localizeDate:'EEEE d MMMM y , HH:mm' }}</div>
  </div>`,
  styleUrls: ['order-meta.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderMetaComponent implements OnInit {
  @Input() order: IOrderItem;

  constructor() {}

  ngOnInit() {}
}
