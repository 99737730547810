import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loyalty-point',
  templateUrl: 'loyalty-point.html'
})
export class LoyaltyPointComponent implements OnInit {
  @Input() point: number;
  @Input() totalLoyaltyPoints: number;

  classesArray = [];
  constructor() {}

  ngOnInit() {
    this.classesArray = this.starClasses();
  }
  rowPosition() {
    return (this.point - 1) % 3;
  }

  lastColumn() {
    return this.rowPosition() === 2;
  }

  firstColumn() {
    return this.rowPosition() === 0;
  }

  bottomRow() {
    return this.point - 1 - this.rowPosition() === 3;
  }

  freePoint() {
    return this.point > 5;
  }

  newestPoint() {
    return this.point === this.totalLoyaltyPoints;
  }

  specialStar() {
    return this.point === 999;
  }

  emptyStar() {
    return !this.freePoint() && this.point > this.totalLoyaltyPoints;
  }
  starClasses() {
    const classesArray = [];
    if (this.lastColumn()) {
      classesArray.push('last-column');
    }
    if (this.firstColumn()) {
      classesArray.push('first-column');
    }
    if (this.bottomRow()) {
      classesArray.push('bottom-row');
    } else {
      classesArray.push('top-row');
    }
    if (this.freePoint()) {
      classesArray.push('free-star');
    }
    if (this.newestPoint()) {
      classesArray.push('popout');
    }
    if (this.emptyStar()) {
      classesArray.push('empty-star');
    }
    if (this.specialStar()) {
      classesArray.push('special-star');
    }
    return classesArray;
  }
}
