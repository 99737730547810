import { HttpClient } from '@angular/common/http';
import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';
import { ImageHandlingService } from 'src/app/core/services/image-handling.service';

@Directive({
  selector: '[appImageHandler]',
})
export class ImageVideoHandlerDirective implements OnInit {

  @Input('appImageHandler') set handle(srcUrl) {
    if (srcUrl) {
      this.src = srcUrl
    }
  };
  @Input () isOnline = false;
  src

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private http: HttpClient,
    private imageHandling: ImageHandlingService,
  ) {
  }

  ngOnInit() {
    this.handleUrl(this.src);
  }

  handleUrl(srcUrl) {
    const src = srcUrl.includes('.gif') ? srcUrl.replace('.gif', '.mp4') : srcUrl;
    this.http.get(src, {
      observe: 'response',
      responseType: 'blob'
    }).subscribe(res => {
      const type = res.headers.get('content-type');
      if (type === 'image/gif' || type === 'video/mp4' || type === 'video/webm') {
        const imgUrl = res.url;

        // if (type === 'image/gif' && src.includes('.gif')) {
        //   imgUrl = imgUrl.replace('.gif', '.mp4');
        // } else if (type === 'image/gif' && !src.includes('.gif')) {
        //   imgUrl = imgUrl + '.mp4'
        // }

        // if (type === 'video/mp4' && src.includes('.gif')) {
        //   imgUrl = imgUrl.replace('.gif', '.mp4');
        // } else if (type === 'video/mp4' && !src.includes('.gif') || type === 'video/mp4' && !src.includes('.mp4')) {
        //   imgUrl = imgUrl + '.mp4'
        // }

        // Maybe we need remove it cause it will load 2 files
        // if (type === 'image/gif' && !src.includes('.gif')) {
        //   imgUrl = imgUrl + '.mp4'
        // }

        const videoEl = this.renderer.createElement('video');
        this.renderer.setAttribute(videoEl, 'height', this.el.nativeElement.offsetHeight);
        this.renderer.setAttribute(videoEl, 'width', this.el.nativeElement.offsetWidth);
        this.renderer.setAttribute(videoEl, 'autoplay', 'true');
        this.renderer.setAttribute(videoEl, 'loop', 'true');
        this.renderer.setAttribute(videoEl, 'muted', 'true');
        this.renderer.setAttribute(videoEl, 'playsinline', 'true');
        this.renderer.setAttribute(videoEl, 'src', imgUrl);
        this.renderer.setAttribute(videoEl, 'poster', './assets/imgs/transparent-pixel.png');
        this.renderer.appendChild(this.el.nativeElement, videoEl);
        setTimeout(() => {
          this.renderer.setAttribute(videoEl, 'autoplay', 'true');
        }, 1000)
      } else {
        this.renderer.setStyle(this.el.nativeElement, 'background-image', 'url(' + res.url + ')')
      }
    });
  }

}
