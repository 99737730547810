import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { TranslateService } from '@ngx-translate/core';
import { ToastController, Platform } from '@ionic/angular';
import { FacebookProvider } from '../../../shared-stores/auth/providers/facebook';
import { ISharedUrls } from '../../../funz/funz.model';
import { TrackingService } from '../../../core/services/tracking.service';

@Component({
  selector: 'share-view',
  templateUrl: 'share-view.component.html',
  styleUrls: ['./share-view.component.scss']
})
export class ShareViewComponent implements OnInit {

  @Input() shareUrl = '';
  @Input() shareUrls: ISharedUrls[] = [];
  @Input() shareSource = '';
  @Input() showTitle = true;
  @Output() closeModal = new EventEmitter<any>();
  cordova = !!(window as any).cordova;
  isIos = false;

  constructor(
    private trackingService: TrackingService,
    private inAppBrowser: InAppBrowser,
    private facebook: FacebookProvider,
    private platform: Platform,
    private toastCtrl: ToastController,
    private clipboard: Clipboard,
    private translate: TranslateService,
    private socialSharing: SocialSharing,
  ) {
    this.facebook.initialize();
  }

  ngOnInit() {
    this.isIos = this.cordova && this.platform.is('ios');
  }

  copy() {
    if (this.cordova) {
      this.clipboard.copy(this.shareUrl);
    } else {
      const copyBox = document.createElement('textarea');
      copyBox.style.position = 'fixed';
      copyBox.style.left = '0';
      copyBox.style.top = '0';
      copyBox.style.opacity = '0';
      copyBox.value = this.shareUrl;
      document.body.appendChild(copyBox);
      copyBox.focus();
      copyBox.select();
      document.execCommand('copy');
      document.body.removeChild(copyBox);
    }
    this.showToast();
  }

  private async showToast() {
    const toast = await this.toastCtrl.create({
      message: this.translate.instant('component.share.copied'),
      position: 'bottom',
      duration: 3000,
      color: 'dark'
    });
    toast.present();
  }

  private async showErrorToast() {
    const toast = await this.toastCtrl.create({
      message: this.translate.instant('component.share_view.app_is_not_installed_on_your_device'),
      position: 'bottom',
      duration: 3000,
      color: 'dark'
    });
    toast.present();
  }

  openShareLink(param) {
    if (param) {
      this.trackingService.traceAction('share_click', {
        channel: param,
        source: this.shareSource
      });
      if (this.cordova) {
        const shareText = this.translate.instant('pages.share.section.join_funzing_arena_for_fun') + ' ' + this.shareUrl;
        switch (param) {
          case 'Facebook':
            this.socialSharing.shareViaFacebook(shareText).catch(() => this.showErrorToast());
            break;
          case 'Messenger':
            this.socialSharing.shareVia('com.facebook.orca', shareText, null, null, null).catch(() => this.showErrorToast());
            break;
          case 'Whatsapp':
            this.socialSharing.shareViaWhatsApp(shareText).catch(() => this.showErrorToast());
            break;
          case 'Email':
            this.socialSharing.shareViaEmail(shareText, this.translate.instant('pages.share.section.it_is_time_to_choose_experience'), [])
              .catch(() => this.showErrorToast());
            break;
        }
      } else {
        switch (param) {
          case 'Facebook':
            this.facebook.share(this.linkByType('facebook'));
            break;
          case 'Messenger':
            this.facebook.send(this.linkByType('facebook-messenger'));
            break;
          case 'Whatsapp':
            this.browserShare(this.linkByType('whatsapp'));
            break;
          case 'Twitter':
            this.browserShare(this.linkByType('twitter'));
            break;
          case 'Email':
            this.browserShare(this.linkByType('email'));
            break;
        }
      }
    }
    this.closeModal.emit();
  }

  private linkByType(type) {
    return this.shareUrls.filter(item => item.type === type)[0].url;
  }

  private browserShare(link) {
    this.inAppBrowser.create(
      link,
      '_blank',
      'location=no,hideurlbar=yes,zoom=no'
    );
  }

}
