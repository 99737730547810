
import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { FunzApiService } from '../../services/funz-api.service';
import { Action, Store } from '@ngrx/store';
import { IAppState } from '../app.reducers';
import { withLatestFrom ,  catchError, filter, map ,  switchMap } from 'rxjs/operators';
import * as fromActions from './';

@Injectable()
export class CategoriesEffects {
  constructor(
    private actions$: Actions,
    private funzApi: FunzApiService,
    private store: Store<IAppState>,
  ) {}

  @Effect()
  fetchCategories$: Observable<Action> = this.actions$
    .pipe(
      ofType(fromActions.ActionTypes.FETCH_CATEGORIES),
      withLatestFrom(this.store.select(fromActions.getCategories)),
      filter(([_, categories]) => !categories || !categories.length),
      switchMap(() => this.funzApi.fetchCategories().pipe(
        map(values => new fromActions.FetchCategoriesSuccess(values.category_boxes)),
        catchError(() => observableOf(new fromActions.FetchCategoriesError()))
        )
      )
    );
}
