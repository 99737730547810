import { Action } from '@ngrx/store';
import {
  IOrderItem,
  IOrderDetails,
  IOrderFeedback,
  ActivityTypes
} from '../activities';
import * as fromModels from '../../models/activities.models';
import { ICommentEvent } from '../../../components/order/order';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 */
export enum ActionTypes {
  SET_NOTIFICATIONS = '[Activities] SET_NOTIFICATIONS',
  ADD_NOTIFICATIONS = '[Activities] ADD_NOTIFICATIONS',
  FETCH_NOTIFICATIONS = '[Activities] FETCH_NOTIFICATIONS',
  FETCH_MORE_NOTIFICATIONS = '[Activities] FETCH_MORE_NOTIFICATIONS',
  UPDATE_READ_NOTIFICATION = '[Activities] UPDATE_READ_NOTIFICATION',
  UPDATE_READ_NOTIFICATION_SUCCESS = '[Activities] UPDATE_READ_NOTIFICATION_SUCCESS',
  FETCH_ORDERS = '[Activities] FETCH_ORDERS',
  FETCH_ORDERS_SUCCESS = '[Activities] FETCH_ORDERS_SUCCESS',

  FETCH_ORDER = '[Activities] FETCH_ORDER',
  FETCH_ORDER_SUCCESS = '[Activities] FETCH_ORDER_SUCCESS',
  RESET_ACTIVE_ORDER = '[Activities] RESET_ACTIVE_ORDER',
  SAVE_ORDER_COMMENT = '[Activities] SAVE_ORDER_COMMENT',
  SAVE_ORDER_COMMENT_SUCCESS = '[Activities] SAVE_ORDER_COMMENT_SUCCESS',
  SAVE_ORDER_COMMENT_ERROR = '[Activities] SAVE_ORDER_COMMENT_ERROR',
  CANCEL_ORDER = '[Activities] CANCEL_ORDER',
  CANCEL_ORDER_SUCCESS = '[Activities] CANCEL_ORDER_SUCCESS',

  SET_CONVERSATIONS = '[Activities] SET_CONVERSATIONS',
  ADD_CONVERSATIONS = '[Activities] ADD_CONVERSATIONS',
  FETCH_CONVERSATIONS = '[Activities] FETCH_CONVERSATIONS',
  FETCH_MORE_CONVERSATIONS = '[Activities] FETCH_MORE_CONVERSATIONS',

  FETCH_MESSAGES = '[Activities] FETCH_MESSAGES',
  FETCH_MESSAGES_SUCCESS = '[Activities] FETCH_MESSAGES_SUCCESS',
  SET_MESSAGES = '[Activities] SET_MESSAGES',

  REPLY_MESSAGE = '[Activities] REPLY_MESSAGE',
  RESET_MESSAGES = '[Activities] RESET_MESSAGES',
  REPLY_MESSAGE_SUCCESS = '[Activities] REPLY_MESSAGE_SUCCESS',
  REPLY_MESSAGE_FAILURE = '[Activities] REPLY_MESSAGE_FAILURE',

  CLEAR_ERROR_MESSAGE = '[Activities] CLEAR_ERROR_MESSAGE',

  SET_ACTIVITY_TYPE = '[Activities] SET_ACTIVITY_TYPE',
  CLEAR_ACTIVITY_PARAMS = '[Activities] CLEAR_ACTIVITY_PARAMS',
  RESET_INBOX_MESSAGE = '[Activities] RESET_INBOX_MESSAGE',

  CALL_PHONE_NUMBER = '[Activities] CALL_PHONE_NUMBER',
  CALL_PHONE_NUMBER_SUCCESS = '[Activities] CALL_PHONE_NUMBER_SUCCESS'
}

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 */
export class FetchNotifications implements Action {
  readonly type = ActionTypes.FETCH_NOTIFICATIONS;

  constructor(public payload = 1) { }
}

export class FetchMoreNotifications implements Action {
  readonly type = ActionTypes.FETCH_MORE_NOTIFICATIONS;

  constructor(public payload: number) { }
}

export class AddNotifications implements Action {
  readonly type = ActionTypes.ADD_NOTIFICATIONS;

  constructor(public payload: fromModels.INotificationsResponse) { }
}

export class SetNotifications implements Action {
  readonly type = ActionTypes.SET_NOTIFICATIONS;

  constructor(public payload: fromModels.INotificationsResponse) { }
}

export class UpdateReadNotification implements Action {
  readonly type = ActionTypes.UPDATE_READ_NOTIFICATION;

  constructor(public payload: { id: number; unread: boolean }) { }
}
export class UpdateReadNotificationSuccess implements Action {
  readonly type = ActionTypes.UPDATE_READ_NOTIFICATION_SUCCESS;

  constructor(public payload: { id: number; unread: boolean }) { }
}

export class FetchOrders implements Action {
  readonly type = ActionTypes.FETCH_ORDERS;

  constructor() { }
}

export class FetchOrdersSuccess implements Action {
  readonly type = ActionTypes.FETCH_ORDERS_SUCCESS;

  constructor(public payload: { orders: IOrderItem[], canonical_url: string, page_title: string }) { }
}

export class FetchOrder implements Action {
  readonly type = ActionTypes.FETCH_ORDER;

  constructor(public payload: string) { }
}

export class FetchOrderSuccess implements Action {
  readonly type = ActionTypes.FETCH_ORDER_SUCCESS;

  constructor(public payload: IOrderDetails) { }
}

export class ResetActiveOrder implements Action {
  readonly type = ActionTypes.RESET_ACTIVE_ORDER;

  constructor() { }
}

export class SaveOrderComment implements Action {
  readonly type = ActionTypes.SAVE_ORDER_COMMENT;

  constructor(public payload: ICommentEvent) { }
}

export class SaveOrderCommentSuccess implements Action {
  readonly type = ActionTypes.SAVE_ORDER_COMMENT_SUCCESS;

  constructor(public payload: IOrderFeedback) { }
}

export class SaveOrderCommentError implements Action {
  readonly type = ActionTypes.SAVE_ORDER_COMMENT_ERROR;

  constructor(public payload: any) { }
}

export class CancelOrder implements Action {
  readonly type = ActionTypes.CANCEL_ORDER;

  constructor(public payload: number) { }
}

export class CancelOrderSuccess implements Action {
  readonly type = ActionTypes.CANCEL_ORDER_SUCCESS;

  constructor(public payload: string) { }
}

export class FetchConversations implements Action {
  readonly type = ActionTypes.FETCH_CONVERSATIONS;

  constructor(public payload = 1) { }
}

export class FetchMoreConversations implements Action {
  readonly type = ActionTypes.FETCH_MORE_CONVERSATIONS;

  constructor(public payload: number) { }
}

export class SetConversations implements Action {
  readonly type = ActionTypes.SET_CONVERSATIONS;

  constructor(public payload: fromModels.IConversationsResponse) { }
}

export class AddConversations implements Action {
  readonly type = ActionTypes.ADD_CONVERSATIONS;

  constructor(public payload: fromModels.IConversationsResponse) { }
}

export class ResetMessages implements Action {
  readonly type = ActionTypes.RESET_MESSAGES;
}

export class FetchMessages implements Action {
  readonly type = ActionTypes.FETCH_MESSAGES;

  constructor(public payload: { id: number; page: number }) { }
}

export class FetchMessagesSuccess implements Action {
  readonly type = ActionTypes.FETCH_MESSAGES_SUCCESS;

  constructor(public payload: any) { }
}

export class ReplyMessage implements Action {
  readonly type = ActionTypes.REPLY_MESSAGE;

  constructor(public payload: fromModels.IMessageReply) { }
}

export class ReplyMessageSuccess implements Action {
  readonly type = ActionTypes.REPLY_MESSAGE_SUCCESS;

  constructor(public payload?: fromModels.IMessageReplyResponse) { }
}
export class ReplyMessageFailure implements Action {
  readonly type = ActionTypes.REPLY_MESSAGE_FAILURE;

  constructor(public payload?: fromModels.IMessageReplyResponse) { }
}

export class ClearErrorMessage implements Action {
  readonly type = ActionTypes.CLEAR_ERROR_MESSAGE;

  constructor(public payload = 1) { }
}

export class SetActivityType implements Action {
  readonly type = ActionTypes.SET_ACTIVITY_TYPE;

  constructor(public payload: { activityType: ActivityTypes; params?: any }) { }
}

export class ClearActivityParams implements Action {
  readonly type = ActionTypes.CLEAR_ACTIVITY_PARAMS;
}

export class ResetInboxMessage implements Action {
  readonly type = ActionTypes.RESET_INBOX_MESSAGE;

  constructor() { }
}

export class CallPhoneNumber implements Action {
  readonly type = ActionTypes.CALL_PHONE_NUMBER;

  constructor(public payload: any) { }
}

export class CallPhoneNumberSuccess implements Action {
  readonly type = ActionTypes.CALL_PHONE_NUMBER;
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type ActivitiesActions =
  | AddNotifications
  | SetNotifications
  | FetchOrders
  | FetchOrdersSuccess
  | FetchOrder
  | FetchOrderSuccess
  | SaveOrderComment
  | SaveOrderCommentSuccess
  | SaveOrderCommentError
  | FetchConversations
  | FetchMoreConversations
  | FetchMessages
  | FetchMessagesSuccess
  | ReplyMessage
  | ReplyMessageSuccess
  | ReplyMessageFailure
  | ResetMessages
  | ClearErrorMessage
  | CallPhoneNumber
  | CallPhoneNumberSuccess
  | ClearActivityParams
  | any;
