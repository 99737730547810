import { Directive, HostListener, Input } from '@angular/core';
import { AuthorizationService } from '../../core/services/authorization.service';
import { AppApi } from '../../core/api/app.api';
import { NavController, ModalController } from '@ionic/angular';
import { TrackingService } from '../../core/services/tracking.service';
import { RequireAuthModalComponent } from '../require-auth/require-auth';
import { ChatModalComponent } from '../chat-modal/chat.modal';
import { AuthModalComponent } from '../../auth-components/auth-modal/auth-modal';

export interface IContactMeParams {
  funz_id?: number;
  conversation_id?: number;
  order_id?: number;
}
@Directive({
  selector: '[appContactMe]'
})
export class ContactMeDirective {
  @Input() contactId: number;
  @Input() contactParams: IContactMeParams = {};
  @Input() contactTrackEvent = '';
  @Input() contactTrackParams: any = {};

  @HostListener('click', ['$event'])
  public handleClick(event: MouseEvent) {
    event.stopImmediatePropagation();
    event.preventDefault();
    if (!!this.contactTrackEvent) {
      this.trackingService.traceAction(this.contactTrackEvent, this.contactTrackParams);
    }
    let isAuthorized = false;
    this.auth.isAuthorized().subscribe(res => (isAuthorized = res));
    if (isAuthorized) {
      return this.handleAuthorized();
    }
    return this.handleNotAuthorized();
  }

  constructor(
    private auth: AuthorizationService,
    private appApi: AppApi,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private trackingService: TrackingService,
  ) {}

  async handleAuthorized() {
    const param = {
      id: this.contactParams.conversation_id || 'new',
      owner_id: this.contactId,
      params: { ...this.contactParams }
    };
    const modal = await this.modalCtrl.create({
      component: ChatModalComponent, componentProps: param
    });
    return await modal.present();
  }

  async handleNotAuthorized() {
    const authModal = await this.modalCtrl.create({
      component: RequireAuthModalComponent,
      cssClass: 'auth-popup'
    });
    await authModal.present();
    const authType = await authModal.onDidDismiss();
    if (authType) {
      const modal = await this.modalCtrl.create({
        component: AuthModalComponent,
        componentProps: { authType }
      });
      await modal.present();
      modal.onDidDismiss().then(isAuthenticated => {
        if (isAuthenticated.data) { this.handleAuthorized(); }
      });
    }
  }
}
