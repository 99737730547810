import { NgModule } from '@angular/core';

import { CoreStoreModule } from './store';

import { APP_SERVICES } from './services';
import { APP_APIS } from './api';
import { APP_INTERCEPTORS } from './interceptors';
import { APP_GUARDS } from './guards';

// TODO FIX RXJS
@NgModule({
  imports: [CoreStoreModule],
  declarations: [],
  exports: [CoreStoreModule],
  providers: [...APP_SERVICES, ...APP_APIS, ...APP_INTERCEPTORS, ...APP_GUARDS]
})
export class CoreModule {}
