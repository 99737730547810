import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ticket-order-notification',
  templateUrl: './ticket-order-notification.component.html',
  styleUrls: ['./ticket-order-notification.component.scss'],
})
export class TicketOrderNotificationComponent {
  @Input() message: string;
  @Output() onDismiss = new EventEmitter<void>();

  constructor() { }

  dismiss() {
    this.onDismiss.emit();
  }
}
