import { createSelector, createFeatureSelector } from '@ngrx/store';

import { State } from './bookmarks.reducer';

export const selectBookmarks = createFeatureSelector<State>('bookmarks');
export const getBookmarks = createSelector(
  selectBookmarks,
  (state: State) => state.entities
);
export const getBookmarksIds = createSelector(
  selectBookmarks,
  (state: State) => state.ids
);
export const getIsEmptyBookmarks = createSelector(
  selectBookmarks,
  (state: State) => state.entities.length === 0
);
export const getLoadingBookmarks = createSelector(
  selectBookmarks,
  (state: State) => state.loading
);
