import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export type ReadingDirection = 'ltr' | 'rtl';

@Injectable({ providedIn: 'root' })
export class DocumentService {

  constructor(@Inject(DOCUMENT) private doc) {}

  public setReadingDirection(dir: ReadingDirection) {
    this.doc.dir = dir;
  }

  setVariable(name, value) {
    this.doc.documentElement.style.setProperty(name, value);
  }

  addClass(name) {
    this.doc.documentElement.className += ' ' + name;
  }
}
