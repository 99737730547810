import { take, takeUntil } from 'rxjs/operators';
import { Component, Input, DoCheck, Output, EventEmitter, OnInit } from '@angular/core';
import { MenuService } from '../../core/services/menu.service';
import { APP_PAGES } from '../../pages';
import * as fromAuth from '../../shared-stores/auth/store';
import { ModalController, NavController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { IAppState } from '../../core/store/app.reducers';
import * as fromCore from '../../core/store/core';
import * as fromFunz from '../../shared-stores/funz/store';
import { Store } from '@ngrx/store';
import { SetFromMenu } from '../../shared-stores/auth/store';
import { TrackingService } from '../../core/services/tracking.service';
import { GeneralPwaService } from '../../core/services/general-pwa.service';
import { ResetDate } from '../../shared-stores/search';
import { AuthModalComponent } from '../../auth-components/auth-modal/auth-modal';
import { ContactUsModalComponent } from '../contact-us-modal/contact-us.modal';
import { PAGE_TO_URL_TRANSFORMER } from '../../app-routing.utils';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { PersonalizedParam } from 'src/app/core/services/funz-api.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'funz-menu',
  templateUrl: 'funz-menu.component.html',
  styleUrls: ['funz-menu.component.scss']
})

export class FunzMenuComponent implements DoCheck, OnInit {

  @Input() content: any;
  @Output() closeMenu = new EventEmitter();
  menuData: any = this.menuService.menuData;
  componentName;
  rootDomain: string;
  cordova = !!(window as any).cordova;
  currentUrlParts = [];
  private locale$ = this.store.select(fromCore.getSelectedLanguage);

  constructor(
    private menuService: MenuService,
    private inAppBrowser: InAppBrowser,
    private modalCtrl: ModalController,
    private store: Store<IAppState>,
    private actions$: Actions,
    private trackingService: TrackingService,
    public generalPwaService: GeneralPwaService,
    private navCtrl: NavController,
    private appRate: AppRate,
    private translate: TranslateService,
    private router: Router
  ) {
    this.store.select(fromCore.getRootDomain).pipe(take(1)).subscribe(rootDomain => this.rootDomain = rootDomain);

    const url = this.router.url;
    this.currentUrlParts = url.split('/');

    if (this.cordova) {
      this.translate.get([
        'pages.settings.confirm.title',
        'pages.settings.confirm.log_out',
        'pages.settings.confirm.cancel',
        'dialog.apprate.title',
        'dialog.apprate.message',
        'dialog.apprate.cancelButtonLabel',
        'dialog.apprate.laterButtonLabel',
        'dialog.apprate.rateButtonLabel'
      ]).subscribe((res: any) => {
        this.appRate.setPreferences({
          usesUntilPrompt: 1,
          displayAppName: 'Funzing',
          storeAppURL: {
            ios: '1053086694',
            android: 'market://details?id=com.funzing.ionic31'
          },
          customLocale: {
            title: res['dialog.apprate.title'],
            message: res['dialog.apprate.message'],
            cancelButtonLabel: res['dialog.apprate.cancelButtonLabel'],
            laterButtonLabel: res['dialog.apprate.laterButtonLabel'],
            rateButtonLabel: res['dialog.apprate.rateButtonLabel']
          },
          openUrl: (urlstr: any) => this.inAppBrowser.create(urlstr, '_system', 'location=no,hideurlbar=yes,zoom=no'),
          simpleMode: true,
          callbacks: {
            handleNegativeFeedback: () => {
              // todo: what to do here?
              window.open('mailto:support@funzing.com', '_system');
            },
            onRateDialogShow: (callback) => {
              // cause immediate click on 'Rate Now' button
              callback(1);
            },
            onButtonClicked: (buttonIndex) => {
              // todo: actions for other buttons?
              console.log('onButtonClicked -> ' + buttonIndex);
            }
          }
        })
      });
    }
  }

  trackPage(page) {
    this.trackingService.traceAction('menu_item_click', {
      item: page.title,
    });
  }

  async openMenuPage(page) {
    this.generalPwaService.showMenu.next(false);
    this.trackPage(page);
    if (page.component) {
      if (page.component === APP_PAGES.SettingsPage) {
        const modal = await this.modalCtrl.create({
          component: ContactUsModalComponent
        });
        await modal.present();
      } else {
        await this.navCtrl.navigateRoot(PAGE_TO_URL_TRANSFORMER.getUrlByPageName(page.component));
        if (APP_PAGES.DiscoverPage === page.component) {
          this.store.dispatch(new ResetDate());
        }
      }
    } else if (page.external) {
      const hideBannerParam = !!(window as any).cordova ? '?no_banner' : '';
      this.inAppBrowser.create(
        `${this.rootDomain}/${page.external}${hideBannerParam}`,
        '_blank',
        'location=no,hideurlbar=yes,zoom=no'
      );
    } else if (page.authType) {
      this.store.dispatch(new SetFromMenu(true));
      this.trackingService.traceAction('login_signup_click', { type: page.authType });
      const modal = await this.modalCtrl.create({
        component: AuthModalComponent,
        componentProps: { authType: page.authType }
      });
      await modal.present();
    } else if (page.action) {
      if (page.action === 'logout') {
        this.store.dispatch(new fromAuth.Logout());
      } else if (page.action === 'rate-us') {
        this.appRate.promptForRating(true);
      }
    }
  }

  ngOnInit(): void {
    const destroy$: Subject<boolean> = new Subject<boolean>();
    this.actions$.pipe(takeUntil(destroy$), ofType(fromCore.SIGN_IN_COMPLETED)).subscribe(val => {
      destroy$.next(true);
      destroy$.unsubscribe();
      this.locale$.pipe(take(1)).subscribe(locale => {
        if (locale === 'he') {
          console.log('FETCHING MENU PRESONALIZATED');
          this.store.dispatch(new fromFunz.FetchPersonolisedFunzes({
            params: [PersonalizedParam.FORYOU, PersonalizedParam.BESTSELLERS, PersonalizedParam.MOSTVIEWED],
            home: true,
          }));
          this.store.select(fromFunz.getSelectedFunzId).pipe(take(1)).subscribe(funzId => {
            if (funzId) {
              this.store.dispatch(new fromFunz.FetchPersonolisedFunzes({
                params: [PersonalizedParam.WHOVIEWED, PersonalizedParam.BESTSELLERS],
                funzId
              }));
            }
          })
        }
      });
    })
  }

  ngDoCheck(): void {
    // TODO ADD ACTIVE FUNZ MENU
   /* if (this.nav && this.nav.getActive()) {
      const cname = this.nav.getActive().name;
      if (cname !== this.componentName) {
        this.componentName = cname;
      }
    }*/
  }
}
