import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TrackingService } from '../../core/services/tracking.service';

@Component({
  selector: 'require-auth-modal',
  templateUrl: 'require-auth.html',
  styleUrls: ['require-auth.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequireAuthModalComponent {
  constructor(
    private modalCtrl: ModalController,
    private trackingService: TrackingService,
  ) {
    this.trackingService.tracePageView('login_signup_for_action_pageview');
  }

  dismiss(authType?) {
    this.modalCtrl.dismiss(authType);
  }
}
