import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../../core/store/app.reducers';
import * as fromActivities from '../../core/store/activities';
import * as fromCore from '../../core/store/core';
import { IMessageReply } from '../../core/models/activities.models';

@Injectable({ providedIn: 'root' })
export class ChatProxy {
  conversation$ = this.store.select(fromActivities.getMessages);
  username$ = this.store.select(fromActivities.getMessagesUserName);
  avatar$ = this.store.select(fromActivities.getMessagesUserAvatar);
  messages$ = this.store.select(fromActivities.getMessagesForUser);
  totalPages$ = this.store.select(fromActivities.getMessagesTotalPages);
  errorMessage$ = this.store.select(fromActivities.getErrorMessage);
  canonicalUrlAndPageTitle$ = this.store.select(fromActivities.getMessagesCanonicalUrlAndPageTitle);
  rootDomain$ = this.store.select(fromCore.getRootDomain);


  constructor(private store: Store<IAppState>) {}

  fetchMessages(id: number, page = 1) {
    this.store.dispatch(new fromActivities.FetchMessages({ id, page }));
  }

  replyMessage(message: IMessageReply) {
    this.store.dispatch(new fromActivities.ReplyMessage(message));
  }

  resetMessages() {
    this.store.dispatch(new fromActivities.ResetMessages());
  }

  clearErrorMessage() {
    this.store.dispatch(new fromActivities.ClearErrorMessage());
  }

  createConversation(params) {
    this.store.dispatch(new fromActivities.ResetInboxMessage());
  }
}
