import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IInterestsState, storeKey } from './interests.reducers';

export const selectInterests = createFeatureSelector<IInterestsState>(storeKey);

export const getInterests = createSelector(
  selectInterests,
  (state: IInterestsState) => state.interests
);

export const hasInterests = createSelector(
  getInterests,
  interests => interests.length > 0
);
