import { NgModule } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { NetworkDirective } from './network/network';
import { DiagnosticDirective } from './diagnostic/diagnostic';
import { GetDirectionDirective } from './get-direction/get-direction';
import { ShowMoreDirective } from './show-more/show-more';
import { FixFreezeDirective } from './fix-freeze/fix-freeze';
import { ImageVideoHandlerDirective } from './image-video-handler/image-video-handler';
import { ClickOutsideDirective } from './click-outside-directive/click-outside-directive';


@NgModule({
  declarations: [
    NetworkDirective,
    DiagnosticDirective,
    GetDirectionDirective,
    ShowMoreDirective,
    FixFreezeDirective,
    ImageVideoHandlerDirective,
    ClickOutsideDirective
  ],
  imports: [],
  exports: [
    NetworkDirective,
    DiagnosticDirective,
    GetDirectionDirective,
    ShowMoreDirective,
    FixFreezeDirective,
    ImageVideoHandlerDirective,
    ClickOutsideDirective
  ],
  providers: [ SocialSharing ]
})
export class DirectivesModule {}
