import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/app.reducers';
import * as fromProfile from '../store/profile';
import { ModalController } from '@ionic/angular';
import { GeneralFbOnboardComponent } from '../../components/general-fb-onboard/general-fb-onboard';
import { FunzMyApiService, FbOnboard } from './funz-my-api.service';
import { isEmpty } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { get } from 'lodash';
import { ErrorTrackerService } from './error-tracker.service';

@Injectable()
export class GeneralPwaService {
  onBoardDialogDisplayed = false;
  fbOnboardData: FbOnboard;
  noSuggest: BehaviorSubject<any> = new BehaviorSubject(null);
  public hashChange: BehaviorSubject<any> = new BehaviorSubject({});
  public currentMenuItem: BehaviorSubject<any> = new BehaviorSubject('home');
  public showMenu: Subject<boolean> = new Subject();

  constructor(
    private store: Store<IAppState>,
    private modalCtrl: ModalController,
    private funzMyApiService: FunzMyApiService,
    private errorTracker: ErrorTrackerService,
  ) {
    this.store
    .select(fromProfile.getFbOnboardingData)
    .subscribe((onboardData: FbOnboard) => {
      if (!this.onBoardDialogDisplayed && onboardData.signedIn && !!onboardData.userId && onboardData.needsToFbOnboard) {
        this.fbOnboardData = onboardData;
        this.onBoardDialogDisplayed = true;
        this.displayFbOnboardingDialogIfNeeded();
      }
    });

    (window as any).onhashchange = (e) => {
      if (!isEmpty(e)) {
        this.hashChange.next({ oldURL: e.oldURL, newURL: e.newURL });
      }
    };
  }

  async displayFbOnboardingDialogIfNeeded() {
    const requestModal = await this.modalCtrl.create({
      component: GeneralFbOnboardComponent,
      componentProps: {
        fbOnboardData: this.fbOnboardData
      },
      cssClass: 'fb-onboard-dialog'
    });
    await requestModal.present();
    requestModal.onDidDismiss().then((ret: any) => {
      if (get(ret, 'data.success')) {
        const notSubscribedToMarketingEmails = get(ret, 'data.notSubscribedToMarketingEmails');
        this.funzMyApiService.updateUserFacebookOnboard({
          profile: {
            email: this.fbOnboardData.email,
            name: this.fbOnboardData.name,
            // this is for backwards compatibility for the app, which uses the same endpoint
            subscribed_to_marketing_emails: notSubscribedToMarketingEmails,
          }
        }).subscribe(
          (successReturn) => {
            this.store.dispatch(new fromProfile.UpdateProfile({ ...successReturn.user_data }));
          },
          (error) => {
            this.errorTracker.trackError(error);
          }
        );
      }
    });
  }

  scrollFreezeFix(scrollElem) {
    let lastY;
    scrollElem.addEventListener('touchstart', (e) => {
      lastY = e.touches[0].clientY;
    });
    scrollElem.addEventListener('touchmove', (e) => {
      const top = e.touches[0].clientY;
      const scrollTop = e.currentTarget.scrollTop;
      const direction = lastY - top < 0 ? 'up' : 'down';
      if (scrollTop <= 0 && direction === 'up') {
        if (e.cancelable) { e.preventDefault(); }
      } else if (scrollTop >= e.currentTarget.scrollHeight - e.currentTarget.offsetHeight && direction === 'down') {
        if (e.cancelable) { e.preventDefault(); }
      }
      lastY = top;
    });
  }
}
