import * as fromActions from './interests.actions';

export const storeKey = 'interests';

export interface IInterestsState {
  interests: any[];
}

const initialState: IInterestsState = {
  interests: []
};

export function reducer(
  state = initialState,
  action: fromActions.Actions
): IInterestsState {
  switch (action.type) {
    case fromActions.ActionTypes.GET_INTERESTS_SUCCESS: {
      const { payload: { interests } } = action;

      return {
        ...state,
        interests: [...interests]
      };
    }

    default: {
      return state;
    }
  }
}
