export interface IOrderItem {
  id: number;
  total_price: string;
  price_paid_with_money: string;
  kids_quantity: number;
  adults_quantity: number;
  start_time: Date;
  cover_url: string;
  share_url: string;
  share_urls: ISharedUrls[];
  title: string;
  address: string;
  allow_feedbacks: boolean;
  nearest_tube_station: string;
  driving_directions: string;
  owner: {
    avatar: string;
    full_name?: string;
    name?: string;
    conversation_id?: number;
    id: number;
    phone: string;
  };
  status: 'ended' | any;
  canonical_url?: string;
  page_title?: string;
  cancel_hours?: number;
  live_funz: boolean;
  free_funz: boolean;
}

export interface IOrderDetails {
  id: number;
  kids_quantity: number;
  adults_quantity: number;
  total_price: string;
  price_paid_with_money: string;
  start_time: Date;
  end_time: Date;
  cover_url: string;
  share_url: string;
  share_urls: ISharedUrls[];
  title: string;
  address: string;
  nearest_tube_station: string;
  driving_directions: string;
  can_be_cancelled: boolean;
  allow_feedbacks: boolean;
  can_edit_feedback: boolean;
  funz_id: number;
  latitude: number;
  longitude: number;
  currency: string;
  status: string;
  owner: {
    full_name?: string;
    name?: string;
    conversation_id?: number;
    phone: string;
    avatar: string;
    id: number;
  };
  tickets: IOrderTicket[];
  feedback: IOrderFeedback;
  user_phone_present: boolean;
  subscription_id: number;
  subscription: ISubscription;
  revenue: number;
  category_slug: string;
  city: string;
  canonical_url: string;
  page_title: string;
  cancel_hours?: number;
  live_funz: boolean;
  free_funz: boolean;
}
export interface IOrdersState {
  entities: IOrderItem[];
  active: IOrderDetails | {};
  canonical_url: string;
  page_title: string;
}
export interface IOrderFeedback {
  id: number;
  like: boolean;
  order_id: number;
  comment: string;
  seller_reply: string;
  created_at: Date; // 2018-03-22T08:02:22.366+00:00,
  seller_replied_at: Date;
}

export interface IOrderTicket {
  id: number;
  barcode: string;
  state: string;
  value: string;
  row: string | number;
  seat: string | number;
}
export interface IOrderSubscription {
  subscription_price?: string;
  final_price_with_sub?: string;
  only_tickets_str?: string;
  tickets_with_sub_str?: string;
  plan_system_name?: string;
  book_now_string?: string;
  subscription_selected?: boolean;
  sub_info_str?: string;
  book_now_str?: string;
  initial_price_with_sub?: string;
}
export enum NotificationColors {
  APPROVAL = '#48b15e',
  ERROR = '#EB5757'
}

export interface ISharedUrls {
  url: string;
  type: string;
}

export interface ISubscription {
  id: number;
  coupon_code: string;
  next_billing_date: string;
  plan_name: string;
  state: string;
}
