export interface IInboxState {
  conversations: {
    conversations: IConversation[];
    total_pages: number;
  };
  messages: IMessages;
  canonical_url: string;
  page_title: string;
}

export interface IMessage {
  author_id: number;
  body: string;
  created_at: Date;
}

export interface IMessageReply {
  recipient_id: number;
  body: string;
  conversation_id?: number;
  funz_id?: number;
  order_id?: number;
}

export interface IMessageReplyResponse {
  id: number;
  conversation_id: number;
  author_id: number;
  body: string;
  created_at: Date;
  updated_at: Date;
  order_id: number;
  funz_id: number;
  date_request: boolean;
  closed_group: boolean;
  guests: any;
}

export interface IMessages {
  id: number;
  messages_count: number;
  last_updated: Date;
  unread: boolean;
  other_party_avatar: string;
  other_party_id: number;
  other_party_name: string;
  updated: Date;
  messages: IMessage[];
  total_pages: number;
  canonical_url: string;
  page_title: string;
}

export interface IConversation {
  id: number;
  uid: string;
  messages_count: 3;
  last_updated: Date;
  unread: boolean;
  other_party_id: number;
  other_party_avatar: string;
  other_party_name: string;
  updated: string;
  last_message_snippet: string;
  canonical_url: string;
  page_title: string;
}

export interface INotification {
  id: number;
  created_at: Date;
  alert: string;
  unread: boolean;
  extra_data: {
    action_type: 'event' | string;
    action_id: number;
    route_url: string;
  };
}

export interface INotificationsResponse {
  notifications: INotification[];
  total_pages: number;
  canonical_url: string;
  page_title: string;
}

export interface IConversationsResponse {
  conversations: IConversation[];
  total_pages: number;
  canonical_url: string;
  page_title: string;
}

/* tslint:disable */
export class MessageConversation {
  id = null;
  uid = 'unique';
  messages_count = 0;
  last_updated = '2018-03-27T15:14:09.202+01:00';
  unread = false;
  other_party_avatar = '';
  other_party_id = 1;
  other_party_name = '';
  updated = '';
  messages = [];
  total_pages = 0;

  constructor(conversion = {}) {}
}

export  interface  IPushNotificationSetting {
  key: string;
  name: string;
  enabled: boolean
}
