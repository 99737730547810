import { createSelector } from '@ngrx/store';
import { IActivitiesState } from './activities.reducer';
import { IAppState } from '../app.reducers';
import { INotificationsResponse } from '../../services/funz-my-api.service';
import * as fromModels from '../../models/activities.models';
import { IOrdersState } from '../../store/activities';
import { IOrderDetails } from './activities.models';

export const getActivities = (state: IAppState) => state.activities;
export const getSelectedActivity = createSelector(
  getActivities,
  (activities: IActivitiesState) => activities.selectedActivity
);
export const getActivityParams = createSelector(
  getActivities,
  (activities: any) => activities.activityParams
);
export const getNotifications = createSelector(
  getActivities,
  (activities: IActivitiesState) => activities.notifications
);
export const getNotificationsPageTitleAndCanonicalUrl = createSelector(
  getNotifications,
  (notifications: INotificationsResponse) => ({ pageTitle: notifications.page_title, canonicalUrl: notifications.canonical_url })
);
export const getNotificationsItems = createSelector(
  getNotifications,
  (notifications: INotificationsResponse) => notifications.notifications
);
export const getNotificationsCount = createSelector(
  getNotificationsItems,
  (notifications: any) => notifications.length
);
export const getOrders = createSelector(
  getActivities,
  (activities: IActivitiesState) => {
    return activities.orders;
  }
);
export const getOrdersPageTitleAndCanonicalUrl = createSelector(
  getOrders,
  (orders: IOrdersState) => {
    return { page_title: orders.page_title, canonical_url: orders.canonical_url };
  }
);
export const getAllOrders = createSelector(
  getOrders,
  (orders: IOrdersState) => orders.entities
);

export const getActiveOrder = createSelector(
  getOrders,
  (orders: IOrdersState) => {
    return orders.active ? orders.active : false;
  }
);
export const getTotalPagesForNotifications = createSelector(
  getNotifications,
  (notifications: fromModels.INotificationsResponse) =>
    notifications.total_pages
);
export const getShowNewOrderNotification = createSelector(
  getActivityParams,
  getActiveOrder,
  (activityParams: any, activeOrder: IOrderDetails) => {
    return activityParams.showNewOrderNotification && Number(activeOrder.id) === Number(activityParams.orderId);
  }
);
export const getConversationsFetched = createSelector(
  getActivities,
  (activities: IActivitiesState) => activities.conversationsFetched
);
export const getNotificationsFetched = createSelector(
  getActivities,
  (activities: IActivitiesState) => activities.notificationsFetched
);
export const getOrdersFetched = createSelector(
  getActivities,
  (activities: IActivitiesState) => activities.ordersFetched
);

// inbox
// conversations
export const getInbox = createSelector(
  getActivities,
  (activities: IActivitiesState) => activities.inbox
);
export const getConversations = createSelector(
  getInbox,
  (inbox: any) => inbox.conversations
);
export const getConversationsPageTitleAndCanonicalUrl = createSelector(
  getConversations,
  (conversations: fromModels.IConversationsResponse) => ({
    pageTitle: conversations.page_title,
    canonicalUrl: conversations.canonical_url
  })
);
export const getConversationsItems = createSelector(
  getConversations,
  (conversations: fromModels.IConversationsResponse) => {
    return conversations.conversations;
  }
);
export const getConversationsCount = createSelector(
  getConversationsItems,
  (conversations: any) => conversations.length
);
export const getTotalPagesForConversations = createSelector(
  getConversations,
  (conversations: fromModels.IConversationsResponse) =>
    conversations.total_pages
);

// messages
export const getMessages = createSelector(
  getInbox,
  (inbox: any) => inbox.messages
);
export const getMessagesUserName = createSelector(
  getMessages,
  (messages: fromModels.IMessages) => messages.other_party_name
);
export const getMessagesUserAvatar = createSelector(
  getMessages,
  (messages: fromModels.IMessages) => messages.other_party_avatar
);
export const getMessagesForUser = createSelector(
  getMessages,
  (messages: fromModels.IMessages) => messages.messages
);
export const getMessagesTotalPages = createSelector(
  getMessages,
  (messages: fromModels.IMessages) => messages.total_pages
);
export const getMessagesCanonicalUrlAndPageTitle = createSelector(
  getMessages,
  (messages: fromModels.IMessages) => ({ page_title: messages.page_title, canonical_url: messages.canonical_url })
);

export const getErrorMessage = createSelector(
  getActivities,
  (activities: IActivitiesState) => activities.error
);
