import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the PluralPhrasePipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'pluralPhrase'
})
export class PluralPhrasePipe implements PipeTransform {
  constructor() {}
  transform(value: string, args: any) {
    const pluralMap = {
      0: value + '.zero',
      1: value + '.one',
      other: value + '.other'
    };
    const pluralisedPhrase = () => {
      switch (args.count) {
        case 0:
          return pluralMap['0'];
        case 1:
          return pluralMap['1'];
        default:
          return pluralMap.other;
      }
    };
    return pluralisedPhrase();
  }
}
