import { NgModule } from '@angular/core';
import { TextBasedModalComponent } from './text-based-modal';
import { TextBasedModalService } from './text-based-modal.service';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes/pipes.module';
import { DirectivesModule } from '../../directives/directives.module';

@NgModule({
  declarations: [
    TextBasedModalComponent
  ],
  imports: [
    CommonModule, IonicModule, TranslateModule.forChild(),
    PipesModule, DirectivesModule
  ],
  exports: [
    TextBasedModalComponent
  ],
  providers: [TextBasedModalService],
  entryComponents: [
    TextBasedModalComponent,
  ]
})
export class TextBasedModalModule {}
