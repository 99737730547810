import { Funz } from '../../funz/funz.model';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'funz-virtual-scroll',
  templateUrl: 'funz-virtual-scroll.html',
  styleUrls: ['funz-virtual-scroll.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FunzVirtualScrollComponent {

  @Input() funzes: Funz[];
  @Input() title = null;
  @Input() showHeart = true;

  constructor(
  ) {}

  trackByFunzIdFn(index, item) {
    return item.id;
  }

}
