import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'funz-video',
  templateUrl: './funz-video.component.html',
  styleUrls: ['./funz-video.component.scss'],
})
export class FunzVideoComponent implements AfterContentInit {

  @Input() videoUrl: string;
  @ViewChild('videoEl', { static: true }) videoElRef: ElementRef; // binds to #video in video.html
  videoElement: HTMLVideoElement;

  constructor() {}

  ngAfterContentInit() {
    this.videoElement = this.videoElRef.nativeElement;
    this.videoElement.play();
  }

}
