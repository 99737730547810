import { Injectable } from '@angular/core';
import { IAppState } from '../app.reducers';
import { Store } from '@ngrx/store';
import { FetchCategories } from './categories.actions';
import { CategoryBox } from '../../../funz/category.model';
import { Observable } from 'rxjs';
import { getCategories, getCategoriesIds, getCategoriesLoaded } from './categories.selectors';
import { Actions } from '@ngrx/effects';

@Injectable({ providedIn: 'root' })
export class CategoriesProxy {

  categories$: Observable<any[]> = this.store.select(getCategories);
  categoriesIds$: Observable<number[]> = this.store.select(getCategoriesIds);

  constructor(
    private store: Store<IAppState>,
    private actions$: Actions,
  ) {}

  fetchCategories() {
    this.store.dispatch(new FetchCategories());
  }

  categoriesIsFetched(): Promise<boolean> {
    return new Promise(resolve => {
      return this.store.select(getCategoriesLoaded)
        .subscribe((value) => {
          if (value) {
            resolve(true);
          }
        });
    });
  }
}
