import { Component } from '@angular/core';

@Component({
  selector: 'no-connection',
  templateUrl: 'no-connection.html'
})
export class NoConnectionComponent {
  text: string;

  constructor() {}
}
