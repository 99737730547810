import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'funzBoxDate',
})
export class FunzBoxDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(value: any): any {
    const currentLang = this.translateService.currentLang;
    const datePipe: DatePipe = new DatePipe(currentLang);
    const utcOffset = `UTC+${parseInt(value.split('+').pop(), 10)}`;
    return currentLang === 'he' ?
        `${datePipe.transform(value, 'd.M', utcOffset, currentLang)}`
        : datePipe.transform(value, 'd/M', utcOffset, currentLang);
  }
}
