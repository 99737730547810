import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromAuth from './store/auth.reducers';
import { AuthEffects } from './store/auth.effects';
import { RegisterEffects } from './store/register/register.effects';
import { LoginEffects } from './store/login/login.effects';
import { PasswordEffects } from './store/password/password.effects';
import { FacebookProvider } from './providers/facebook';
import { AuthComponentsModule } from '../../auth-components/auth-components.module';

@NgModule({
  imports: [
    StoreModule.forFeature('auth', fromAuth.reducerToken),
    AuthComponentsModule,
    EffectsModule.forFeature([
      AuthEffects,
      RegisterEffects,
      LoginEffects,
      PasswordEffects,
    ])
  ],
  providers: [
    fromAuth.authReducerProvider,
    FacebookProvider,
  ]
})
export class AuthStoreModule {}
