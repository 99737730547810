import * as fromApp from '../../core/store/app.reducers';
import * as fromCore from '../../core/store/core';
import * as fromAuth from '../../shared-stores/auth/store';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { FacebookProvider } from '../../shared-stores/auth/providers/facebook';
import { skip, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { TrackingService } from 'src/app/core/services/tracking.service';

@Component({
  selector: 'page-auth-modal',
  templateUrl: 'auth-modal.html',
  styleUrls: ['auth-modal.scss']
})
export class AuthModalComponent implements OnInit {
  authType: string;
  displayedComponentStateSubscription: Subscription;
  cordova = !!(window as any).cordova;

  constructor(
    public navCtrl: NavController,
    private modalCtrl: ModalController,
    private store: Store<fromApp.IAppState>,
    private facebookProvider: FacebookProvider,
    private trackingService: TrackingService,
  ) {
    if (!this.cordova) {
      // history.pushState({ a: 1 }, 'Auth', location.href); // TODO investigate for what?
      this.facebookProvider.initialize();
    }
    this.listenToAuth();
    this.store.dispatch(new fromCore.SetAuthGuardInitiatedAuthentication());
  }

  ngOnInit(): void {
    this.displayedComponentStateSubscription = this.store.select(fromAuth.getAuthDisplayedComponent).pipe(
      skip(1),
      tap(val => {
        if (val) { this.authType = val; }
      })
    ).subscribe();
  }

  listenToAuth() {
    this.store
      .select(fromAuth.getAuthSignedIn)
      .subscribe(signedIn => {
        if (signedIn === true) {
          this.closeModal(signedIn);
        }
      });
  }

  skipLogin() {
    this.closeModal(false);
  }

  closeModal(result) {
    this.modalCtrl.dismiss(result);
  }

  onDestroy() {
    this.displayedComponentStateSubscription.unsubscribe();
  }

  handleLogin() {
    this.store.dispatch(new fromAuth.SubmitLoginForm());
  }

  handleRegister() {
    this.store.dispatch(new fromAuth.SubmitRegisterForm());
    this.trackingService.traceAction('login_signup_click', { type: 'Registration' });
  }

  handleResetPassword() {
    this.store.dispatch(new fromAuth.SubmitPasswordForm());
  }
}
