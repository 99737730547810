import { Pipe, PipeTransform } from '@angular/core';
import { FilterType } from './../../funz/filter.model';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { values } from 'lodash';

@Pipe({
  name: 'filterQuery'
})
export class FilterQueryPipe implements PipeTransform {
  dictionary: string[] = [
    'pages.search.query.results_in',
    'pages.search.query.location.in',
    'pages.search.query.location.around_me',
    'pages.search.query.location.around',
    'pages.search.query.date.on'
  ];
  locales: any;

  constructor(private translateService: TranslateService) {
    translateService.get(this.dictionary).subscribe(res => {
      this.locales = res;
    });
  }

  transform(filters: any, ...args) {
    return values(filters)
      .filter((filter: any) => filter.payload)
      .reduce((query, filter: any) => {
        const { type, payload, metadata, subType } = filter;

        switch (filter.type) {
          case 'search':
            if (filter.payload) {
              query += ` ${this.locales['pages.search.query.results_in']} ${
                filter.type
              }`;
            }
            break;
          case FilterType.DATE:
            if (payload) {
              if (!payload.from_date) {
                const { title = '' } = metadata;
                query += ` ${title}`;
              } else {
                const date = new DatePipe(
                  this.translateService.currentLang
                ).transform(payload.from_date, 'd MMM');
                query += ` ${
                  this.locales['pages.search.query.date.on']
                } ${date}`;
              }
            }
            break;
          case FilterType.LOCATION:
            // Switch-case for sub-type selection
            switch (subType) {
              case 'custom_list':
                query += ` ${this.locales['pages.search.query.location.in']}${
                  payload.address
                }`;
                break;
              case 'geolocation':
                query += ` ${
                  this.locales['pages.search.query.location.around_me']
                }`;
                break;
              case 'auto_complete':
                query += ` ${
                  this.locales['pages.search.query.location.around']
                } ${metadata.formatted_address.split(',')[0]}`;
                break;
            }
            break;
        }

        return query;
      }, '');
  }
}
