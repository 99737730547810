import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { NotificationColors } from './../../core/store/activities/activities.models';

@Component({
  selector: 'ticket-notification',
  templateUrl: 'ticket-notification.html',
  styleUrls: ['ticket-notification.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketNotificationComponent {
  @Input() message: string;
  @Input() showCheckmark = false;
  @Input() showExclamation = false;
  @Input() backgroundColor: string = NotificationColors.APPROVAL;
  @Output() onDismiss = new EventEmitter<void>();

  constructor() {}

  dismiss() {
    this.onDismiss.emit();
  }
}
