import { concatMap, tap, withLatestFrom, switchMap, catchError, delayWhen } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import * as fromApp from '../../../../core/store/app.reducers';
import { Store } from '@ngrx/store';
import * as fromRegister from './register.actions';
import * as fromAuth from '../auth.actions';
import * as fromCore from './../../../../core/store/core';
import { of, from } from 'rxjs';
import { SetUserDefinedPropertyAction } from 'ngrx-forms';
import { LoadingController } from '@ionic/angular';
import { TrackingService } from '../../../../core/services/tracking.service';
import { get, keys } from 'lodash';

@Injectable()
export class RegisterEffects {
  loader: any;
  cordova = !!(window as any).cordova;

  @Effect()
  submitRegisterForm = this.actions$.pipe(
    ofType(fromRegister.SUBMIT_REGISTER_FORM),
    tap(() => this.appStore$.dispatch(new fromAuth.DisableSubmit())),
    withLatestFrom(
      this.appStore$.select(state => state.auth),
      this.appStore$.select(fromCore.getBaseDomain),
      this.appStore$.select(fromCore.getMarketingOptIn),
      this.appStore$.select(fromCore.getLastSelectedCityId),
      this.appStore$.select(fromCore.getMarketingParams),
    ),
    delayWhen(() => from(this.loadingCtrl.create().then((loader) => this.loader = loader))),
    switchMap(([action, state, baseDomain, subscribedToMarketingEmails, cityId, marketingParams]) => {
      const { registerForm: { value } } = state;
      this.loader.present();
      const payloadParams = {
        name: value.name,
        email: value.givenEmail,
        password: value.password.firstPwd,
        subscribed_to_marketing_emails: subscribedToMarketingEmails,
        city_id: cityId,
        registration_source: this.cordova ? 'api' : 'web-mobile',
        referer: get(marketingParams, 'referer')
      };
      return this.httpClient
        .post(`${baseDomain}/auth`, payloadParams, {
          observe: 'body',
          responseType: 'json'
        }).pipe(
        concatMap(response => {
          this.loader.dismiss();
          this.trackingService.sendUtms().then(() => {
            setTimeout(() => {
              this.trackingService.traceAction('email_login_success', {
                type: 'Registration',
                email: get(response, 'user_data.email')
              });
              if (this.cordova) {
                this.trackingService.trackAppsFlyer('af_complete_registration', {
                  af_customer_user_id: get(response, 'user_data.user_id'),
                  af_registration_method: 'email'
                });
              }
            }, 1000)
          });
          return [new fromAuth.Signin(response), new fromAuth.EnableSubmit()];
        }),
        catchError(error => {
          this.trackingService.traceAction('email_login_failure', {
            type: 'Registration',
            email: get(payloadParams, 'email')
          });
          this.loader.dismiss();
          this.appStore$.dispatch(new fromAuth.EnableSubmit());
          if (error.error.errors) {
            keys(error.error.errors).forEach(key => {
              if (key !== 'full_messages') {
                if (key === 'email') {
                  this.appStore$.dispatch(
                    new SetUserDefinedPropertyAction('register-form', 'givenEmail', {
                      error: get(error, `error.errors.${key}.0`)
                    })
                  );
                } else {
                  this.appStore$.dispatch(
                    new SetUserDefinedPropertyAction('register-form', key, {
                      error: get(error, `error.errors.${key}.0`)
                    })
                  );
                }
              }
            });
            return [];
          } else {
            return of({
              type: fromAuth.SIGNUP_ERROR,
              payload: error.message
            });
           // should throw error! Need to deal with error handling
          }
        }));
    })
  );

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
    private appStore$: Store<fromApp.IAppState>,
    private loadingCtrl: LoadingController,
    private trackingService: TrackingService,
  ) {
    this.loader = null;
  }
}
