import { HttpParams } from '@angular/common/http';
import { SearchWhenItem } from '../../pages/search/components/search-when-modal/search-when-modal.service';
import { CategoryBox } from '../../funz/category.model';
import { City, NO_CITY_ID } from '../../funz/city.model';
import { ICategoryRow } from '../funz/store';
import { entries, assign, forEach } from 'lodash';

export enum SearchFilterFieldType {
  QUERY = 'query',
  CATEGORIES = 'categories',
  DATE = 'date',
  INTERESTS = 'interests',
  PRICE = 'price',
  HANDICAP_ACCESSIBLE = 'handicap_accessible',
  START_TIME = 'day_part',
  IS_AUTOCOMPLETE_SEARCH = 'autocomplete'
}

export interface ISearchPriceRange {
  minPrice: number;
  maxPrice: number;
  lower: number;
  upper: number;
  displayedUpper: number;
  displayedLower: number;
}

export enum ISearchStartTime {
  DAY = 'DAY',
  EVENING = 'EVENING'
}

abstract class SearchFilterField {
  value: any;

  constructor(value) {
    this.value = value;
  }

  isActive() {
    return !!this.value;
  }

  abstract toQueryParam();

  toUrlParam(): string {
    if (this.isActive()) { return String(this.value); }
  }
}

class SearchInterestsFilterField extends SearchFilterField {
  isActive() {
    return this.value && this.value.length > 0;
  }

  toQueryParam() {
    if (this.isActive()) {
      return {
        'interests[]' : this.value.map(i => i.key)
      };
    } else {
      return {};
    }
  }
}

export class SearchFilterModel {
  private fields: { [key: string]: SearchFilterField } = {};

  constructor() {}

  isFieldActive(type: SearchFilterFieldType | string): boolean {
    return this. fields[type] && this.fields[type].isActive();
  }

  getField(type: SearchFilterFieldType  | string): SearchFilterField | null {
    return this.fields[type];
  }

  getFieldValue(type: SearchFilterFieldType | string): any {
    return this.fields[type] ? this.fields[type].value : null;
  }

  toParams(): any {
    return entries(this.fields).reduce((res, [, filter]) => {
      return assign(res, filter.toQueryParam());
    }, {});
  }

  toObject(): any {
    return entries(this.fields).reduce(
      (res, [key, filter]) => assign(res, { [key]: filter.value }),
      {}
    );
  }

  toHttpParams(): HttpParams {
    return new HttpParams({ fromObject: this.toParams() });
  }
}

class SearchHandicapAccessibleFilterField extends SearchFilterField {
  toQueryParam() {
    return this.isActive() ? { handicap_accessible : true } : {};
  }
}

class SearchQueryFilterField extends SearchFilterField {
  toQueryParam() {
    if (this.value && this.value !== '') {
      return {
        q: this.value
      };
    } else { return {}; }
  }
}


class SearchCategoriesFilterField extends SearchFilterField {
  isActive() {
    return this.value && this.value.length > 0;
  }

  toQueryParam() {
    if (this.isActive()) {
      if (this.value.length === 1) {
        return {
          slug: this.value[0].slug
        };
      } else {
        const landingPagesIds = [];
        const categoriesIds = [];
        this.value.forEach(val => {
          if (val?.lp) {
            landingPagesIds.push(val.id);
          } else {
            categoriesIds.push(val.id);
          }
        });
        const returnedFilters = {};
        if (landingPagesIds.length) {
          returnedFilters['landing_page_ids[]'] = landingPagesIds;
        }
        if (categoriesIds.length) {
          returnedFilters['categories_ids[]'] = categoriesIds;
        }
        return returnedFilters;
      }

    } else { return {}; }
  }

  toUrlParam() {
    if (this.isActive()) { return `${[SearchFilterFieldType.CATEGORIES]}=${this.value.map((v) => v.id).join()}`; }
  }
}

class SearchDateFilterField extends SearchFilterField {
  toQueryParam() {
    if (this.isActive()) {
      if (this.value.type === 'from_date') {
        const result: any = {};
        const value = this.value.value;
        if (value.from) { result.from_date = value.from; }
        if (value.to) { result.end_date = value.to; }
        return result;
      } else {
        return { [this.value.type]: true };
      }
    }
    return {};
  }

  isActive() {
    return this.value && this.value.type !== 'anytime';
  }
}

class SearchStartTimeFilterField extends SearchFilterField {
  toQueryParam() {
    return this.isActive() ? { start_time: this.value.toLowerCase() } : {};
  }
}

class SearchPriceFilterField extends SearchFilterField {

  isActive() {
    return this.value && (this.value.minPrice !== this.value.lower || this.value.maxPrice !== this.value.upper);
  }

  toQueryParam() {
    const res: any = {};
    if (this.value && this.value.lower && this.value.minPrice !== this.value.lower) { res.price_min = this.value.lower; }
    if (this.value && this.value.upper && this.value.maxPrice !== this.value.upper) { res.price_max = this.value.upper; }
    return res;
  }

}

export class SearchFilterBuilder {
  private filter;

  constructor(object?) {
    this.filter = new SearchFilterModel();
    if (object === undefined) { return; }
    forEach(object, (val, key) => {
      switch (key) {
        case SearchFilterFieldType.QUERY:
          this.filter.fields[key] = new SearchQueryFilterField(object[key]);
          return;
        case SearchFilterFieldType.CATEGORIES:
          this.filter.fields[key] = new SearchCategoriesFilterField(object[key]);
          return;
        case SearchFilterFieldType.INTERESTS:
          this.filter.fields[key] = new SearchInterestsFilterField(object[key]);
          return;
        case SearchFilterFieldType.PRICE:
          this.filter.fields[key] = new SearchPriceFilterField(object[key]);
          return;
        case SearchFilterFieldType.START_TIME:
          this.filter.fields[key] = new SearchStartTimeFilterField(object[key]);
          return;
        case SearchFilterFieldType.DATE:
          this.filter.fields[key] = new SearchDateFilterField(object[key]);
          return;
        case SearchFilterFieldType.HANDICAP_ACCESSIBLE:
          this.filter.fields[key] = new SearchHandicapAccessibleFilterField(object[key]);
      }
    });
  }

  query(str: string) {
    this.filter.fields[SearchFilterFieldType.QUERY] = new SearchQueryFilterField(str);
    return this;
  }

  date(whenItem: SearchWhenItem) {
    this.filter.fields[SearchFilterFieldType.DATE] = new SearchDateFilterField(whenItem);
    return this;
  }

  startTime(dayPart) {
    this.filter.fields[SearchFilterFieldType.START_TIME] = new SearchStartTimeFilterField(dayPart);
    return this;
  }

  categories(categories: CategoryBox[] | ICategoryRow[]) {
    this.filter.fields[SearchFilterFieldType.CATEGORIES] = new SearchCategoriesFilterField(categories);
    return this;
  }

  handicapedAccessible(accessible: boolean) {
    this.filter.fields[SearchFilterFieldType.HANDICAP_ACCESSIBLE] = new SearchHandicapAccessibleFilterField(accessible);
    return this;
  }

  price(boundaries: ISearchPriceRange) {
    this.filter.fields[SearchFilterFieldType.PRICE] = new SearchPriceFilterField(boundaries);
    return this;
  }

  interests(interests) {
    this.filter.fields[SearchFilterFieldType.INTERESTS] = new SearchInterestsFilterField(interests);
    return this;
  }

  build() {
    return this.filter;
  }
}

export function toTrackingParams(filter: SearchFilterModel, city: City | any) {
  const data: any = {};
  if (filter.isFieldActive(SearchFilterFieldType.DATE)) {
    data.date = filter.getFieldValue(SearchFilterFieldType.DATE).type;
  }
  if (filter.isFieldActive(SearchFilterFieldType.CATEGORIES)
    && filter.getFieldValue(SearchFilterFieldType.CATEGORIES).length > 0)  {
    data.category = filter.getFieldValue(SearchFilterFieldType.CATEGORIES)[0].slug;
  }

  if (city && city.id !== NO_CITY_ID) {
    data.location = city.name;
  }

  if (filter.isFieldActive(SearchFilterFieldType.INTERESTS)) {
    data.interests = filter.getFieldValue(SearchFilterFieldType.INTERESTS).map(v => v.key).join();
  }
  return data;
}
