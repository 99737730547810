import { combineLatest } from 'rxjs';
import { take, takeWhile, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import * as fromCore from '../../core/store/core';
import * as fromApp from '../../core/store/app.reducers';
import { Store } from '@ngrx/store';
import { AppApi } from '../../core/api/app.api';

@Component({
  selector: 'cookie-consent-toast',
  templateUrl: 'cookie-consent-toast.html',
  styleUrls: ['cookie-consent-toast.scss']
})
export class CookieConsentToastComponent implements OnInit {

  canShowCookiesMsg = false;
  cordova = !!(window as any).cordova;
  private privacyLink = '';

  constructor(
    public toastCtrl: ToastController,
    private storage: Storage,
    private store: Store<fromApp.IAppState>,
    private appApi: AppApi,
  ) {
  }

  ngOnInit() {
    if (!this.cordova) {
      this.store.select(fromCore.getRootDomain).pipe(take(1)).subscribe((rootDomain: string) => {
        this.privacyLink = `${rootDomain}/privacy-policy?only_body=true`;
      });
      combineLatest([this.store.select(fromCore.getRootDomain), this.appApi.canShowCookiesMsg])
      .pipe(tap(([rootDomain, canShowCookiesMsg]) => {
        if (canShowCookiesMsg) {
          this.storage.get('cookies_accepted').then(cookiesAccepted => {
            if (!cookiesAccepted && rootDomain.includes('uk')) {
              this.canShowCookiesMsg = true;
            }
          });
        }
      }), takeWhile(([rootDomain, canShowCookiesMsg]) => !canShowCookiesMsg)).subscribe();
    }
  }

  closeCookies() {
    this.canShowCookiesMsg = false;
    this.storage.set('cookies_accepted', true);
  }

  openPrivacyPolicy() {
    window.open(this.privacyLink, '_blank');
  }

}
