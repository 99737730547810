import { CoreEffects } from './core/core.effects';
import { ProfileEffects } from './profile/profile.effects';
import { ActivitiesEffects } from './activities/activities.effects';

import { InterestsEffects } from './interests/interests.effects';
import { CitiesEffects } from './cities/cities.effects';
import { CategoriesEffects } from './categories/categories.effects';

export const APP_EFFECTS = [
  CoreEffects,
  ProfileEffects,
  ActivitiesEffects,
  InterestsEffects,
  CitiesEffects,
  CategoriesEffects,
];
