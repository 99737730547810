import { Pipe, PipeTransform } from '@angular/core';
import { keys } from 'lodash';

@Pipe({
  name: 'keys',
})
export class KeyValuePipe implements PipeTransform {
  transform(value: any, ...args) {
    return value ? keys(value) : [];
  }
}
