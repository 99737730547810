import { Action } from '@ngrx/store';
import { City } from '../../funz/city.model';

export enum ActionTypes {
  INIT_FILTER = '[Search] init filter',
  INIT_FILTER_SUCCESS = '[Search] init filter success',
  SEARCH = '[Search] SEARCH',
  SEARCH_NEXT_PAGE = '[Search] SEARCH_NEXT_PAGE',
  SEARCH_SUCCESS = '[Search] SEARCH_SUCCESS',
  SEARCH_ERROR = '[Search] search error',
  SEARCH_NEXT_PAGE_SUCCESS = '[Search] SEARCH_NEXT_PAGE_SUCCESS',
  INIT_FROM_LOCAL_STORAGE = '[Search] update filter from ulr succcess',
  UPDATE_FILTER = '[Search] update filter',
  INIT_MAX_PRICE = '[Search] init max price',
  RESET_FILTER = '[Search] reset filter',
  RESET_DATE = '[Search] reset date',
  FETCH_SLUG_DEEPLINK = '[Search] FETCH_SLUG_DEEPLINK',
  LOAD_SLUG_DEEPLINK = '[Search] LOAD_SLUG_DEEPLINK',
  LOAD_LANDING_PAGE = '[Search] LOAD_LANDING_PAGE',
}

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 */

export class InitFilter implements Action {
  readonly type = ActionTypes.INIT_FILTER;
  constructor() {}
}

export class InitFilterSuccess implements Action {
  readonly type = ActionTypes.INIT_FILTER_SUCCESS;
  constructor(public filter: any) {}
}

export class Search implements Action {
  readonly type = ActionTypes.SEARCH;

  constructor(public refresh: boolean = false) {}
}

export class SearchNextPage implements Action {
  readonly type = ActionTypes.SEARCH_NEXT_PAGE;

  constructor() {}
}

export class SearchSuccess implements Action {
  readonly type = ActionTypes.SEARCH_SUCCESS;

  constructor(public payload: any) {}
}

export class SearchError implements Action {
  readonly type = ActionTypes.SEARCH_ERROR;

  constructor(public err: any) {}
}

export class SearchNextPageSuccess implements Action {
  readonly type = ActionTypes.SEARCH_NEXT_PAGE_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateFilter implements Action {
  readonly type = ActionTypes.UPDATE_FILTER;
  constructor(public filter: any, public city?: City) {}
}

export class InitFromLocalStorage implements Action {
  readonly type = ActionTypes.INIT_FROM_LOCAL_STORAGE;
  constructor() {}
}

export class InitMaxPrice implements Action {
  readonly type = ActionTypes.INIT_MAX_PRICE;
  constructor(public maxPrice: number) {}
}

export class ResetFilter implements Action {
  readonly type = ActionTypes.RESET_FILTER;
  constructor() {}
}

export class ResetDate implements Action {
  readonly type = ActionTypes.RESET_DATE;
  constructor() {}
}

export class FetchSlugDeeplink implements Action {
  readonly type = ActionTypes.FETCH_SLUG_DEEPLINK;
  constructor(public payload: string) { }
}

export class LoadSlugDeeplink implements Action {
  readonly type = ActionTypes.LOAD_SLUG_DEEPLINK;
  constructor(public payload: any) { }
}

export class LoadLandingPage implements Action {
  readonly type = ActionTypes.LOAD_LANDING_PAGE;
  constructor(public payload: any) { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
  | InitFilter
  | InitFilterSuccess
  | Search
  | SearchSuccess
  | SearchNextPage
  | SearchError
  | SearchNextPageSuccess
  | InitFromLocalStorage
  | UpdateFilter
  | InitMaxPrice
  | ResetFilter
  | ResetDate
  | FetchSlugDeeplink
  | LoadSlugDeeplink
  | LoadLandingPage;
