
import { tap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppApi } from '../api/app.api';
import { AuthHeaders } from '../models/authHeaders.model';
import { Store } from '@ngrx/store';
import { IAppState } from '../store/app.reducers';
import * as fromCore from './../store/core';
import { TranslateService } from '@ngx-translate/core';
import {  ModalController } from '@ionic/angular';
import { RequireAuthModalComponent } from '../../components/require-auth/require-auth';
import { AuthModalComponent } from 'src/app/auth-components/auth-modal/auth-modal';

@Injectable()
export class AuthorizationService {
  baseDomain: string;
  cordova = !!(window as any).cordova;

  constructor(
    private http: HttpClient,
    private appApi: AppApi,
    private translateService: TranslateService,
    public modalCtrl: ModalController,
    private store: Store<IAppState>,
  ) {
    this.store.select(fromCore.getBaseDomain).subscribe(baseDomain => {
      this.baseDomain = baseDomain;
    });
  }

  validateToken(params) {
    return this.http.get(
      `${this.baseDomain}/auth/validate_token.json?${params}`
    );
  }

  isAuthorized() {
    return this.appApi.isSignedIn$.pipe(take(1));
  }

  isAuthorizedToView(page: string, params: any = {}): Promise<boolean> {
    if (this.cordova) {
      this.appApi.requestPage({ page, params });
      return this.isAuthorized().pipe(
        tap((signedIn: boolean) => {
          if (!signedIn) {
            this.appApi.requireAuthConfirm();
          }
        }))
        .toPromise();
    } else {
      return this.isAuthorizedToViewPwa();
    }
  }

  isAuthorizedToViewPwa(): Promise<boolean> {
    return new Promise(resolve => {
      this.store.select(fromCore.getBootstrapped).subscribe(
        bootstrapped => {
          if (bootstrapped) {
            this.appApi.isSignedIn$.pipe(
              take(1))
              .subscribe(
                async signedIn => {
                  if (signedIn) {
                    resolve(signedIn);
                  } else {
                    const modal = await this.modalCtrl.create({
                      component: RequireAuthModalComponent,
                      cssClass: 'auth-popup'
                    });
                    await modal.present();
                    modal.onDidDismiss().then(async authType => {
                      if (authType && authType.data) {
                        const secondModal = await this.modalCtrl.create({
                          component: AuthModalComponent,
                          componentProps: { authType: authType.data }
                        });
                        await secondModal.present();
                        secondModal.onDidDismiss().then((isAuthenticated: any) => {
                          resolve(isAuthenticated.data);
                        });
                      } else {
                        location.hash = '';
                        location.reload();
                      }
                    });
                  }
                });
          }
        });
    });
  }

  fetchAuthHeadersAsParams() {
    let authHeaders: AuthHeaders;
    this.appApi.authHeaders$.pipe(
      take(1))
      .subscribe(headers => (authHeaders = headers));
    return this.toParams(authHeaders);
  }

  toParams(authHeaders: AuthHeaders) {
    return `uid=${authHeaders.uid}&client=${authHeaders.client}&accessToken=${
      authHeaders.accessToken
    }`;
  }
}
