import { Directive } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { AppApi } from '../../core/api/app.api';

@Directive({
  selector: '[appDiagnostic]' // Attribute selector
})
export class DiagnosticDirective {
  constructor(
    private platform: Platform,
    private diagnostic: Diagnostic,
    private appApi: AppApi
  ) {
    this.platform.ready().then(() => {
      this.isLocationAvailable();
    });
  }

  handleDrag() {
  }

  isLocationAvailable() {
    this.diagnostic
      .isLocationAvailable()
      .then(isAvailable => {
        this.appApi.onLocationStatusChange(isAvailable);
      })
      .catch((error: any) => {
        this.diagnostic.requestLocationAuthorization();
        // this.appApi.onLocationStatusChange(false);
      });
  }
}
