import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'page-menu',
  templateUrl: './menu.html',
  encapsulation: ViewEncapsulation.None
})
export class MenuPage {

  public cordova = !!(window as any).cordova;

}
