import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export const TranslateLangs = {
  ENGLISH_UK: 'en-GB'
};

@Injectable()
export class AppServices {
  constructor(private translate: TranslateService) {}

  useTranslateAsDefault(lang: string) {
    // this language will be used as  fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(lang);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(lang);
  }

  funzIsToday(dateString) {
    if (!dateString) {
      return false;
    }
    const pad = (num) => num < 10 ? `0${num}` : `${num}`;
    const d = new Date();
    return dateString.substring(0, 10) === `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}`;
  }
}
