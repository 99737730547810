import { Injectable } from '@angular/core';
import { IAppState } from '../../core/store/app.reducers';
import { Store } from '@ngrx/store';
import * as fromInterests from '../../core/store/interests';
import * as fromSearch from './index';
import { BehaviorSubject, Observable } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import * as fromActions from './search.actions';
import * as fromCities from '../../core/store/cities';
import { take } from 'rxjs/operators';
import { City } from '../../funz/city.model';

@Injectable({ providedIn: 'root' })
export class SearchProxy {

  interests$: Observable<any[]> = this.store.select(fromInterests.getInterests);
  selectedCity$: Observable<City> = this.store.select(fromCities.getSelectedCity);
  searchFilter$ = this.store.select(fromSearch.getSearchFilter);
  inputValue$: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    private store: Store<IAppState>,
    private actions$: Actions
  ) {}

  fetchInterests(): void {
    this.store.dispatch(new fromInterests.RequestInterests());
  }

  search(): void {
    this.store.dispatch(new fromSearch.Search());
  }

  updateFilter(filter, city?): void {
    this.store.dispatch(new fromSearch.UpdateFilter(filter, city));
  }

  initMaxPrice(maxPrice): void {
    this.store.dispatch(new fromSearch.InitMaxPrice(maxPrice));
  }

  resetFilter(): void {
    this.store.dispatch(new fromSearch.ResetFilter());
  }

  resetDate(): void {
    this.store.dispatch(new fromSearch.ResetDate());
  }

  onSearchSuccess(): Observable<any> {
    return this.actions$.pipe(
      ofType(fromActions.ActionTypes.SEARCH_SUCCESS),
      take(1),
    );
  }
}
