import { debounce } from 'lodash-decorators';
import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ENV } from '../../../environments/environment';

const URL = 'https://api.mapbox.com/styles/v1/mapbox/streets-v10/static';

@Component({
  selector: 'location-map',
  templateUrl: 'location-map.html'
})
export class LocationMapComponent {
  locationURL: string;
  displayImage: any;

  @Input('latLng')
  set latLng(latlng) {
    this.setlocationURL(latlng);
  }

  setlocationURL(latLng: { latitude: number; longitude: number }) {
    const { latitude, longitude } = latLng;
    this.locationURL = `${URL}/pin-s-marker+454B54(${longitude},${latitude})/${longitude},${latitude},13.5,0,0/375x210@2x?access_token=${
      ENV.mapbox_token
    }`;
  }
}
