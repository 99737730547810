import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { NavigationEnd, Router } from '@angular/router';
import * as LogRocket from 'logrocket';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class SplashScreenService {

  private hidden = false;
  private cordova = !!(window as any).cordova;

  constructor(private splashScreen: SplashScreen, private platform: Platform) {
  }

  hide() {
    if (!this.hidden && this.cordova) {
      LogRocket.log('Hide splashscreen');
      this.splashScreen.hide();
      this.hidden = true;
    }
  }
}
