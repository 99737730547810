import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, concatMap, withLatestFrom, mergeMap, filter, tap } from 'rxjs/operators';
import { FunzApiService } from '../../services/funz-api.service';
import { IAppState } from '../app.reducers';
import { Store } from '@ngrx/store';
import * as fromActions from './cities.actions';
import * as fromCore from './../core';
import * as fromProfile from './../profile';
import { Storage } from '@ionic/storage';
import { getCities, getRecentCities } from './cities.selectors';
import { recentCitiesStorageKey } from './cities.reducers';
import { InitRecentCitiesSuccess } from './cities.actions';
import { GeneralHelperService } from '../../services/general-helper.service';

@Injectable()
export class CitiesEffects {
  constructor(
    private actions$: Actions,
    private funzApi: FunzApiService,
    private store: Store<IAppState>,
    private storage: Storage,
    private ghs: GeneralHelperService,
  ) {}

  @Effect({ dispatch: false })
  checkCitiesFetched$ = this.actions$
    .pipe(
      ofType(fromActions.ActionTypes.CHECK_CITIES_FETCHED),
      withLatestFrom(this.store.select(getCities)),
      map(([action, cities]) => {
        if (cities.length === 0) {
          this.store.dispatch(new fromActions.FetchCityList());
        }
      })
    );

  @Effect()
  fetchCityList$ = this.actions$
    .pipe(
      ofType(fromActions.ActionTypes.FETCH_CITIES),
      switchMap(() => this.funzApi.requestRoot('', 'cities')),
      map(response => {
        return new fromActions.FetchCityListSuccess(response);
      })
    );

  @Effect()
  fetchSelectedCity$ = this.actions$
    .pipe(
      ofType(fromActions.ActionTypes.FETCH_SELECTED_CITY),
      withLatestFrom(this.store.select(fromCore.getLastSelectedCityId)),
      map((lastSelectedCity) => {
        return new fromActions.LoadSelectedCity(lastSelectedCity);
      })
    );

  @Effect()
  setSelectCity$ = this.actions$
    .pipe(
      ofType(fromActions.ActionTypes.SET_SELECTED_CITY),
      map((action: fromActions.SetSelectCity) => action.payload),
      withLatestFrom(this.store.select(fromCore.getLastSelectedCityId)),
      concatMap(([selectedCity, lastSelectedCityId]) => {
        // if (selectedCity.id === lastSelectedCityId) {
        //   return [];
        // }
        return [
          new fromCore.SetLastSelectedCityId(selectedCity.id)
        ];
      })
    );

  @Effect()
  citySelectionSkip$ = this.actions$
    .pipe(
      ofType(fromActions.ActionTypes.SKIP_CITY_SELECTION),
      withLatestFrom(this.store.select(getCities)),
      map(
        ([action, cities]) =>
          new fromActions.SetSelectCity({
            id: cities[0].id,
            isDefault: true
          })
      )
    );

  @Effect()
  setUserCity$ = this.actions$
    .pipe(
      ofType(fromActions.ActionTypes.SET_USER_CITY),
      map((action: fromActions.SetSelectCity) => action.payload),
      withLatestFrom(this.store.select(fromCore.getLastSelectedCityId)),
      mergeMap(([selectedCity, lastSelectedCityId]) => {
        const { id = null, isDefault = false } = selectedCity;
        let returnedActions = [];
        if (id && id !== lastSelectedCityId ) {
          returnedActions = returnedActions.concat([new fromProfile.UpdateCity(id)]);
          returnedActions = returnedActions.concat([new fromActions.SetSelectCity(selectedCity)]);
        }
        return returnedActions;
      })
    );

  @Effect({ dispatch: false })
  saveRecentCitiesInLocalStorage$ = this.actions$
    .pipe(
      ofType(fromActions.ActionTypes.SET_SELECTED_CITY),
      withLatestFrom(this.store.select(getRecentCities)),
      tap(([_, recentCities]) => {
        this.storage.set(recentCitiesStorageKey, JSON.stringify(recentCities || []))
        .catch((error: Error) => this.ghs.logStorageError(error));
      })
    );

  @Effect()
  initRecentCities$ = this.actions$
    .pipe(
      ofType(fromActions.ActionTypes.INIT_RECENT_CITIES),
      switchMap(() => this.storage.get(recentCitiesStorageKey)),
      map((data) => new InitRecentCitiesSuccess(JSON.parse(data) || []))
    );
}
