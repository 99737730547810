import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'subscription-info',
  templateUrl: 'subscription-info.html',
  styleUrls: ['subscription-info.scss']
})
export class SubscriptionInfoModalComponent {

  constructor(private modalCtrl: ModalController) {
  }

  goBack() {
    this.modalCtrl.dismiss();
  }

}
