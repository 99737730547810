import { Directive, Input, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TrackingService } from '../../core/services/tracking.service';

enum TranslateKeys {
  ShowMore = 'pages.funz_show.show_more',
  ShowLess = 'pages.funz_show.show_less'
}

@Directive({
  selector: '[appShowMore]',
})
export class ShowMoreDirective implements OnInit {

  @Input('showMore-innerHtml') private innerHtml: any;
  @Input('showMore-maxLength') private maxLength = 250;
  @Input('showMore-newLine') private newLine = true;
  @Input('showMore-trackEvent') private trackEventName: string;
  @Input('showMore-onlyMore') private onlyMore = false;
  @Input('showMore-quotes') private quotes = false;

  private readMoreTranslated: string;
  private readLessTranslated: string;
  private stripedText: string;
  private currentPosition = 0;
  private clickListener: any;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
    private trackingService: TrackingService,
  ) {
    this.readMoreTranslated = this.translateService.instant(TranslateKeys.ShowMore);
    this.readLessTranslated = this.translateService.instant(TranslateKeys.ShowLess);
  }

  ngOnInit() {
    if (this.innerHtml) {
      this.stripedText = this.strip(this.innerHtml);
      if (this.stripedText.length > this.maxLength) {
        this.hideFullText();
      } else {
        if (this.quotes) { this.innerHtml = this.setQuotes(this.innerHtml); }
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.innerHtml);
      }
    }
  }

  showFullText() {
    if (!this.onlyMore) {
      const scrollEl = this.getScrollElement(this.el.nativeElement);
      if (scrollEl) { this.currentPosition = scrollEl.scrollTop; }
    }
    if (this.quotes) { this.innerHtml = this.setQuotes(this.innerHtml); }
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.innerHtml);
    if (!this.onlyMore) { this.createShowBtn(false); }
    if (!!this.trackEventName) {
      this.trackingService.traceAction(this.trackEventName);
    }
  }

  hideFullText() {
    let substringText = `${this.stripedText.substring(0, this.maxLength)}... `;
    if (this.quotes) { substringText = '"' + substringText; }
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', substringText);
    this.createShowBtn(true);
    setTimeout(() => {
      this.clickListener = this.renderer.listen(this.el.nativeElement, 'click', (event) => {
        this.showFullText();
      });
    });
    if (!this.onlyMore) {
      const scrollEl = this.getScrollElement(this.el.nativeElement);
      if (scrollEl) { scrollEl.scrollTo({ top: this.currentPosition, behavior: 'smooth' }); }
    }
  }

  strip(html) {
    if (!html) { return ''; }
    html = html.replace('<br>', ' ');
    return html.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '');
  }

  getScrollElement(el) {
    if (el.parentElement) {
      return el.parentElement.className.indexOf('scroll-content') !== -1
        ? el.parentElement
        : this.getScrollElement(el.parentElement);
    } else {
      return null;
    }

  }

  createShowBtn(more) {
    const showBtn = this.renderer.createElement('span');
    this.renderer.setProperty(showBtn, 'innerText', more
      ? this.readMoreTranslated
      : this.readLessTranslated
    );
    this.renderer.addClass(showBtn, 'show-more-dir');
    this.renderer.addClass(showBtn, this.newLine
      ? more
        ? 'show-more-dir__new-line'
        : 'show-more-dir__new-line__less'
      : 'show-more-dir__inline'
    );
    this.renderer.listen(showBtn, 'click', (event) => {
      if (!more) {
        this.clickListener();
        this.hideFullText();
      }
    });
    this.renderer.appendChild(this.el.nativeElement, showBtn);
  }

  setQuotes = (text) => '"' + text + '"';

}
