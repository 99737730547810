import * as _ from 'lodash';
import { Funz } from '../../../funz/funz.model';

export const mapKeysToCamelCase = funz =>
  _.mapKeys(funz, (value, key) => _.camelCase(key.toString()));

export const toCamelCase = (map, funz) => {
  map[funz.id] = mapKeysToCamelCase(funz);
  return map;
};

export const toFunzMap = (map, funz) => {
  const funzObject: any = mapKeysToCamelCase(funz);
  map[funz.id] = { ...funzObject };
  return map;
};

export const funzToId = funz => funz.id;
export const toCategoryJson = (id, slug, response) => {
  return {
    id,
    funzIds: response.funzes.map(funz => funz.id),
    totalPages: response.total_pages,
    loadedPages: response.current_page,
    mobileDisplayName: response.mobile_display_name,
    imageUrl: response.image_url,
    imageUrlWide: response.image_url_wide,
    lp: response.lp,
    slug
  };
};
export const toLandingPageJson = (slug, response) => ({
  funzIds: response.funzes.map(funz => funz.id),
  slug,
  ...response
});

export const intersectArrays = (a, b) => _.intersection(a, b);

const toInt = s => +s;
export const stringsToInts = strings => strings.map(toInt);

export const getWishlistIcon = (funz: Funz) =>
  funz && funz.bookmarked ? 'custom-wishlist-f' : 'custom-wishlist-o';
